import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-checkbox',
    templateUrl: './checkbox.control.html',
    styleUrls: ['./checkbox.control.css']
})
export class CheckBoxControl extends IBaseComponent {
    static Type: any = 'checkbox';
    static Default = JSON.parse(JSON.stringify(defaultProperties.checkbox));

    //#region IsChecked
    @Input()
    get IsChecked() {
        if (typeof this.DataSourceValue === 'boolean') {
            return this.DataSourceValue;
        }
        return false;
    }
    set IsChecked(val) {
        this.DataSource = val;
        this.IsCheckedChange.emit(val);
    }

    @Output() IsCheckedChange = new EventEmitter<any>();
    //#endregion
    //#region LabelPosition
    @Input()
    get LabelPosition() {
        return this.LayoutElement.LabelPosition;
    }
    set LabelPosition(val) {
        this.LayoutElement.LabelPosition = val;
        this.LabelPositionChange.emit(this.LayoutElement.LabelPosition);
    }

    @Output() LabelPositionChange = new EventEmitter<any>();
    //#endregion
    //#region Caption
    CaptionValue;
    @Input()
    get Caption() {
        if (this.CaptionValue != null) {
            return this.CaptionValue;
        }
        return this.LayoutElement.Caption;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    @Output() OnCheckedChanged = new EventEmitter<any>();

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('checked');
        this.EventList.push('unchecked');
        this.PropertyList.push(new ElementProperty('Caption', 'string', '@@Caption'));
    }

    ControlInitialized() {
    }

    onChange(ev) {
        if (ev.checked) {
            this.triggerEvent('checked');
        } else {
            this.triggerEvent('unchecked');
        }
        this.OnCheckedChanged.emit(ev.checked);
    }

    setFocus() {
        let control = document.getElementById(this.LayoutElement.ID);
        if (control) {
            control.focus();
        }
    }
}
export class CheckBoxPanel extends BasePanel {
    static override SIDS = ['50d1ec13-23ef-4c72-ac9c-01eef226836e']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'50d1ec13-23ef-4c72-ac9c-01eef226836e',
            ID: 'checkbox',
            Caption: '@@CheckBox',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'checkbox';
            }
        })

        PROPERTIES.push({
            ID: "Caption",
            Parent: "checkbox",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Caption",
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "LabelPosition",
            Parent: "checkbox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@LabelPosition",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [{
                    Caption: '@@After',
                    Value: 'after'
                }, {
                    Caption: '@@Before',
                    Value: 'before'
                }],
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        })

    }
}