import { IBaseComponent } from '../base.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ComponentPortal } from '@angular/cdk/portal';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-sum-component',
    templateUrl: './sum.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumComponent extends IBaseComponent {
    static Type: any = 'sumcomponent';
    static Default = JSON.parse(JSON.stringify(defaultProperties.sumcomponent));

    ControlInitialized() {
    }
}
export class SumPanel extends BasePanel{
    static override SIDS = ['f30bad42-3d50-4158-99a4-2c53ae5fea2d']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'f30bad42-3d50-4158-99a4-2c53ae5fea2d',
            ID: 'sumcomponent',
            Caption: '@@Summe',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'sumcomponent';
            }
        })

        PROPERTIES.push({
            ID: "ShowCarryOver",
            Parent: "sumcomponent",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Uebertrag anzeigen"
            }
        })
    }
}