import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-tab',
    templateUrl: './tab.control.html',
    styleUrls: ['./tab.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabControl extends IBaseComponent {
    static Type: any = 'tab';
    static Default = JSON.parse(JSON.stringify(defaultProperties.tab));

    SelectedIndex = -1;
    StopClick = false;
    Redraw = false;

    private static FindElem(elem, elemToFind) {
        if (elem) {
            if (elem === elemToFind) {
                return true;
            }
            if (elem.Elements) {
                return elem.Elements.some(x => TabControl.FindElem(x, elemToFind));
            }
        }
        return false;
    }

    ControlInitialized() {
        this.Subscriptions['ElemChanged'] = LayoutService.SelectedItem.subscribe(x => {
            if (this.LayoutElement && this.LayoutElement.Elements && this.LayoutElement.ViewType === ViewType.Edit) {
                this.LayoutElement.Elements.some((y, i) => {
                    if (TabControl.FindElem(y, x)) {
                        if (i !== this.SelectedIndex) {
                            this.SelectedIndex = i;
                        }
                        return true;
                    }
                    return false;
                });
            }
        });
    }

    onTabClick(ev) {
        if (this.StopClick) {
            this.StopClick = false;
            if (ev) {
                ev.stopPropagation();
            }
        }
    }

    onFocus(ev) {
        if (ev && this.LayoutElement && this.LayoutElement.Elements && this.LayoutElement.ViewType === ViewType.Edit) {
            LayoutService.SelectedItem.next(this.LayoutElement.Elements[ev.index]);
            this.StopClick = true;
        }
    }

    ResetTabs() {
        this.Redraw = true;
        setTimeout(() => {
            this.Redraw = false;
        }, 50);
    }

    TabChanged(event) {
        if (event) {
            let result = [];
            this.findTable(this.LayoutElement.Elements[event.index].Elements, result);
            if (result.length > 0) {
                result.forEach((element) => {
                    if (element.Element) {
                        element.Element.Rerender();
                    }
                })
            }
        }
    }
    findTable(elements, result) {
        for (let i = 0; i < elements.length; i++) {
            let element = elements[i];
            if (element.ElementType == 'datatable') {
                result.push(element);
            }
            if (element.Elements) {
                this.findTable(element.Elements, result);
            }
        }
    }
}
export class TabPanel extends BasePanel {
    static override SIDS = ['c428ef5a-2133-49c4-9e61-1a3e2f092f1e']
    static override ElementType = 'tab';
    static InitPanel() {
        this.InitSelectedItem();
        this.InitSelectedLayout();
        PROPERTYGROUPS.push({
            SID:'c428ef5a-2133-49c4-9e61-1a3e2f092f1e',
            ID: 'tab',
            Caption: '@@Tab',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'tab';
            }
        })
        PROPERTIES.push({
            ID: "Elements",
            Parent: "tab",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Caption",
                    Parent: "tab",
                    Content: new ComponentPortal(TranslationTextBoxThemeControl),
                    Label: "@@Caption",
                    InitArgs: {
                        DataType: 'string'
                    }
                },
                {
                    ID: "ContentType",
                    Parent: "tab",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@ContentType",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        EnumSource: ContentType,
                        Action: (ev) => {
                            ev.SelectedItem.ElementType = ContentType[ev.SelectedItem.ContentType].toLowerCase();
                        }
                    }
                }
            ],
            InitArgs: {
                DisplayMemberPath: 'Caption',
                GenerateNewItem: () => {
                    const elem = new LayoutElement();
                    elem.ContentType = ContentType.Grid;
                    elem.ElementType = 'grid';
                    elem.Name = LayoutHelper.GetNextElementNameByPrefix(TabPanel.SelectedLayout, TabPanel.SelectedItem.Name + '_tab');
                    elem.Caption = LayoutHelper.GetNextElementNameByPrefix(TabPanel.SelectedLayout, TabPanel.SelectedItem.Name + '_tab');
                    return elem;
                }
            }
        });
    }
}