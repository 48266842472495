import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ComponentPortal } from '@angular/cdk/portal';
import { of } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { NotificationHelper } from "../../../helpers/notification.helper";
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';
@Component({
    selector: 'evi-chiplist',
    templateUrl: 'chiplist.control.html',
    styleUrls: ['chiplist.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipListControl extends IBaseComponent {

    static Type: any = 'chiplist';
    static Default = JSON.parse(JSON.stringify(defaultProperties.chiplist));
    ControlStyling = false;
    SeparatorKeysCodes: number[] = [ENTER, COMMA];
    list: any = []
    //#region Selectable
    @Input()
    get Selectable(): string {
        return this.LayoutElement.Selectable;
    }
    set Selectable(val: string) {
        this.LayoutElement.Selectable = val;
        this.SelectableChange.emit(this.LayoutElement.Selectable);
    }

    @Output() SelectableChange = new EventEmitter<string>();
    //#endregion
    //#region Removable
    @Input()
    get Removable(): string {
        return this.LayoutElement.Removable;
    }
    set Removable(val: string) {
        this.LayoutElement.Removable = val;
        this.RemovableChange.emit(this.LayoutElement.Removable);
    }

    @Output() RemovableChange = new EventEmitter<string>();
    //#endregion
    //#region AddOnBlur
    @Input()
    get AddOnBlur(): string {
        return this.LayoutElement.AddOnBlur;
    }
    set AddOnBlur(val: string) {
        this.LayoutElement.AddOnBlur = val;
        this.AddOnBlurChange.emit(this.LayoutElement.AddOnBlur);
    }

    @Output() AddOnBlurChange = new EventEmitter<string>();
    //#endregion
    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion
    //#region InputType
    @Input()
    get InputType(): string {
        return this.LayoutElement.InputType;
    }
    set InputType(val: string) {
        this.LayoutElement.InputType = val;
        this.InputTypeChange.emit(this.LayoutElement.InputType);
    }

    @Output() InputTypeChange = new EventEmitter<string>();
    //#endregion
    //#region Minimum
    @Input()
    get Minimum(): string {
        return this.LayoutElement.Minimum;
    }
    set Minimum(val: string) {
        this.LayoutElement.Minimum = val;
        this.MinimumChange.emit(this.LayoutElement.Minimum);
    }

    @Output() MinimumChange = new EventEmitter<string>();
    //#endregion
    //#region Maximum
    @Input()
    get Maximum(): string {
        return this.LayoutElement.Maximum;
    }
    set Maximum(val: string) {
        this.LayoutElement.Maximum = val;
        this.MaximumChange.emit(this.LayoutElement.Maximum);
    }

    @Output() MaximumChange = new EventEmitter<string>();
    //#endregion

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('ValueChanged');
    }
    ControlInitialized() {
        if (this.LayoutElement.ControlStyling) {
            this.list = this.DataSource.split(',');
        } else {
            this.list = this.DataSource;
        }
    }
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if (input.type == "number") {
            if (this.Maximum!==null) {
                if (parseInt(value) > parseInt(this.Maximum)) {
                    NotificationHelper.Error("Value should be less than "+this.Maximum,"Failed to add number")
                    return;
                }
            }
            if (this.Minimum!==null) {
                if (parseInt(value) < parseInt(this.Minimum)) {
                    NotificationHelper.Error("Value should be greater than "+this.Minimum,"Failed to add number")
                    return;
                }
            }
        }
        if (!this.DataSource) {
            this.DataSource = [];
            this.list = [];

        }
        // Add our fruit
        if ((value || '').trim()) {
            if (!this.LayoutElement.ControlStyling) {
                this.DataSource.push(value.trim());
            }
            this.list.push(value.trim())

        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.triggerEvent('ValueChanged', this.DataSourceValue);
    }
    List;
    ngOnInit(): void {
        // this.List = 
    }
    remove(item): void {
        const index = this.list.indexOf(item);
        ;

        if (index >= 0) {
            this.list.splice(index, 1);
            if (!this.LayoutElement.ControlStyling) {
                this.DataSource.splice(index, 1);
            }
            this.triggerEvent('ValueChanged', this.DataSourceValue);
        }
    }

    setFocus() {
        let control = document.getElementById(this.LayoutElement.ID);
        if (control) {
            control.focus();
        }
    }
}
export class ChipListPanel extends BasePanel {
    static override SIDS = ['d178a9e2-bd88-4afc-aef6-37742c4bca36']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID: 'd178a9e2-bd88-4afc-aef6-37742c4bca36',
            ID: 'chiplist',
            Caption: '@@ChipList',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'chiplist';
            }
        });
        //#region InputTypes
        let inputTypes = [{
            Caption: '@@Number',
            Value: 'number'
        }, {
            Caption: '@@Text',
            Value: 'text'
        }];
        //#endregion
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "chiplist",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "InputType",
            Parent: "chiplist",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Input type",
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: inputTypes,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "Minimum",
            Parent: "chiplist",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Minimum",
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Maximum",
            Parent: "chiplist",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Maximum",
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Selectable",
            Parent: "chiplist",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Selectable"
            }
        });
        PROPERTIES.push({
            ID: "Removable",
            Parent: "chiplist",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Removable"
            }
        });
        PROPERTIES.push({
            ID: "AddOnBlur",
            Parent: "chiplist",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@AddOnBlur"
            }
        });
    }
}