import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Moment } from 'moment';
import { CacheService } from '../../../cache/cache.service';
import { DateHelper } from '../../../helpers/date.helper';
import { WhereSelects } from '../../../models/datadescription/multi/querydef.model';
import { LevelType } from '../../../models/enums';
import { ElementProperty } from '../../../models/layoutbase.model';
import { DynamicTimeSettingsService } from '../../../services/dynamictimesettings.service';
import { LayoutService } from '../../../services/layout.service';
import { VariableInfo } from '../../common/variable.chooser';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'from-to-control',
    templateUrl: './from.to.filter.html',
    styles: ['.piCal { position: absolute; right: 0.5rem; top: 0px; bottom: 0px; }']
})
export class FromToFilter extends IBaseComponent {
    static Type = 'fromto';
    static Default = JSON.parse(JSON.stringify(defaultProperties.fromto));

    Range;

    get StartView() {
        switch (this.LayoutElementValue.LevelType) {
            case LevelType.TimeYears:
                return 'multi-years';
                break;
            case LevelType.TimeMonths:
                return "year";
                break;
            default:
                return 'month';
        } 
    }

    get From() {
        if (Array.isArray(this.Range) && this.Range.length > 0) {
            return this.Range[0];
        }
        return null;
    }
    set From(val) {
        if (Array.isArray(this.Range)) {
            this.Range = [val, this.Range[1]];
        } else {
            this.Range = [val, null];
        }
        this.dateChanged(true);
    }

    get To() {
        if (Array.isArray(this.Range) && this.Range.length > 1) {
            return this.Range[1];
        }
        return null;
    }
    set To(val) {
        if (Array.isArray(this.Range)) {
            this.Range = [this.Range[0], val];
        } else {
            this.Range = [null, val];
        }
        this.dateChanged(false);
    }

    static FillVariables(varInfos: VariableInfo[], value, layout) {
        if (varInfos) {
            varInfos.forEach(varInfo => {
                if (varInfo.IsGlobal) {
                    CacheService.ReadGlobalVariables().then((vars) => {
                        if (vars) {
                            const v = vars.find(x => x.Name === varInfo.VariableName);
                            if (v) {
                                v.Value = value;
                            }
                        }
                    });
                } else {
                    const elem = FromToFilter.GetElementByName(layout, varInfo.ElemName);
                    if (elem && elem.Variables) {
                        const v = elem.Variables.find(x => x.Name === varInfo.VariableName);
                        if (v) {
                            v.Value = value;
                        }
                    }
                }
            });
        }
    }

    private static GetElementByName(elem, name) {
        let retVal = null;
        if (elem) {
            if (elem.Name === name) {
                retVal = elem;
            } else if (elem.Elements) {
                elem.Elements.some(x => {
                    const child = FromToFilter.GetElementByName(x, name);
                    if (child) {
                        retVal = child;
                        return true;
                    }
                    return false;
                });
            }
        }
        return retVal;
    }

    constructor(private dynService: DynamicTimeSettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('fromChanged');
        this.EventList.push('toChanged');
        this.PropertyList.push(new ElementProperty('From', 'object', '@@Von'));
        this.PropertyList.push(new ElementProperty('To', 'object', '@@Bis'));
    }

    ControlInitialized() {
        const le = this.LayoutElementValue;
        if (le) {
            const range = [null, null];
            let diff;
            if (le.SaveSelection && Array.isArray(le.SavedRange) && le.SavedRange.length > 0) {
                let startTime, endTime;
                if (typeof le.SavedRange[0] === 'string') {
                    startTime = Date.parse(le.SavedRange[0]);
                }
                if (le.SavedRange.length > 1 && typeof le.SavedRange[1] === 'string') {
                    endTime = Date.parse(le.SavedRange[1]);
                }
                if (!isNaN(startTime)) {
                    range[0] = new Date(startTime);
                    if (!isNaN(endTime)) {
                        range[1] = new Date(endTime);
                        diff = endTime - startTime;
                    }
                } else if (!isNaN(endTime)) {
                    range[1] = new Date(endTime);
                }
            }
            if (le.DynTimeFrom) {
                const query = {
                    Start: le.DynTimeFrom,
                    End: null
                };
                if (le.DynTimeTo) {
                    query.End = le.DynTimeTo;
                    this.dynService.ExecuteTimeQuery(query).subscribe(x => {
                        if (x && x.Start && x.End) {
                            const start = new Date(x.Start);
                            const end = new Date(x.End);
                            this.Range = [start, end];
                            if (le.Relational) {
                                FromToFilter.FillVariables(le.VariablesFrom, start, this.LayoutValue);
                                FromToFilter.FillVariables(le.VariablesTo, end, this.LayoutValue);
                            } else {
                                this.updateMultiVar();
                            }
                            this.triggerEvent('fromChanged', start);
                            this.triggerEvent('toChanged', end);
                            this.cdRef.detectChanges();
                        }
                        this.setInitialized();
                    });
                } else {
                    this.dynService.ExecuteTimeQuery(query).subscribe(x => {
                        if (x && x.Start) {
                            const dynStart = Date.parse(x.Start);
                            let dynEnd = null;
                            if (typeof diff === 'number') {
                                dynEnd = new Date(dynStart + diff);
                            }
                            this.Range = [new Date(dynStart), dynEnd];
                            this.dateChanged(true);
                        }
                        this.setInitialized();
                    });
                }
                return;
            } else if (le.DynTimeTo) {
                this.dynService.ExecuteTimeQuery({ Start: le.DynTimeTo }).subscribe(x => {
                    if (x && x.Start) {
                        const dynEnd = Date.parse(x.Start);
                        let dynStart = null;
                        if (typeof diff === 'number') {
                            dynStart = new Date(dynEnd - diff);
                        }
                        this.Range = [dynStart, new Date(dynEnd)];
                        this.dateChanged(false);
                    }
                    this.setInitialized();
                });
                return;
            } else if (le.SaveSelection) {
                this.Range = range;
                if (le.Relational) {
                    FromToFilter.FillVariables(le.VariablesFrom, range[0], this.LayoutValue);
                    FromToFilter.FillVariables(le.VariablesTo, range[1], this.LayoutValue);
                } else {
                    this.updateMultiVar();
                }
            }
        }
    }

    isFrom = true;
    chosenYearHandler(normalizedYear: Moment, datepicker:any) {
    
        if (this.LayoutElementValue.LevelType === LevelType.TimeYears && normalizedYear) {

            let oldRange = [null, null];
            if (Array.isArray(this.Range)) {
                 oldRange = this.Range;
            }
            this.Range = null;
            this.Range = [null, null];

            if (datepicker._dtInput.selectMode === 'rangeFrom' || (datepicker._dtInput.selectMode === 'range' && this.isFrom)) {
                this.Range[0] = new Date(normalizedYear.toString());
                if (Array.isArray(oldRange )&& oldRange.length > 1) {
                    this.Range[1] = oldRange[1];
                }
                if (datepicker._dtInput.selectMode !== 'rangeFrom') {
                    this.isFrom = false;
                }
                this.dateChanged(true);
                datepicker.close();
            }
            else {
                let date = new Date(normalizedYear.toString());
                date.setDate(date.getDate() - 1);
                this.Range[1] = date;
                if (Array.isArray(oldRange) && oldRange.length > 0) {
                    this.Range[0] = oldRange[0];
                }
                this.isFrom = true;
                this.dateChanged(false);
                datepicker.close();
            }
        }
        else if (this.LayoutElementValue.LevelType === LevelType.TimeMonths && normalizedYear) {
           
        }
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker:any) {
     
        if (this.LayoutElementValue.LevelType === LevelType.TimeMonths && normalizedMonth) {

            let oldRange = [null, null];
            if (Array.isArray(this.Range)) {
                oldRange = this.Range;
            }
            this.Range = null;
            this.Range = [null, null];

            if (datepicker._dtInput.selectMode === 'rangeFrom' || (datepicker._dtInput.selectMode === 'range' && this.isFrom)) {
                this.Range[0] = new Date(normalizedMonth.toString());
                if (Array.isArray(oldRange) && oldRange.length > 1) {
                    this.Range[1] = oldRange[1];
                }
                if (datepicker._dtInput.selectMode !== 'rangeFrom') {
                    this.isFrom = false;
                }
                this.dateChanged(true);
                datepicker.close();
            }
            else {
                let date = new Date(normalizedMonth.toString());
                date.setDate(date.getDate() - 1);
                this.Range[1] = date;
                if (Array.isArray(oldRange) && oldRange.length > 0) {
                    this.Range[0] = oldRange[0];
                }
                this.isFrom = true;
                this.dateChanged(false);
                datepicker.close();
            }
        }
    }

    dateChanged(from: boolean) {
        let fromDate;
        let toDate;
        if (Array.isArray(this.Range) && this.Range.length > 0) {
            fromDate = this.Range[0];
            if (this.Range.length > 1) {
                toDate = this.Range[1];
            }
        }
        if (this.LayoutElementValue) {
            const savedRange = [];
            if (this.LayoutElementValue.SaveSelection) {
                if (fromDate instanceof Date) {
                    savedRange.push(fromDate.toUTCString());
                } else {
                    savedRange.push(null);
                }
                if (toDate instanceof Date) {
                    savedRange.push(toDate.toUTCString());
                } else {
                    savedRange.push(null);
                }
            }
            this.LayoutElementValue.SavedRange = savedRange;
            if (this.LayoutElementValue.Relational) {
                if (from) {
                    FromToFilter.FillVariables(this.LayoutElementValue.VariablesFrom, fromDate, this.LayoutValue);
                } else {
                    FromToFilter.FillVariables(this.LayoutElementValue.VariablesTo, toDate, this.LayoutValue);
                }
            } else {
                this.updateMultiVar();
            }
        }
        if (from) {
            this.triggerEvent('fromChanged', fromDate);
        } else {
            this.triggerEvent('toChanged', toDate);
        }
    }

    updateMultiVar() {
        if (this.LayoutElementValue.Level) {
            const whereDef = new WhereSelects();
            whereDef.LevelId = this.LayoutElementValue.Level;
            if (Array.isArray(this.Range)) {
                if (this.Range.length > 0) {
                    whereDef.Start = this.Range[0];
                    if (whereDef.Start instanceof Date) {
                        whereDef.Start = DateHelper.GetSerializeString(whereDef.Start);
                    }
                    if (this.Range.length > 1) {
                        whereDef.End = this.Range[1];
                        if (whereDef.End instanceof Date) {
                            whereDef.End = DateHelper.GetSerializeString(whereDef.End);
                        }
                    }
                }
            }
            FromToFilter.FillVariables(this.LayoutElementValue.VariablesFrom, [whereDef], this.LayoutValue);
        }
    }
}
