import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { LanguageMenuText } from '../../../models/enums/languagemenutext.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { StandardRequestBase } from '../../../services/request-base';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-language-menu',
    templateUrl: './language.menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageMenu extends IBaseComponent {
    static Type: any = 'languagemenu';
    static Default = JSON.parse(JSON.stringify(defaultProperties.languagemenu));
    @ViewChild('languageMenu') languageMenu: LanguageMenuControl;

    ControlInitialized() {
    }    

    setFocus() {
        if (this.languageMenu) {
            this.languageMenu.setFocus();
        }
    }
}

@Component({
    selector: 'language-menu-control',
    templateUrl: './language.menu.control.html',
    styleUrls: ['./language.menu.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageMenuControl implements OnInit {

    SelectedLanguage;
    Languages = [];
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    //#region Disabled
    DisabledValue = false;
    @Input()
    get Disabled(): boolean {
        return this.DisabledValue;
    }
    set Disabled(val: boolean) {
        this.DisabledValue = val;
        this.DisabledChange.emit(this.DisabledValue);
    }
    @Output() DisabledChange = new EventEmitter<boolean>();
    //#endregion
    @Input() Label: string;
    //#region ShowFlags
    ShowFlagsValue = false;
    @Input()
    get ShowFlags(): boolean {
        return this.ShowFlagsValue;
    }
    set ShowFlags(val: boolean) {
        this.ShowFlagsValue = val;
        this.ShowFlagsChange.emit(this.ShowFlagsValue);
        this.cdRef.detectChanges();
    }
    @Output() ShowFlagsChange = new EventEmitter<boolean>();
    //#endregion

    //#region LanguageMenuText
    LanguageMenuTextValue = LanguageMenuText.ShortName;
    @Input()
    get LanguageMenuText(): LanguageMenuText {
        return this.LanguageMenuTextValue;
    }
    set LanguageMenuText(val: LanguageMenuText) {
        if (typeof val !== 'number') {
            val = LanguageMenuText.ShortName;
        }
        if (val !== this.LanguageMenuTextValue) {
            this.LanguageMenuTextValue = val;
            this.updateText();
            this.LanguageMenuTextChange.emit(this.LanguageMenuTextValue);
        }
    }
    @Output() LanguageMenuTextChange = new EventEmitter<LanguageMenuText>();
    //#endregion

    @Output() LanguageChanged = new EventEmitter();

    constructor(private service: StandardRequestBase, private translate: TranslateService, public cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.service.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            this.Languages = languages;
            this.Languages.forEach(x => {
                // x.Icon = 'flags/' + x.Name;
                x.Icon = x.Name;
            });
            const currentLang = this.translate.currentLang;
            let sel = {
                LCID: parseInt(currentLang, 10),
                Caption: currentLang,
                Name: currentLang,
            };
            if (!isNaN(sel.LCID)) {
                languages.some(lang => {
                    if (lang.LCID === sel.LCID) {
                        sel = lang;
                        return true;
                    }
                    return false;
                });
            }
            this.SelectedLanguage = sel;
            this.updateText();
        });
    }

    languageSelected(lang) {
        if (this.SelectedLanguage !== lang) {
            this.translate.use(lang.LCID.toString());
            this.SelectedLanguage = lang;
            this.ChangeDirection();
            localStorage.setItem('SelectedLanguage', lang.LCID);
            this.LanguageChanged.emit(lang);
            this.updateText();
        }
        this.trigger.closeMenu();
    }

    ChangeDirection() {
        RTLHelper.Direction = this.SelectedLanguage.IsRightToLeft ? 'rtl' : 'ltr';
    }

    updateText() {
        switch (this.LanguageMenuTextValue) {
            case LanguageMenuText.None:
                this.Languages.forEach(x => {
                    x.ActualText = '';
                    x.Tooltip = x.Name + ' (' + x.Caption + ')';
                });
                break;
            case LanguageMenuText.ShortName:
                this.Languages.forEach(x => {
                    x.ActualText = x.Name;
                    x.Tooltip = null;
                });
                break;
            case LanguageMenuText.LongName:
                this.Languages.forEach(x => {
                    x.ActualText = x.Caption;
                    x.Tooltip = null;
                });
                break;
            case LanguageMenuText.Both:
                this.Languages.forEach(x => {
                    x.ActualText = x.Name + ' (' + x.Caption + ')';
                    x.Tooltip = null;
                });
            case LanguageMenuText.Translation:
                this.Languages.forEach(x => {
                    const translation = TranslatedString.GetTranslation(x.Translation);
                    if (translation) {
                        x.ActualText = translation;
                    } else {
                        x.ActualText = x.Name;
                    }
                    x.Tooltip = null;
                });
                break;
        }
        this.cdRef.detectChanges();
    }

    setFocus() {
        if (this.trigger) {
            this.trigger.focus();
        }
    }
}
export class LanguageMenuPanel extends BasePanel{
    static override SIDS = ['9c434e14-d611-416c-8d41-a19687f3666b']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'9c434e14-d611-416c-8d41-a19687f3666b',
            ID: 'languagemenu',
            Caption: '@@Sprachauswahl',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'languagemenu';
            }
        })
        PROPERTIES.push({
            ID: "ShowFlags",
            Parent: "languagemenu",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Flaggen anzeigen"
            }
        });
        PROPERTIES.push({
            ID: "LanguageMenuText",
            Parent: "languagemenu",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Text",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: LanguageMenuText
            }
        })
    }
}
