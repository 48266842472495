import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, EventEmitter, Inject, Input, KeyValueDiffers, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FileSelectionThemeControl } from '../../../appbuilder/controls/fileselection/fileselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MediaSource } from '../../../models/datamodel/mediasource.model';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { FileExplorerDialog } from '../../fileexplorer/fileexplorer.dialog';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-objectviewer',
    templateUrl: './objectviewer.control.html',
    styleUrls: ['./objectviewer.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectViewerControl extends IBaseComponent {

    static Type: any = 'objectviewer';
    static Default = JSON.parse(JSON.stringify(defaultProperties.objectviewer));

    URL;

    //#region MediaSource
    MediaSourceValue;
    @Input()
    get MediaSource() {
        return this.MediaSourceValue;
    }
    set MediaSource(val) {
        this.MediaSourceValue = val;
        this.MediaSourceChange.emit(this.MediaSourceValue);
    }

    @Output() MediaSourceChange = new EventEmitter<any>();
    //#endregion

    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        if (this.LayoutElement && this.LayoutElement.DataSource) {
            this.CreateUrl();
        }
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    differ;
    Sources;
    constructor(private mediaService: MediaService, private dialog: MatDialog, private sanitizer: DomSanitizer, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('objectFileUploaded');
        this.EventList.push('click');
    }
    ControlInitialized() {
        this.CreateUrl();
    }
    ngOnInit(): void {
        this.CreateUrl();
        super.ngOnInit();
    }

    onLayoutElementChanged() {
        if (!this.Editable) {
            this.CreateUrl();
        }
    }
    //BuildStyle(element, result) {
    //    //result['display'] = 'flex';
    //    //result['width'] = '100%';
    //    //result['height'] = '100%';
    //  //  result['overflow'] = 'hidden';
    //    super.BuildStyle(element, result);
    //    //result['overflow'] = 'hidden';
    //}


    CreateUrl() {
        if (this.LayoutElement) {
            if (this.LayoutElement.UseForBlob) {
                this.URL = this.DataSource;
                this.cdRef.detectChanges();
            } else if (this.LayoutElement.MediaSource) {
                CacheService.ReadMediaSource(this.LayoutElement.MediaSource).then((source) => {
                    let fileName = this.LayoutElement.FileName;
                    if (this.LayoutElement.DataSource) {
                        if (this.DataSourceValue) {
                            fileName = this.DataSourceValue;
                        }
                    }
                    if (fileName) {
                        if (source.Private) {
                            this.mediaService.LoadImage(source.SID, fileName).subscribe((file) => {
                                this.URL = file;
                                this.cdRef.detectChanges();
                            });
                        } else {
                            const url = MediaSource.GetURL(source, fileName + '?a=' + Math.random());
                            this.URL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        }
                    } else {
                        this.URL = '';
                    }
                    this.cdRef.detectChanges();
                });
            }
        }
    }

    chooseObjectFile() {
        if (this.Editable && this.LayoutElement && this.LayoutElement.MediaSource) {
            const dialogRef = this.dialog.open(FileExplorerDialog, {
                panelClass: 'fileexplorer-dialog-container',
                data: {
                    MediaSourceID: this.LayoutElement.MediaSource,
                    ImageInstance: this
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.DataSource = result;
                }
            });
        }
    }

    deleteObjectFile() {
        if (this.Editable) {
            this.DataSource = null;
        }
    }

    onFileUploaded(ev) {
        this.triggerEvent('objectFileUploaded', ev);
    }

    onClick() {
        this.triggerEvent('click');
    }
}
export class ObjectViewerPanel extends BasePanel {
    static override SIDS = ['37d11954-bf64-41a0-b6c4-f4dce709ce2e']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'37d11954-bf64-41a0-b6c4-f4dce709ce2e',
            ID: 'objectviewer',
            Caption: '@@Dateiviewer',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'objectviewer';
            }
        })
        // PROPERTIES.push({
        //     ID: "UseForBlob",
        //     Parent: "objectviewer",
        //     Content: new ComponentPortal(CheckBoxThemeControl),
        //     InitArgs: {
        //         Caption: "@@UseForBlob"
        //     },
        //     Row: 1
        // })

        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "MediaSource",
                Parent: "objectviewer",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@SelectMediaSource",
                Row: 2,
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: false,
                    ItemsSource: data,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'SID'
                }
            })
        });
        PROPERTIES.push({
            ID: "FileName",
            Parent: "objectviewer",
            Content: new ComponentPortal(FileSelectionThemeControl),
            CheckVisibility: (item) => {
                return !item.UseForBlob && item.MediaSource;
            },
            Row: 3
        })
    }
}