import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MetaHelper } from '../../../helpers/meta.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { LayoutElement } from '../../../models/layoutelement.model';
import { DynamicDataService } from '../../../services/dynamicdata.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-listdetail',
    templateUrl: './listdetail.control.html',
    styleUrls: ['./listdetail.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListDetailControl extends IBaseComponent {

    static Type: any = 'listdetail';
    static Default = JSON.parse(JSON.stringify(defaultProperties.listdetail));

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }
    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region ItemsSource
    ItemsSourceValue;

    @Input()
    get ItemsSource() {
        return this.ItemsSourceValue;
    }
    set ItemsSource(val) {
        this.ItemsSourceValue = val;
        this.ItemsSourceChange.emit(this.ItemsSourceValue);
    }

    @Output() ItemsSourceChange = new EventEmitter<any>();
    //#endregion

    //#region Caption
    CaptionValue;

    @Input()
    get Caption() {
        return this.CaptionValue;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.CaptionChange.emit(this.CaptionValue);
    }

    @Output() CaptionChange = new EventEmitter<any>();
    //#endregion

    //#region DraggedItem
    DraggedItemValue;

    @Input()
    get DraggedItem() {
        return this.DraggedItemValue;
    }
    set DraggedItem(val) {
        this.DraggedItemValue = val;
        this.DraggedItemChange.emit(this.DraggedItemValue);
    }

    @Output() DraggedItemChange = new EventEmitter<any>();
    //#endregion

    MetaTableProperties;

    constructor(private service: DynamicDataService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('selectionchanged');
    }

    ControlInitialized() {
        MetaHelper.FindTableProperties(this.LayoutValue, this.LayoutElementValue).then(result => {
            this.MetaTableProperties = result;
        });
    }

    add() {
        this.SelectedItem = {};
    }

    save() {
        if (this.MetaTableProperties) {
            this.service.SaveEntry(this.MetaTableProperties.SID, this.SelectedItem).subscribe((data) => {
                if (data) {
                    if (data.Success) {
                        Object.assign(this.SelectedItem, data.UpdateValues);
                        if (data.IsNewEntry) {
                            this.ItemsSource.push(this.SelectedItem);
                        }
                        this.SelectedItem = null;
                    } else {
                        NotificationHelper.Error(data.Error, '@@Error');
                    }
                }
            });
        }
    }
    delete() {
        if (this.MetaTableProperties) {
            this.service.DeleteEntry(this.MetaTableProperties.SID, this.SelectedItem._Id).subscribe((data) => {
                if (data) {
                    NotificationHelper.Error('Data could not be deleted. (' + data + ')', '@@Error');
                } else {
                    this.ItemsSource.splice(this.ItemsSource.indexOf(this.SelectedItem), 1);
                    this.SelectedItem = null;
                }
            });
        }
    }

    getLayoutElement(prop) {
        const retVal = new LayoutElement();
        retVal['Placeholder'] = prop.TranslatedCaption;
        retVal.Editable = !prop.IsReadOnly;
        switch (prop.Type) {
            // Text
            case '9b056ce1-ffb9-4168-9e00-977449f2f66d':
                retVal.ElementType = 'textbox';
                retVal['InputType'] = 'text';
                break;
            // Datum
            case 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69':
            case '397767a9-9711-49cd-adb7-76678fff16bf':
            case 'f696d7f4-fa0b-4664-84d7-3f9bab8c99b7':
                retVal.ElementType = 'datetimepicker';
                break;
            // Bool
            case '02414483-3194-474d-a3d1-0a1a8a36a9e0':
                retVal.ElementType = 'checkbox';
                retVal['Caption'] = prop.TranslatedCaption;
                break;
            // Enum
            case '876cf188-243b-49ed-91ab-b2cf27216a30':
                retVal.ElementType = 'combobox';
                retVal['IsEnum'] = true;
                retVal['DataSourceID'] = prop.AdvancedType;
                retVal['DisplayMemberPath'] = 'Caption';
                retVal['ValueMemberPath'] = 'Value';
                break;
            // Nummer
            case 'a2eb35c4-56d5-4acc-a58e-ef855cb5086b':
            case 'c02230ee-05fb-46d2-ab58-5b9dde1f32d5':
            case 'a4299f61-eb3f-4b29-8c4e-144458d17048':
                retVal.ElementType = 'textbox';
                retVal['InputType'] = 'number';
                break;
            // TranslationText
            case 'A0C232A9-4AB7-444C-A7E2-D23899A5673B':
                retVal.ElementType = 'translationtextbox';
                break;
            // User
            case 'a236172b-9aaa-4211-9fe9-b57daf3f3a29':
            case '07b8fdec-641d-48b7-b491-ca13af4b59c5':
            case '919a5bff-675a-435c-b0c6-6d1076ae9156':
                retVal.ElementType = 'autocomplete';
                retVal['DataSourceID'] = 'a236172b-9aaa-4211-9fe9-b57daf3f3a29';
                retVal['DisplayMemberPath'] = 'DisplayName';
                retVal['ValueMemberPath'] = '_Id';
                retVal.Editable = false;
                break;
        }
        return retVal;
    }
}
