import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-accordion',
    templateUrl: './accordion.control.html',
    styleUrls: ['./accordion.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionControl extends IBaseComponent {
    static Type: any = 'accordion';
    static Default = JSON.parse(JSON.stringify(defaultProperties));

    //#region ExpandedTab
    ExpandedTabValue;
    get ExpandedTab() {
        return this.ExpandedTabValue;
    }
    set ExpandedTab(val) {
        if (typeof val === 'number') {
            if (this.LayoutElement.Multiple) {
                if (this.ExpandedTabValue) {
                    if (this.ExpandedTabValue.indexOf(val) > -1) {
                        this.ExpandedTabValue.splice(this.ExpandedTabValue.indexOf(val), 1);
                    } else {
                        this.ExpandedTabValue.push(val);
                    }
                }
            } else {
                this.ExpandedTabValue = [val];
            }
        }
    }
    //#endregion

    @Output() Click = new EventEmitter<any>();

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.ExpandedTabValue = [];
    }
    ControlInitialized() {
        this.PropertyList.push(new ElementProperty('ExpandedTab', 'int', '@@ExpandedTab'));
        if (this.LayoutElement && this.LayoutElement.Elements) {
            for (let i = 0; i < this.LayoutElement.Elements.length; i++) {
                const panel = this.LayoutElement.Elements[i];
                if (panel.Open) {
                    this.ExpandedTab = i;
                }
                if (!panel.Caption) {
                    panel.Caption = panel.Name;
                }
            }
        }
    }

    onClick(ev) {
        this.triggerEvent('click');
        this.Click.emit(ev);
    }
}
export class AccordionPanel extends BasePanel {
    static override SIDS = ['5db13c50-d111-4ef0-af78-8a342d490c50']
    static override ElementType = 'accordion';
    static InitPanel() {
        this.InitSelectedItem();
        this.InitSelectedLayout();
        PROPERTYGROUPS.push({
            SID:'5db13c50-d111-4ef0-af78-8a342d490c50',
            ID: 'accordion',
            Caption: '@@Accordion',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'accordion';
            }
        })
        PROPERTIES.push({
            ID: "Multiple",
            Parent: "accordion",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Multiple"
            }
        });
        PROPERTIES.push({
            ID: "Elements",
            Parent: "accordion",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Caption",
                    Parent: "accordion",
                    Content: new ComponentPortal(TranslationTextBoxThemeControl),
                    Label: "@@Caption",
                    InitArgs: {
                        DataType: 'string'
                    }
                },
                {
                    ID: "ContentType",
                    Parent: "accordion",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@ContentType",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        EnumSource: ContentType,
                        Action: (ev) => {
                            ev.SelectedItem.ElementType = ContentType[ev.SelectedItem.ContentType].toLowerCase();
                        }
                    }
                },
                {
                    ID: "Open",
                    Parent: "accordion",
                    Content: new ComponentPortal(CheckBoxThemeControl),
                    InitArgs: {
                        Caption: "@@Open"
                    }
                }
            ],
            InitArgs: {
                DisplayMemberPath:'Caption',
                GenerateNewItem: () => {
                    const elem = new LayoutElement();
                    elem.ContentType = ContentType.Grid;
                    elem.ElementType = 'grid';
                    elem.Name = LayoutHelper.GetNextElementNameByPrefix(AccordionPanel.SelectedLayout, AccordionPanel.SelectedItem.Name + '_tab');
                    return elem;
                }
            }
        });
    }
}