import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DomHandler } from 'primeng/dom';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { KeyFilter } from '../../../models/enums/keyfilter.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { Resizeable } from '../../../models/enums/resizeable.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { StandardRequestBase } from '../../../services/request-base';
import { IBaseComponent } from '../base.component';
import { plainToClass, Type } from 'class-transformer';
import { TranslationTextBoxDialog } from './translation.textbox.dialog';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-translationtextbox',
    templateUrl: './translation.textbox.component.html',
    styleUrls: ['./translation.textbox.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationTextBoxComponent extends IBaseComponent {
    static Type: any = 'translationtextbox';
    static Default = JSON.parse(JSON.stringify(defaultProperties.translationtextbox));
    AllLanguages = [];
    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion

    get DefaultValue() {
        if (this.DataSourceValue) {
            return this.DataSourceValue.DefaultValue;
        }
        return null;
    }
    set DefaultValue(text) {
        if (this.DataSourceValue) {
            this.DataSourceValue.DefaultValue = text;
        } else {
            this.DataSource = {
                DefaultValue: text,
                SerializedTranslations: []
            };
        }
    }

    get MaxLength() {
        if (typeof this.LayoutElementValue.MaxLength === 'number' && this.LayoutElementValue.MaxLength > 0) {
            return this.LayoutElementValue.MaxLength;
        }
        return null;
    }

    get Resizeable() {
        if (typeof this.LayoutElementValue.Resizeable === 'number') {
            return {
                resize: Resizeable[this.LayoutElementValue.Resizeable]
            };
        }
        return { resize: 'both' };
    }

    Valid = true;
    KeyDownTimeOut;
    DEFAULT_MASKS = {
        pint: /[\d]/,
        'int': /[\d\-]/,
        pnum: /[\d\.]/,
        money: /[\d\.\s,]/,
        num: /[\d\-\.]/,
        hex: /[0-9a-f]/i,
        email: /[a-z0-9_\.\-@]/i,
        alpha: /[a-z_]/i,
        alphanum: /[a-z0-9_]/i
    };
    SAFARI_KEYS = {
        63234: 37,
        63235: 39,
        63232: 38,
        63233: 40,
        63276: 33,
        63277: 34,
        63272: 46,
        63273: 36,
        63275: 35 // end
    };
    Keys = [
        9, 13, 27, 8, 46, 36, , 35, 37, 39
    ]
    invalidChars = ['e'];

    IsRtl = false;
    constructor(private dialog: MatDialog, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data,
        requestService: StandardRequestBase, private translate: TranslateService) {
        super(cdRef, data);
        requestService.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            let list = [];
            if (languages) {
                list.push(...languages);
            }
            list.unshift({ LCID: "default", Caption:"@@Default" })
            this.AllLanguages = list;
            this.updateCaptions();
            this.FillLanguages();
            this.cdRef.detectChanges();
        });

        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(x => {
            this.FillLanguages();
        });
        this.Subscriptions['LanguageChange'] = LanguageService.LanguageChanged.subscribe(() => {
            this.updateCaptions();
            if (this.Initialized) {
                this.cdRef.detectChanges();
            }
        });
        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
        });
        this.PropertyList.push(new ElementProperty('Tooltip', 'string', '@@Tooltip'));
        this.EventList.push('keypress');
        this.EventList.push('keydown');
        this.EventList.push('keyup');
        this.EventList.push('ValidationFailed');
    }

    ControlInitialized() {        
    }

    FillLanguages() {
        if (this.AllLanguages && this.AllLanguages.length > 0) {
            if (this.DataSource == null) {
                this.AllLanguages.forEach((lang) => lang.Value = null);
            } else {
                this.AllLanguages[0].Value = this.DataSource.DefaultValue;
                if (this.DataSource.SerializedTranslations) {
                    this.AllLanguages.forEach((trans) => {
                        if (trans.LCID != 'default') {
                            let found = this.DataSource.SerializedTranslations.find((value) => value.LCID == trans.LCID);
                            if (found) {
                                trans.Value = found.Value;
                            } else {
                                trans.Value = null;
                            }
                        }
                        trans.Valid = true;
                    });
                }
            }
        }
    }
    setFocus() {
        let control = document.getElementById(this.LayoutElement.ID);
        if (control) {
            control.focus();
        }
    }
    langChanged(lang) {
        if (lang.LCID == 'default') {
            if (this.DataSourceValue) {
                this.DataSourceValue.DefaultValue = lang.Value;
            } else {
                this.DataSource = {
                    DefaultValue: lang.Value,
                    SerializedTranslations: []
                };
            }
        } else {
            if (this.DataSourceValue) {
                let trans = this.DataSource.SerializedTranslations.find((val) => val.LCID == lang.LCID);
                if (lang.Value == "") {
                    if (trans) {
                        this.DataSource.SerializedTranslations.splice(this.DataSource.SerializedTranslations.indexOf(trans), 1);
                    }
                } else {
                    if (trans) {
                        trans.Value = lang.Value;
                    } else {
                        this.DataSource.SerializedTranslations.push({ LCID: lang.LCID, Value: lang.Value });
                    }
                }
            } else {
                this.DataSource = {
                    DefaultValue: lang.Value,
                    SerializedTranslations: [{ LCID: lang.LCID, Value: lang.Value }]
                };
            }
        }        
    }

    openDialog() {
        if (this.DataSourceValue) {
            const source = plainToClass(TranslatedString, JSON.parse(JSON.stringify(this.DataSourceValue)))
            const dialogRef = this.dialog.open(TranslationTextBoxDialog, { data: { Data: source } });
            dialogRef.afterClosed().subscribe((result) => {
                if (result && result.ok) {
                    this.DataSource = result.data;
                }
            });
        } else {
            const ts = new TranslatedString();
            const dialogRef = this.dialog.open(TranslationTextBoxDialog, { data: { Data: ts } });
            dialogRef.afterClosed().subscribe((result) => {
                if (result && result.ok) {
                    this.DataSource = result.data;
                }
            });
        }
    }

    updateCaptions() {
        this.AllLanguages.forEach(x => {
            if (x.LCID == "default") {
                x.ActualCaption = this.translate.instant(x.Caption);
            } else {
                let caption;
                if (x.Translation) {
                    caption = TranslatedString.GetTranslation(x.Translation);
                }
                if (!caption) {
                    caption = x.Caption;
                }
                x.ActualCaption = caption;
            }
        });
    }

    CheckValidation(text = null, keypress = false) {
        let ok = true;
        if (this.LayoutElement && this.LayoutElement.UseValidation) {
            let regex = null;
            if (this.LayoutElement.KeyFilter) {
                if (this.LayoutElement.KeyFilter == KeyFilter.regex) {
                    regex = new RegExp(this.LayoutElement.RegExp);
                } else {
                    let key = KeyFilter[this.LayoutElement.KeyFilter];
                    regex = this.DEFAULT_MASKS[key];
                }
            }
            if (!regex) {
                return true;
            }
            if (this.LayoutElementValue.Multiline) {
                let allok = true;
                this.AllLanguages.forEach((lang) => {
                    let data = lang.Value;
                    let ok = true;
                    if (data) {
                        if (this.LayoutElement.CheckWholeExpression) {
                            ok = regex.test(data);
                        } else {
                            for (let char of data) {
                                if (!regex.test(char)) {
                                    ok = false;
                                    break;
                                }
                            }
                        }
                    }
                    if (lang.Valid != ok) {
                        lang.Valid = ok;
                    }
                    if (!ok) {
                        allok = false;
                    }
                })
                if (this.LayoutElement.ValidateOnly) {
                    this.triggerEvent('ValidationFailed', !allok);
                    if (keypress) {
                        ok = true;
                    }
                }
                this.cdRef.detectChanges();
            } else {
                let data = text;
                if (!text && this.DataSourceValue.DefaultValue) {
                    data = this.DataSourceValue.DefaultValue.toString();
                }
                if (!keypress || this.LayoutElement.ValidateOnly) {
                    data = this.DataSourceValue.DefaultValue.toString();
                }

                if (data) {
                    if (this.LayoutElement.CheckWholeExpression) {
                        ok = regex.test(data);
                    } else {
                        for (let char of data) {
                            if (!regex.test(char)) {
                                ok = false;
                                break;
                            }
                        }
                    }
                }
                if (this.LayoutElement.ValidateOnly) {
                    this.triggerEvent('ValidationFailed', !ok);
                    if (keypress) {
                        ok = true;
                    }
                }
            }
        }
        if (this.LayoutElement.ValidateOnly) {
            if (this.Valid != ok) {
                this.Valid = ok;
                this.cdRef.detectChanges();
            }
        } else {
            this.Valid = true;
        }
        return ok;
    }
    isSpecialKey(e) {
        let k = e.keyCode || e.charCode;
        return k == 9 || k == 13 || k == 27 || k == 16 || k == 17 || (k >= 18 && k <= 20) ||
            (DomHandler.getBrowser().opera && !e.shiftKey && (k == 8 || (k >= 33 && k <= 35) || (k >= 36 && k <= 39) || (k >= 44 && k <= 45)));
    }
    getKey(e) {
        let k = e.keyCode || e.charCode;
        return DomHandler.getBrowser().safari ? (this.SAFARI_KEYS[k] || k) : k;
    }
    getCharCode(e) {
        return e.charCode || e.keyCode || e.which;
    }
    KeyDown(event) {
        //this.cdRef.detach();
        let k = this.getKey(event);
        if (event.key == "F2") {
            LayoutService.KeyPress.next(event);
        }
        if (!event.ctrlKey && this.Keys.indexOf(k) == -1 && !this.LayoutElement.ValidateOnly && !this.CheckValidation(event.key, true)) {
            event.preventDefault();
        }
        if (this.LayoutElementValue.InputType === 'number' && this.invalidChars.includes(event.key)) {
            event.preventDefault();
        } else {
            if (this.LayoutElementValue.UseKeyDownDelay &&
                typeof this.LayoutElementValue.KeyDownDelay === 'number' && this.LayoutElementValue.KeyDownDelay > 0) {
                clearTimeout(this.KeyDownTimeOut);
                this.KeyDownTimeOut = setTimeout(() => {
                    this.triggerEvent('keydown', event);
                }, this.LayoutElementValue.KeyDownDelay);
            } else {
                this.triggerEvent('keydown', event);
            }
        }
    }

    KeyPress(event) {
        this.triggerEvent('keypress', event);
    }
    KeyUp(event) {
        if (this.LayoutElement.ValidateOnly) {
            this.CheckValidation();
        }
        this.triggerEvent('keyup', event);
    }
    //#region Tooltip
    TooltipValue;
    @Input()
    get Tooltip() {
        if (this.TooltipValue != null) {
            return this.TooltipValue;
        }
        return this.LayoutElement.Tooltip;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
}
export class TranslationTextBoxPanel extends BasePanel {
    static override SIDS = ['a6970217-e3bb-48b4-b2ed-ab1bc4137e17']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'a6970217-e3bb-48b4-b2ed-ab1bc4137e17',
            ID: 'TranslationTextBox',
            Caption: '@@TranslationTextBox',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'translationtextbox';
            }
        })

        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            InitArgs: {
                DataType: 'string'
            }
        })
        PROPERTIES.push({
            ID: "Tooltip",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Tooltip",
            InitArgs: {
                DataType: 'string'
            }
        })
        PROPERTIES.push({
            ID: "Multiline",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Mehrzeilig"
            }
        })
        PROPERTIES.push({
            ID: "UseValidation",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseValidation"
            }
        });
        PROPERTIES.push({
            ID: "KeyFilter",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Filter type",
            CheckVisibility: (item) => {
                return item.UseValidation;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: KeyFilter
            }
        })
        PROPERTIES.push({
            ID: "RegExp",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@RegExp",
            CheckVisibility: (item) => {
                return item.KeyFilter == 9 && item.UseValidation;
            }
        });
        PROPERTIES.push({
            ID: "ValidateOnly",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ValidateOnly"
            },
            CheckVisibility: (item) => {
                return item.UseValidation && (item.KeyFilter === 0 || item.KeyFilter > 0);
            }
        });
        PROPERTIES.push({
            ID: "CheckWholeExpression",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@CheckWholeExpression"
            },
            CheckVisibility: (item) => {
                return item.UseValidation && item.KeyFilter == 9;
            }
        });
        PROPERTIES.push({
            ID: "Message",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Message",
            CheckVisibility: (item) => {
                return item.UseValidation && (item.KeyFilter === 0 || item.KeyFilter > 0);
            }
        });
        PROPERTIES.push({
            ID: "UseKeyDownDelay",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseKeyDownDelay"
            }
        });
        PROPERTIES.push({
            ID: "KeyDownDelay",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Delay in ms",
            CheckVisibility: (item) => {
                return item.UseKeyDownDelay;
            },
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "DisplayCount",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@DisplayCount"
            },
            CheckVisibility: (item) => {
                return item.Multiline;
            }
        });
        PROPERTIES.push({
            ID: "MaxLength",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MaxLangth",
            CheckVisibility: (item) => {
                return item.Multiline;
            },
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "Resizeable",
            Parent: "TranslationTextBox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Resizeable",
            InitArgs: {
                Placeholder:'@@Select',
                EnumSource: Resizeable
            },
            CheckVisibility: (item) => {
                return item.Multiline;
            }
        });
    }
}