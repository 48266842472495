import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TextAreaThemeControl } from '../../../appbuilder/controls/textarea/textarea.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-embed',
    templateUrl: './embed.control.html',
    styleUrls: ['./embed.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedControl extends IBaseComponent {

    static Type: any = 'embed';
    static Default = JSON.parse(JSON.stringify(defaultProperties.embed))

    EmbedContent;

    ContentValue;
    get Content() {
        if (this.ContentValue) {
            return this.ContentValue;
        }
        return this.LayoutElementValue.Content;
    }
    set Content(val) {
        this.ContentValue = val;
        this.setSanitizedContent();
    }
   
    constructor(private sanitizer: DomSanitizer, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Content', 'string', '@@Content'));
    }

    ControlInitialized() {
        this.setSanitizedContent();
    }

    onLayoutElementChanged() {
        this.setSanitizedContent();
    }

    setSanitizedContent() {
        const content = this.Content;
        if (content != null) {
            this.EmbedContent = this.sanitizer.bypassSecurityTrustHtml(content);
        } else {
            this.EmbedContent = '';
        }
    }
}
export class EmbedPanel extends BasePanel {
    static override SIDS = ['83ed3787-3c19-4488-b728-387962189e84']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'83ed3787-3c19-4488-b728-387962189e84',
            ID: 'embed',
            Caption: '@@Embed',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'embed';
            }
        })

        PROPERTIES.push({
            ID: "Content",
            Parent: "embed",
            Content: new ComponentPortal(TextAreaThemeControl),
            Label: "@@Content"
        });
    }
}