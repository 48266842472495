import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
} from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Font } from '../../../models/style/font.model';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { Theme } from '../../../models/style/theme.model';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { MetaService } from '../../../services/meta.service';
import config from '../../../../assets/config.json'
import {
	BaseListDetail,
	BaseListSettings,
	DeleteTexts,
	SaveTexts,
} from '../../base.list.settings';
import { TranslatedString } from '../../../models/translatedstring.model';
import { EnumHelper } from '../../../helpers/enum.helper';
import { ControlStyle } from '../../../models/enums/controlstyle.enum';
import { MenuKeyDownHelper } from '../../../helpers/clickstoppropagation.directive';
import { Gradient } from '../../../models/style/gradient.model';
import { Color } from '../../../models/style/color.model';
import { BorderStyle } from '../../../models/enums/borderstyle.enum';
import { Border, BorderInfo } from '../../../models/style/border.model';
import { UnitType } from '../../../models/enums/unittype.enum';
import { BorderRadius } from '../../../models/style/borderradius.model';
import { ThemeHelper } from 'src/app/helpers/theme.helpers';
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
	selector: 'theme-settings',
	templateUrl: '../../base.list.settings.html',
})
export class ThemeSettings extends BaseListSettings {
	ShowAdd = true;
	ShowDelete = false;
	ShowRefresh = true;
	ShowSave = true;
	isPublishUsed = false;
	activeTheme;
	selectedTheme;
	constructor(
		protected factoryResolver: ComponentFactoryResolver,
		protected cdRef: ChangeDetectorRef,
		private service: MetaService
	) {
		super(factoryResolver, cdRef);
	}

	ngOnInit(): void {
		this.InitArgs = { ID: 'theme-settings' };
		ThemeHelper.ActiveThemeChanged.subscribe(() => {
			this.activeTheme = ThemeHelper.AcitveTheme;
		});
	}
	getContentType() {
		return ThemesDetail;
	}

	loadList(handler) {
		this.service.GetThemes(null).subscribe((result) => {
			this.service.GetDefaultTheme().subscribe((defaultTheme) => {
				const list = [];
				result.forEach(function (entry) {
					list.push({
						Caption: entry.Caption,
						ID: entry.SID,
						IsCapsule: entry.IsCapsule,
						IsOverridden: entry.IsOverridden,
						isDefault: defaultTheme ? entry.SID === defaultTheme.SID : false,
					});
				});
				handler(list);
			});
		});
	}
	loadData(data) {
		this.service.GetTheme(data).subscribe((result) => {
			if (result) {
				const item = plainToClass(Theme, result);
				this.setSelectedItem(item);
				this.selectedTheme = item;
			} else {
				const item = {};
				this.setSelectedItem(item);
			}
		});
	}
	async publishTheme(item) {
		this.isPublishUsed = true;
		this.selectedNode = item;
		this.service.GetTheme(item.ID).subscribe((result) => {
			if (result) {
				const item = plainToClass(Theme, result);
				item.IsDefault = true;
				this.setSelectedItem(item);
				this.save(false, this.isPublishUsed);
			}
		});
	}
	getNewItem() {
		return {
			Name: TranslateHelper.TranslatorInstance.instant('@@Neues Theme'),
			BorderThickness: { Value: 0, Type: 0 },
			BorderRadiusTopLeft: { Value: 0, Type: 0 },
			BorderRadiusTopRight: { Value: 0, Type: 0  },
			BorderRadiusBottomLeft: { Value: 0, Type: 0 },
			BorderRadiusBottomRight: { Value: 0, Type: 0 },
		};
	}

	getDeleteText(sel): DeleteTexts {
		const retVal = new DeleteTexts();
		retVal.Question = new TranslateFormatText(
			"@@Are you sure you want to delete theme '{0}'?"
		);
		retVal.Question.FormatParams.push(sel.Caption);
		retVal.Success = new TranslateFormatText(
			"@@Theme '{0}' successfully deleted."
		);
		retVal.Success.FormatParams.push(sel.Caption);
		retVal.Title = new TranslateFormatText('@@Theme loeschen');
		return retVal;
	}

	getSaveSuccessText(sel): SaveTexts {
		let caption = TranslatedString.GetTranslation(sel.Caption);
		if (!caption) {
			caption = sel.Name;
		}
		const retVal = new SaveTexts();
		const messageAction = this.isPublishUsed ? 'published' : 'saved';
		retVal.Text = new TranslateFormatText(
			`@@Theme \'{0}\' ${messageAction} successfully.`
		);
		retVal.Text.FormatParams.push(caption);
		retVal.Title = new TranslateFormatText(`@@Theme ${messageAction}`);
		return retVal;
	}

	delete(data, handler) {
		this.service.DeleteTheme(data).subscribe((result) => {
			handler(result);
		});
	}

	saveInternal(item, handler) {
		if (item?.Name.trim() == '') {
			NotificationHelper.Error('Name field is required', '@@Error');
			LayoutService.Loading.next(false);
			return;
		}

		let toSave = JSON.parse(JSON.stringify(item));
		if (toSave.Border) {
			toSave.Border = JSON.stringify(toSave.Border);
		}
		this.service.SaveTheme(toSave).subscribe((result) => {
			if (result) {
				handler(result, result.SID, result.Caption);
				LayoutService.Loading.next(false);
			}
		});
	}

	handleNew(item, result) {
		item.SID = result.SID;
		item.Version = result.Version;
	}

	updateListItem(item, result) {
		item.IsCapsule = result.IsCapsule;
		item.IsOverridden = result.IsOverridden;
	}

	static GetSettingsEntry() {
		// const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
		// if (
		// 	userRole &&
		// 	userRole.length > 0 &&
		// 	userRole[0] === config.FREE_USER_ID
		// ) {
		// 	return null;
		// }
		return {
			Caption: '@@Themes',
			ID: 'theme',
			Icon: 'color_lens',
			Index: 1,
			Parent: 'ci',
			Security: {
				Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
				Value: 2,
			},
			Content: ThemeSettings,
		};
	}
}

@Component({
	selector: 'themes-detail',
	templateUrl: './theme.settings.html',
	styleUrls: ['./theme.settings.css'],
})
export class ThemesDetail extends BaseListDetail {
	ControlStyles = [];
	BorderStyles = [];
	fontFamilies = [];
	fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
	ActBorder = new BorderInfo();
	SelectedItem: any = {
		BorderThickness: { Value: 0 },
		BorderRadiusTopLeft: { Value: 0 },
		BorderRadiusTopRight: { Value: 0 },
		BorderRadiusBottomLeft: { Value: 0 },
		BorderRadiusBottomRight: { Value: 0 },
	};
	ColorChanged(value, color) {
		this.SelectedItem[color] = value;
	}
	GradientColorChange(value) {
		this.SelectedItem.PrimaryGradient = value;
	}
	SelectedBorderWidth = 1;
	SelectedBorderStyle = BorderStyle.Solid;
	SelectedBorderColor = Color.FromHex('#000000');
	//#region Bold
	get Bold() {
		if (this.SelectedItem && this.SelectedItem.Font) {
			return this.SelectedItem.Font.Bold;
		}
		return false;
	}
	set Bold(val) {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Font) {
				this.SelectedItem.Font = new Font();
			}
			this.SelectedItem.Font.Bold = val;
		}
	}
	setBold() {
		this.Bold = !this.Bold;
	}
	//#endregion
	//#region Italic
	get Italic() {
		if (this.SelectedItem && this.SelectedItem.Font) {
			return this.SelectedItem.Font.Italic;
		}
		return false;
	}
	set Italic(val) {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Font) {
				this.SelectedItem.Font = new Font();
			}
			this.SelectedItem.Font.Italic = val;
		}
	}
	setItalic() {
		this.Italic = !this.Italic;
	}
	//#endregion
	//#region Underline
	get Underline() {
		if (this.SelectedItem && this.SelectedItem.Font) {
			return this.SelectedItem.Font.Underline;
		}
		return false;
	}
	set Underline(val) {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Font) {
				this.SelectedItem.Font = new Font();
			}
			this.SelectedItem.Font.Underline = val;
		}
	}
	setUnderline() {
		this.Underline = !this.Underline;
	}
	//#endregion
	//#region SelectedFontFamily
	get SelectedFontFamily() {
		if (this.SelectedItem && this.SelectedItem.Font) {
			return this.SelectedItem.Font.FontFamilyName;
		}
		return '';
	}
	set SelectedFontFamily(val) {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Font) {
				this.SelectedItem.Font = new Font();
			}
			this.SelectedItem.Font.FontFamilyName = val;
		}
	}
	GetFontFamily(ff) {
		const result = {};
		result['font-family'] = ff;
		return result;
	}
	//#endregion

	//#region SelectedFontSize
	get SelectedFontSize() {
		if (this.SelectedItem && this.SelectedItem.Font) {
			return this.SelectedItem.Font.FontSize;
		}
		return 0;
	}
	set SelectedFontSize(val) {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Font) {
				this.SelectedItem.Font = new Font();
			}
			this.SelectedItem.Font.FontSize = val;
		}
	}
	//#endregion
	ClearButtonFont = false;
	ClearButtonFontSize = false;
	ChartPalettes = [];
	constructor(private service: MetaService) {
		super();
		this.fontFamilies = MediaService.GetFontList();
		this.ControlStyles = EnumHelper.GetDropdownValues(ControlStyle);
		this.BorderStyles = EnumHelper.GetDropdownValues(BorderStyle);
	}
	ngOnInit(): void {
		this.service.ReadPaletteInfos().subscribe((x) => {
			if (x) {
				this.ChartPalettes = x;
			}
		});
	}
	setSelectedItem(item) {
		this.SelectedItem = item;
		if (item) {
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
			this.checkBorderValues();
		} else {
			this.ActBorder = new BorderInfo();
		}
	}
	ChangeValueFont() {
		this.ClearButtonFont = true;
	}
	ChangeValueFontSize() {
		this.ClearButtonFontSize = true;
	}
	ResetFont() {
		delete this.SelectedItem.Font.FontFamilyName;
		this.ClearButtonFont = false;
	}
	ResetFontSize() {
		delete this.SelectedItem.Font.FontSize;
		this.ClearButtonFontSize = false;
	}
	menuKeyDown(ev) {
		MenuKeyDownHelper.onKeyDown(ev);
	}

	private static GetBorderSideObject(side) {
		if (
			side &&
			side.Thickness &&
			typeof side.Thickness.Value === 'number' &&
			side.Thickness.Value > 0 &&
			side.Color &&
			typeof side.Style === 'number'
		) {
			return {
				Width: side.Thickness.Value,
				Style: side.Style,
				Color: side.Color,
			};
		}
		return null;
	}

	checkBorderValues() {
		let actObj = null;
		if (this.SelectedItem) {
			const border = this.SelectedItem.Border;
			if (border) {
				[
					border.TopBorder,
					border.RightBorder,
					border.BottomBorder,
					border.LeftBorder,
				].some((x) => {
					const nextObj = ThemesDetail.GetBorderSideObject(x);
					if (actObj == null) {
						actObj = nextObj;
					} else if (
						nextObj != null &&
						(actObj.Width !== nextObj.Width ||
							actObj.Style !== nextObj.Style ||
							!Gradient.IsSameGradient(actObj.Color, nextObj.Color))
					) {
						actObj = null;
						return true;
					}
					return false;
				});
			}
		}
		if (actObj) {
			this.SelectedBorderWidth = actObj.Width;
			this.SelectedBorderStyle = actObj.Style;
			let color;
			if (
				actObj.Color.Colors &&
				actObj.Color.Colors.some((x) => {
					if (x.Color) {
						color = x.Color;
						return true;
					}
					return false;
				})
			) {
				this.SelectedBorderColor = Color.FromObject(color);
			} else {
				this.SelectedBorderColor = Color.FromHex('#000000');
			}
		} else {
			this.resetBorderValues();
		}
	}

	resetBorderValues() {
		this.SelectedBorderWidth = 1;
		this.SelectedBorderStyle = BorderStyle.Solid;
		this.SelectedBorderColor = Color.FromHex('#000000');
	}

	clearBorder() {
		if (this.SelectedItem) {
			this.SelectedItem.Border = new Border();
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		}
	}

	setBorderSide(side, check) {
		if (side) {
			let gradient = Gradient.Solid(Color.FromObject(this.SelectedBorderColor));
			if (
				check &&
				side.Thickness.Value === this.SelectedBorderWidth &&
				side.Style === this.SelectedBorderStyle &&
				Gradient.IsSameGradient(side.Color, gradient)
			) {
				side.Thickness.Value = 0;
				side.Thickness.Type = UnitType.Pixel;
				side.Color = null;
				side.Style = BorderStyle.Solid;
			} else {
				side.Thickness.Value = this.SelectedBorderWidth;
				side.Thickness.Type = UnitType.Pixel;
				side.Color = gradient;
				side.Style = this.SelectedBorderStyle;
			}
		}
	}

	setFullBorder() {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Border) {
				this.SelectedItem.Border = new Border();
			}
			this.setBorderSide(this.SelectedItem.Border.TopBorder, false);
			this.setBorderSide(this.SelectedItem.Border.RightBorder, false);
			this.setBorderSide(this.SelectedItem.Border.BottomBorder, false);
			this.setBorderSide(this.SelectedItem.Border.LeftBorder, false);
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		}
	}

	setTopBorder() {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Border) {
				this.SelectedItem.Border = new Border();
			}
			this.setBorderSide(this.SelectedItem.Border.TopBorder, true);
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		}
	}

	setBottomBorder() {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Border) {
				this.SelectedItem.Border = new Border();
			}
			this.setBorderSide(this.SelectedItem.Border.BottomBorder, true);
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		}
	}

	setLeftBorder() {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Border) {
				this.SelectedItem.Border = new Border();
			}
			this.setBorderSide(this.SelectedItem.Border.LeftBorder, true);
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		}
	}

	setRightBorder() {
		if (this.SelectedItem) {
			if (!this.SelectedItem.Border) {
				this.SelectedItem.Border = new Border();
			}
			this.setBorderSide(this.SelectedItem.Border.RightBorder, true);
			this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		}
	}
	ClearButtonBorderRad = false;
	ResetBorderRad() {
		this.SelectedItem.Border.BorderRadius = new BorderRadius();
		this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
		this.ClearButtonBorderRad = false;
	}
	ChangeValueBorderRad() {
		this.ClearButtonBorderRad = true;
		this.ActBorder = BorderInfo.FromBorder(this.SelectedItem.Border);
	}
}
