import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageType } from '../../../models/enums/messagetype.enum';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { MessageCenterDialog } from './messagecenter.dialog';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-messagecenter',
    templateUrl: './messagecenter.control.html',
    styleUrls: ['./messagecenter.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageCenterControl extends IBaseComponent {

    static Type: any = 'messagecenter';
    static Default = JSON.parse(JSON.stringify(defaultProperties.messagecenter));

    @ViewChild('button', { read: ElementRef }) button: ElementRef<HTMLInputElement>;
    Count = 0;
    ColorStyle = {};

    constructor(private dialog: MatDialog, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ngOnInit() {
        super.ngOnInit();
        this.Subscriptions['ReceivedMessages'] = NotificationHelper.ReceivedMessages.subscribe(x => {
            let count = 0;
            let style = {};
            if (x) {
                x.forEach(rm => {
                    if (!rm.Read) {
                        if (count === 0) {
                            switch (rm.MessageType) {
                                case MessageType.Error:
                                    style['background'] = 'rgb(244,67,54)';
                                    break;
                                case MessageType.Information:
                                    style['background'] = 'rgb(31,135,229)';
                                    break;
                                case MessageType.Success:
                                    style['background'] = 'rgb(77,174,81)';
                                    break;
                                case MessageType.Warning:
                                    style['background'] = 'rgb(254,152,2)';
                                    break;
                            }
                        }
                        count++;
                    }
                });
            }
            this.Count = count;
            this.ColorStyle = style;
            this.cdRef.detectChanges();
        });
    }

    ControlInitialized() {
    }

    open() {
        this.dialog.open(MessageCenterDialog, { panelClass: 'MessageCenterDialog', data: {} });
    }

    setFocus() {
        if (this.button && this.button.nativeElement) {
            this.button.nativeElement.focus();
        }
    }
}
