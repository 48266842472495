import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FileSelectionThemeControl } from '../../../appbuilder/controls/fileselection/fileselection.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TogglebuttongroupThemeControl } from '../../../appbuilder/controls/togglebuttongroup/togglebuttongroup.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MediaSource } from '../../../models/datamodel/mediasource.model';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-pdfviewer',
    templateUrl: './pdfviewer.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PDFViewerControl extends IBaseComponent {
    static Type: any = 'pdfviewer';
    static Default = JSON.parse(JSON.stringify(defaultProperties.pdfviewer));

    URL;
    PDFLoaded = false;

    //#region DataSource
    DataSourceValue;
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        if (this.Editable) {
            this.CreateUrl();
        }
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    constructor(private mediaService: MediaService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('PDFLoaded');
        this.EventList.push('PagesInitialized');
    }

    ControlInitialized() {
        this.CreateUrl();
    }
    onLayoutElementChanged() {
        this.CreateUrl();
    }
    CreateUrl() {
        if (this.LayoutElementValue && this.LayoutElementValue.MediaSource) {
            CacheService.ReadMediaSource(this.LayoutElementValue.MediaSource).then((source) => {
                let fileName = this.LayoutElementValue.FileName;
                if (this.Editable) {
                    if (this.DataSourceValue) {
                        fileName = this.DataSourceValue;
                    }
                }
                if (fileName) {
                    if (source.Private) {
                        this.mediaService.LoadImage(source.SID, fileName).subscribe((file) => {
                            this.URL = file;
                            this.cdRef.detectChanges();
                        });
                    } else {
                        this.URL = MediaSource.GetURL(source, fileName + '?a=' + Math.random());
                    }
                } else {
                    this.URL = '';
                }
                this.cdRef.detectChanges();
            });
        }
    }

    onLoaded(ev) {
        this.PDFLoaded = true;
        this.triggerEvent('PDFLoaded', ev);
    }

    onPages(ev) {
        this.triggerEvent('PagesInitialized', ev);
    }
}
export class PdfViewerPanel extends BasePanel{
    static override SIDS = ['377bf13f-3e7a-4c91-82e4-2e1025bb42af']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'377bf13f-3e7a-4c91-82e4-2e1025bb42af',
            ID: 'pdfviewer',
            Caption: '@@PDFViewer',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'pdfviewer';
            }
        })
        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "MediaSource",
                Parent: "pdfviewer",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@SelectMediaSource",
                Row: 1,
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: false,
                    ItemsSource: data,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'SID'
                }
            })
        });
        PROPERTIES.push({
            ID: "FileName",
            Parent: "pdfviewer",
            Content: new ComponentPortal(FileSelectionThemeControl),
            Row: 2,
            CheckVisibility: (item) => {
                return item.MediaSource;
            }
        })
        PROPERTIES.push({
            ID: "ShowSinglePage",
            Parent: "pdfviewer",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Show single page"
            },
            Row: 3,
            CheckVisibility: (item) => {
                return item.MediaSource;
            }
        })
        PROPERTIES.push({
            ID: "PageToShow",
            Parent: "pdfviewer",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Page to show",
            Row: 4,
            CheckVisibility: (item) => {
                return item.ShowSinglePage && item.MediaSource;
            },
            InitArgs: {
                InputType: "number",
                Minimum:1
            }
        });
        let rotation = [{
                Caption: '0°',
                Value: '0'
            }, {
                Caption: '90°',
                Value: '90'
            }, {
                Caption: '180°',
                Value: '180'
            }, {
                Caption: '270°',
                Value: '270'
            }]

        PROPERTIES.push({
            ID: "PDFRotation",
            Parent: "pdfviewer",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Rotation",
            Row: 5,
            InitArgs: {
                Placeholder: "@@Select...",
                Multiple: false,
                ItemsSource: rotation,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            },
            CheckVisibility: (item) => {
                return item.MediaSource;
            }
        });
        PROPERTIES.push({
            ID: "PageAlignment",
            Parent: "pdfviewer",
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Label: "@@Page alignment",
            LabelPosition: MenuTabLabelPosition.Top,
            Row: 6,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: "align_left",
                    Value: 0,
                    Tooltip: "@@LeftJustified"
                }, {
                    ButtonIcon: "align_center",
                    Value: 1,
                    Tooltip: "@@Centered"
                }, {
                    ButtonIcon: "align_right",
                    Value: 2,
                    Tooltip: "@@RightJustified"
                }]
            },
            CheckVisibility: (item) => {
                return item.MediaSource;
            }
        });
    }
}