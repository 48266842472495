import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { Resizeable } from '../../../models/enums/resizeable.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-textarea',
    templateUrl: './textarea.control.html',
    styleUrls: ['./textarea.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaControl extends IBaseComponent {

    static Type = 'textarea';
    static Default = JSON.parse(JSON.stringify(defaultProperties.textarea));

    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        this.DataSourceChange.emit(this.DataSourceValue);
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
        this.cdRef.detectChanges();
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('textchanged');
    }

    ControlInitialized() {
    }

    setFocus() {
        let textBox = document.getElementById(this.LayoutElement.ID);
        if (textBox) {
            textBox.focus();
        }
    }

    onChange(ev) {
        this.triggerEvent('textchanged', this.DataSourceValue);
    }

    CheckLayoutElement() {
        if (!this.LayoutElement) {
            this.LayoutElement = new LayoutElement();
        }
    }
}
export class TextAreaPanel extends BasePanel {
    static override SIDS = ['04a3a9a2-bdc2-42ab-816c-861d6b8c4dbd']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'04a3a9a2-bdc2-42ab-816c-861d6b8c4dbd',
            ID: 'TextArea',
            Caption: '@@TextArea',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto','auto','auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'textarea';
            }
        })
       
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "TextArea",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            Column: 1,
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        })
        PROPERTIES.push({
            ID: "UseFloatingLabel",
            Parent: "TextArea",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseFloatingLabel"
            },
            Column: 1,
            Row: 2
        })
        PROPERTIES.push({
            ID: "Resizeable",
            Parent: "TextArea",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Resizeable",
            Column: 1,
            Row: 3,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: Resizeable
            }
        })
    }
}