import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'abaseelement-name-control',
    templateUrl: './abaseelement.name.control.html',
    styleUrls: ['./abaseelement.name.control.css']
})
export class ABaseElementNameControl {
    //#region ABaseElement
    ABaseElementValue;
    @Input()
    get ABaseElement() {
        return this.ABaseElementValue;
    }
    set ABaseElement(val) {
        this.ABaseElementValue = val;
        this.ABaseElementChange.emit(this.ABaseElementValue);
    }
    @Input()
    required: boolean;
    @Output() ABaseElementChange = new EventEmitter<any>();
    //#endregion

    //#region ShowCapsuleTags
    ShowCapsuleTagsValue = true;
    @Input()
    get ShowCapsuleTags() {
        return this.ShowCapsuleTagsValue;
    }
    set ShowCapsuleTags(val) {
        this.ShowCapsuleTagsValue = val;
        this.ShowCapsuleTagsChange.emit(this.ShowCapsuleTagsValue);
    }

    @Output() ShowCapsuleTagsChange = new EventEmitter<any>();
    //#endregion

    //#region CheckName
    CheckNameValue = true;
    @Input()
    get CheckName() {
        return this.CheckNameValue;
    }
    set CheckName(val) {
        this.CheckNameValue = val;
        this.CheckNameChange.emit(this.CheckNameValue);
    }

    @Output() CheckNameChange = new EventEmitter<any>();
    //#endregion

    @Output() ElementChanged = new EventEmitter<any>();

    onElementChanged() {
        this.ElementChanged.emit();
    }

    nameChanged() {
        if (this.ABaseElementValue) {
            if (this.CheckNameValue) {
                this.ABaseElementValue.Name = this.ABaseElementValue.Name.replace(/[\W]/gi, '');
            }
            if (this.ABaseElementValue.Caption && !this.ABaseElementValue.Caption.DefaultValue) {
                this.ABaseElementValue.Caption.DefaultValue = this.ABaseElementValue.Name;
                return true;
            }
        }
        this.onElementChanged();
        return false;
    }
}
