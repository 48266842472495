import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslatedString } from '../../../models/translatedstring.model';
import { TranslationTextBoxDialog } from './translation.textbox.dialog';

@Component({
    selector: 'translation-textbox',
    templateUrl: './translation.textbox.control.html',
    styleUrls: ['./translation.textbox.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationTextBox {
    //#region TranslatedString
    TranslatedStringValue: TranslatedString;

    @Input()
    get TranslatedString() {
        return this.TranslatedStringValue;
    }
    set TranslatedString(val) {
        this.TranslatedStringValue = val;
        if (!val) {
            this.TranslatedStringValue = new TranslatedString();
        }
        this.TranslatedStringChange.emit(this.TranslatedStringValue);
    }
    @Input() required: boolean;
    @Input() appearance: string;
    @Output() TranslatedStringChange = new EventEmitter<any>();
    //#endregion
    //#region Disbaled
    DisabledValue;
    @Input()
    get Disabled(): boolean {
        return this.DisabledValue;
    }
    set Disabled(val: boolean) {
        this.DisabledValue = val;
        this.DisabledChange.emit(this.DisabledValue);
    }

    @Output() DisabledChange = new EventEmitter<boolean>();
    //#endregion
    //#region Placeholder
    PlaceholderValue: string;

    @Input()
    get Placeholder() {
        return this.PlaceholderValue;
    }
    set Placeholder(val) {
        this.PlaceholderValue = val;
        this.PlaceholderChange.emit(this.PlaceholderValue);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion

    //#region Editable
    EditableValue = true;

    @Input()
    get Editable() {
        return this.EditableValue;
    }
    set Editable(val) {
        this.EditableValue = val;
        this.EditableChange.emit(this.EditableValue);
    }

    @Output() EditableChange = new EventEmitter<any>();
    //#endregion

    //#region Placeholder
    MultilineValue = false;

    @Input()
    get Multiline() {
        return this.MultilineValue;
    }
    set Multiline(val) {
        this.MultilineValue = val;
        this.MultilineChange.emit(this.MultilineValue);
    }

    @Output() MultilineChange = new EventEmitter<any>();
    //#endregion

    @Output() Saved = new EventEmitter<TranslatedString>();
    @ViewChild('textBox', { read: ElementRef }) textBox: ElementRef<HTMLInputElement>;
    constructor(private dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    }

    onSaved() {
        if (this.TranslatedStringValue) {
            this.Saved.emit(this.TranslatedStringValue);
        }
    }

    defaultChanged(ev) {
        if (this.TranslatedStringValue) {
            this.TranslatedStringValue.DefaultValue = ev;
            this.onSaved();
        }
    }

    openDialog(ev) {
        if (this.TranslatedStringValue) {
            const dialogRef = this.dialog.open(TranslationTextBoxDialog, { data: { Data: this.TranslatedStringValue } });
            dialogRef.afterClosed().subscribe(() => this.onSaved());
        }
    }

    setFocus() {
        if (this.textBox && this.textBox.nativeElement) {
            this.textBox.nativeElement.focus();
        }
    }

    detectChanges() {
        this.cdRef.detectChanges();
    }
}
