import { ComponentPortal } from "@angular/cdk/portal";
import { BehaviorSubject } from "rxjs";
import { InjectorHelper, TranslateHelper } from "../../helpers/injector.helper";
import { ThemeHelper } from "../../helpers/theme.helpers";
import { BackgroundPosition, BackgroundRepeat, BackgroundSize } from "../../models/enums/background.enum";
import { PropertyGroupDisplay } from "../../models/enums/propertygroupdisplay.enum";
import { MultiPropertyChangeValue } from "../../models/layout/layout.change.model";
import { StyleBase } from "../../models/layoutbase.model";
import { MenuTabLabelPosition } from "../../models/menutab/menutab.property.model";
import { Border } from "../../models/style/border.model";
import { Font } from "../../models/style/font.model";
import { Gradient } from "../../models/style/gradient.model";
import { PROPERTIES, PROPERTYGROUPS } from "../../services/dynamic.component.service";
import { LayoutService } from "../../services/layout.service";
import { MediaService } from "../../services/media.service";
import { MetaService } from "../../services/meta.service";
import { BorderThemeControl } from "../controls/borderselection/borderselection.theme.control";
import { BoxShadowThemeControl } from "../controls/boxshadow/boxshadow.theme.control";
import { ButtonThemeControl } from "../controls/button/button.theme.control";
import { ColorpickerThemeControl } from "../controls/colorpicker/colorpicker.theme.control";
import { ComboboxThemeControl } from "../controls/combobox/combobox.theme.control";
import { FileSelectionThemeControl } from "../controls/fileselection/fileselection.theme.control";
import { GradientpickerThemeControl } from "../controls/gradientpicker/gradientpicker.theme.control";
import { InputswitchThemeControl } from "../controls/inputswitch/inputswitch.theme.control";
import { TogglebuttonThemeControl } from "../controls/togglebutton/togglebutton.theme.control";
import { GenericMenuTab } from "../menutabs/generic/generic.menu.tab";
import { BasePanel } from "./base.panel";

export class DecorationsPanel extends BasePanel {
    private static Font: BehaviorSubject<Font> = new BehaviorSubject<Font>(null)
    private static Border: BehaviorSubject<Border> = new BehaviorSubject<Border>(null)
    private static LeftBorder: BehaviorSubject<Border> = new BehaviorSubject<Border>(null)
    private static BottomBorder: BehaviorSubject<Border> = new BehaviorSubject<Border>(null)
    private static RightBorder: BehaviorSubject<Border> = new BehaviorSubject<Border>(null)
    private static TopBorder: BehaviorSubject<Border> = new BehaviorSubject<Border>(null)
    private static SelectedItemSub: BehaviorSubject<Border> = new BehaviorSubject<Border>(null)
    static override SetSelectedItem(item) {
        DecorationsPanel.SelectedItemSub.next(item);
        if (item) {
            if (!item.Font) {
                item.Font = new Font();
            }
            if (!item.Border) {
                item.Border = new Border();
            }
            DecorationsPanel.Font.next(item.Font);
            DecorationsPanel.Border.next(item.Border);
            DecorationsPanel.RightBorder.next(item.Border.RightBorder);
            DecorationsPanel.LeftBorder.next(item.Border.LeftBorder);
            DecorationsPanel.BottomBorder.next(item.Border.BottomBorder);
            DecorationsPanel.TopBorder.next(item.Border.TopBorder);
        } else {
            DecorationsPanel.Font.next(null);
            DecorationsPanel.Border.next(null);
        }
    }
    static override SIDS = ['005cecb9-e72c-48ee-af12-6a2602a79dcf']
    static InitPanel() {
        this.InitSelectedItem()
        this.InitSelectedObject()
        PROPERTYGROUPS.push({
            SID:'005cecb9-e72c-48ee-af12-6a2602a79dcf',
            ID: 'decorations',
            Caption: '@@Decorations',
            Index: 1,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Sections: [
                {
                    ID: 'style',
                    Display: PropertyGroupDisplay.Grid,
                    Columns: ['33%','33%','33%'],
                    Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
                }, {
                    ID: 'border',
                    Display: PropertyGroupDisplay.Grid,
                    Columns: ['120px', 'calc(50% - 60px)', 'calc(50% - 60px)'],
                    Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
                }, {
                    ID: 'shadow',
                    Display: PropertyGroupDisplay.Grid,
                    Columns: ['50%', '25%','25%'],
                    Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
                }
            ]
        });
        PROPERTIES.push({
            ID: "UseDecorationsOnLayout",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(InputswitchThemeControl),
            DataSource: DecorationsPanel.SelectedItemSub,
            Label: "@@UseDecorationsOnLayout",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 3,
            Row: 1,
        });
        let metaService = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
        metaService.GetControlStyles().subscribe(result => {
            const list = [{ Caption: TranslateHelper.TranslatorInstance.instant('@@None'), SID: null }];
            if (result) {
                list.push(...result);
            }
            let StyleList = list;
            PROPERTIES.push({
                ID: "StyleRef",
                Section:'style',
                Parent: "decorations",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "Style Reference",
                Column: 1,
                ColSpan: 3,
                Row: 3,
                InitArgs: {
                    Placeholder: "Style Referenz",
                    Multiple: false,
                    ItemsSource: StyleList,
                    ValueMemberPath: "SID",
                    DisplayMemberPath: "Caption",
                    Action: (ev) => {
                        if (ev) {
                            if (ev.SelectedItem && ev.SelectedItem.ComponentChanged) {
                                ev.SelectedItem.ComponentChanged.next(true);
                            }
                            ev.Source.onStyleChanged('StyleRef');
                        }
                    }
                }
            });
            PROPERTIES.push({
                ID: "StyleRefButton",
                Parent: "decorations",
                Section: 'style',
                Content: new ComponentPortal(ButtonThemeControl),
                Column: 1,
                ColSpan:3,
                Row: 4,
                InitArgs: {
                    Tooltip: "@@Transferred to object",
                    Action: (ev) => {
                        if (ev && ev.SelectedItem) {
                            const selItem = ev.SelectedItem;
                            if (selItem && selItem.StyleRef) {
                                metaService.GetControlStyle(selItem.StyleRef).subscribe(result => {
                                    if (result && result.StyleJSON) {
                                        const styleList = StyleBase.applyToObject(selItem, result.StyleJSON);
                                        selItem.StyleRef = null;
                                        styleList.push('StyleRef');
                                        ev.Source.onStyleChanged(styleList);
                                        ev.Source.cdRef.detectChanges();
                                    }
                                });
                            }
                        }
                    },
                    Caption: "@@Transferred to object"
                }
            });
        })
        PROPERTIES.push({
            ID: "CSSClasses",
            Section:"style",
            Parent: "decorations",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@CSSClasses",
            LabelPosition: MenuTabLabelPosition.Top,
            Column: 1,
            Row: 2,
            ColSpan: 3,
            InitArgs: {
                Placeholder: "CSS Class",
                Multiple:true,
                ItemsSourceSub: ThemeHelper.AvailableClassesSub,
                DisplayMemberPath: 'ClassName',
                Value:'ClassName'
            }
        });
        PROPERTIES.push({
            ID: "Bold",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(TogglebuttonThemeControl),
            DataSource: DecorationsPanel.Font,
            Label: "@@Bold",
            LabelPosition: MenuTabLabelPosition.Bottom,
            Column: 1,
            Row: 5,
            InitArgs: {
                Icon: 'bold'
            }
        });
        PROPERTIES.push({
            ID: "Italic",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(TogglebuttonThemeControl),
            DataSource: DecorationsPanel.Font,
            Label: "@@Italic",
            LabelPosition: MenuTabLabelPosition.Bottom,
            Column: 2,
            Row: 5,
            InitArgs: {
                Icon: 'italic'
            }
        });
        PROPERTIES.push({
            ID: "Underline",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(TogglebuttonThemeControl),
            DataSource: DecorationsPanel.Font,
            Label: "@@Underline",
            LabelPosition: MenuTabLabelPosition.Bottom,
            Column: 3,
            Row: 5,
            InitArgs: {
                Icon: 'underline'
            }
        });       
        PROPERTIES.push({
            ID: "FontColor",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(ColorpickerThemeControl),
            DataSource: DecorationsPanel.Font,
            Label: "@@FontColor",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 3,
            Row: 6,
            InitArgs: {
                Icon: 'font_color'
            }
        });
        //#region Background
        PROPERTIES.push({
            ID: "BackgroundColor",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(GradientpickerThemeControl),
            Type: Gradient,
            Label: "@@BackgroundColor",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 3,
            Row: 7,
            InitArgs: {
                Icon: 'background_color'
            },
            CheckVisibility: (item) => {
                return !item.BackgroundIsImage
            }
        });
        PROPERTIES.push({
            ID: "BackgroundIsImage",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(InputswitchThemeControl),
            DataSource: DecorationsPanel.SelectedItemSub,
            Label: "@@BackgroundIsImage",
            LabelPosition: MenuTabLabelPosition.Right,
            Icon: 'image',
            Column: 1,
            ColSpan:3,
            Row: 8,
            CheckVisibility: (item) => {
                let styleobj = Gradient.getStyleObject(item.BackgroundColor);
                if (Object.keys(styleobj).length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        delete item.SelectedItem.MediaSource;
                        delete item.SelectedItem.BackgroundImage;
                        delete item.SelectedItem.BackgroundSize;
                        delete item.SelectedItem.BackgroundPosition;
                        delete item.SelectedItem.BackgroundRepeat;
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.ElementIDs = [item.SelectedItem.ID],
                            ['MediaSource', 'BackgroundImage', 'BackgroundSize', 'BackgroundPosition', 'BackgroundRepeat'].forEach(x => {
                                mlpcv.Properties.push({
                                    PropertyName: x,
                                    Value: null
                                });
                            });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                }
            }
        });
        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "MediaSource",
                Parent: "decorations",
                Section: 'style',
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@SelectMediaSource",
                Column: 1,
                ColSpan:3,
                Row: 9,
                CheckVisibility: (item) => {
                    //if (item.MediaSource) {
                    //    item.BackgroundIsImage = true;
                    //    LayoutService.LayoutChanged.next();
                    //}
                    return item.BackgroundIsImage;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: false,
                    ItemsSource: data,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'SID'
                }
            })
        });
        PROPERTIES.push({
            ID: "BackgroundImage",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(FileSelectionThemeControl),
            Row: 10,
            Column:1,
            ColSpan:3,
            CheckVisibility: (item) => {
                return item.BackgroundIsImage && item.MediaSource;
            }
        })
        PROPERTIES.push({
            ID: "BackgroundSize",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Background size",
            Row: 11,
            Column: 1,
            ColSpan:3,
            InitArgs: {
                Placeholder: "@@SelectSize",
                Multiple: false,
                EnumSource: BackgroundSize
            },
            CheckVisibility: (item) => {
                return item.BackgroundIsImage && item.MediaSource && item.BackgroundImage;
            }
        })
        PROPERTIES.push({
            ID: "BackgroundPosition",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Background position",
            Row: 12,
            Column: 1,
            ColSpan: 3,
            InitArgs: {
                Placeholder: "@@SelectPosition",
                Multiple: false,
                EnumSource: BackgroundPosition
            },
            CheckVisibility: (item) => {
                return item.BackgroundIsImage && item.MediaSource && item.BackgroundImage;
            }
        })
        PROPERTIES.push({
            ID: "BackgroundRepeat",
            Parent: "decorations",
            Section: 'style',
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Background repeat",
            Row: 13,
            Column: 1,
            ColSpan: 3,
            InitArgs: {
                Placeholder: "@@SelectRepeat",
                Multiple: false,
                EnumSource: BackgroundRepeat
            },
            CheckVisibility: (item) => {
                return item.BackgroundIsImage && item.MediaSource && item.BackgroundImage;
            }
        })
        //#endregion
        //#region Border
        PROPERTIES.push({
            ID: "UseBorder",
            Parent: "decorations",
            Section: 'border',
            Content: new ComponentPortal(InputswitchThemeControl),
            DataSource: DecorationsPanel.SelectedItemSub,
            Label: "@@UseBorder",
            LabelPosition: MenuTabLabelPosition.Right,
            Icon: 'use_border',
            Column: 1,
            ColSpan: 3,
            Row: 1,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        delete item.SelectedItem.Border;
                        LayoutService.OnLayoutPropertyChanged(item.SelectedItem.ID, 'Border', null);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                    LayoutService.LayoutChanged.next(null);
                }
            }
        });
        PROPERTIES.push({
            ID: "Border",
            Parent: "decorations",
            Section: 'border',
            Content: new ComponentPortal(BorderThemeControl),
            Type: Border,
            Column: 1,
            ColSpan: 3,
            Row: 5,
            CheckVisibility: (item) => {
                let borderSet = (item.Border && item.Border.toJSON && item.Border.toJSON() != null);
                if (item.UseBorder == null) {
                    item.UseBorder = borderSet;
                    this.SelectedItemSub.next(this.SelectedItem);
                }
                return item.UseBorder || borderSet;
            }
        });
        //#endregion
        //#region Shadow
        PROPERTIES.push({
            ID: "DropShadow",
            Parent: "decorations",
            Section: 'shadow',
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@DropShadow",
            LabelPosition: MenuTabLabelPosition.Right,
            Icon: 'drop_shadow',
            Column: 1,
            ColSpan: 3,
            Row: 1,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        delete item.SelectedItem.BoxShadow;
                        LayoutService.OnLayoutPropertyChanged(item.SelectedItem.ID, 'BoxShadow', null);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                    LayoutService.LayoutChanged.next(null);
                }
            }
        });
        PROPERTIES.push({
            ID: "BoxShadow",
            Parent: "decorations",
            Section: 'shadow',
            Content: new ComponentPortal(BoxShadowThemeControl),
            Column: 1,
            ColSpan: 3,
            Row: 2,
            CheckVisibility: (item) => {
                let set = (item.BoxShadow && item.BoxShadow.length > 0);
                if (item.DropShadow == null) {
                    item.DropShadow = set;
                    this.SelectedItemSub.next(this.SelectedItem);
                }
                return item.DropShadow || set;
            }
        });
        //#endregion
    }
}