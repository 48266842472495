import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IBaseComponent } from '../../controls/base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-content',
    templateUrl: './content.control.html',
    styleUrls: ['./content.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentControl extends IBaseComponent {
    static Type: any = 'content';
    static Default = JSON.parse(JSON.stringify(defaultProperties.content));

    LayoutEditDailog = false;
    SelectedPage = 'content';
    ControlInitialized() {
        this.cdRef.detectChanges();
    }
}
