import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { KeyFilter } from '../../../models/enums/keyfilter.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import {Observable} from "rxjs";
import {GlobalSearchService} from "../../../services/global-search.service";
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';
@Component({
    selector: 'evi-textbox',
    templateUrl: './textbox.control.html',
    styleUrls: ['./textbox.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBoxControl extends IBaseComponent {
    static Type: any = 'textbox';
    static Default = JSON.parse(JSON.stringify(defaultProperties.textbox));

    Valid = true;
    KeyDownTimeOut;

    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion
    //#region InputType
    @Input()
    get InputType(): string {
        return this.LayoutElement.InputType;
    }
    set InputType(val: string) {
        this.LayoutElement.InputType = val;
        this.InputTypeChange.emit(this.LayoutElement.InputType);
    }

    @Output() VariantChange = new EventEmitter<string>();
    @Input()
    get Variant(): string {
        return this.LayoutElement.Variant;
    }
    set Variant(val: string) {
        this.LayoutElement.Variant = val;
        this.VariantChange.emit(this.LayoutElement.Variant);
    }

    @Output() InputTypeChange = new EventEmitter<string>();
    //#endregion
    //#region Tooltip
    TooltipValue;
    @Input()
    get Tooltip() {
        if (this.TooltipValue != null) {
            return this.TooltipValue;
        }
        return this.LayoutElement.Tooltip;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    //#region Minimum
    @Input()
    get Minimum(): string {
        return this.LayoutElement.Minimum;
    }
    set Minimum(val: string) {
        this.LayoutElement.Minimum = val;
        this.MinimumChange.emit(this.LayoutElement.Minimum);
    }

    @Output() MinimumChange = new EventEmitter<string>();
    //#endregion

    //#region Maximum
    @Input()
    get Maximum(): string {
        return this.LayoutElement.Maximum;
    }
    set Maximum(val: string) {
        this.LayoutElement.Maximum = val;
        this.MaximumChange.emit(this.LayoutElement.Maximum);
    }

    @Output() MaximumChange = new EventEmitter<string>();
    //#endregion

    //#region Nachkommastellen
    @Input()
    get DecimalPositions(): string {
        return this.LayoutElement.DecimalPositions;
    }
    set DecimalPositions(val: string) {
        this.LayoutElement.DecimalPositions = val;
        if (this.DataSourceValue && this.DecimalPositions && Number(this.DecimalPositions) > 0) {
            this.DataSourceValue = Number(this.DataSourceValue).toFixed(Number(this.DecimalPositions));
        }
        this.DecimalPositionsChange.emit(this.LayoutElement.DecimalPositions);
    }

    @Output() DecimalPositionsChange = new EventEmitter<string>();
    //#endregion

    //#region Step
    @Input()
    get Step(): string {
        if (this.LayoutElement.Step !== undefined) {
            return this.LayoutElement.Step;
        }
        return 'any;';
    }
    set Step(val: string) {
        this.LayoutElement.Step = val;
        this.StepChange.emit(this.LayoutElement.Step);
    }

    @Output() StepChange = new EventEmitter<string>();
    //#endregion

    //#region ReadOnly
    ReadOnlyValue;
    get ReadOnly(): boolean {
        if (typeof this.ReadOnlyValue === 'boolean') {
            return this.ReadOnlyValue;
        }
        return this.LayoutElement.ReadOnly;
    }
    set ReadOnly(val) {
        this.ReadOnlyValue = val;
    }
    //#endregion
    get KeyFilter() {
        let result = null;
        if (this.LayoutElement && this.LayoutElement.UseValidation) {
            if (this.LayoutElement.KeyFilter && this.LayoutElement.KeyFilter == KeyFilter.regex) {
                if (this.LayoutElement.RegExp) {
                    result = new RegExp(this.LayoutElement.RegExp);
                }
            } else {
                result = KeyFilter[this.LayoutElement.KeyFilter];
            }
        }
        return result;
    }

    PWVisible = false;
    get ActualInputType() {
        if (this.LayoutElementValue.InputType == 'password' && this.LayoutElementValue.ShowPassword && this.PWVisible) {
            return 'text';
        }
        return this.LayoutElementValue.InputType;
    }

    IsRtl = false;

    // Global Search: variables
    filteredOptions: Observable<any[]>;

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data, private globalSearchService: GlobalSearchService) {
        super(cdRef, data);
        this.EventList.push('textchanged');
        this.EventList.push('keypress');
        this.EventList.push('keydown');
        this.EventList.push('keyup');
        this.EventList.push('ValidationFailed');
        this.PropertyList.push(new ElementProperty('ReadOnly', 'boolean', '@@ReadOnly'));
        this.PropertyList.push(new ElementProperty('DataSourceValue', 'string', '@@Text'));
        this.PropertyList.push(new ElementProperty('Tooltip', 'string', '@@Tooltip'));
    }

    ngOnInit() {
        super.ngOnInit();
        this.IsRtl = RTLHelper.Direction == 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir == 'rtl';
        });
    }

    MetaTable;
    MetaTableProperties;
    DataSourceProperty;
    @Output() TextChange = new EventEmitter<string>();
    invalidChars = ['e'];
    DEFAULT_MASKS = {
        pint: /[\d]/,
        'int': /[\d\-]/,
        pnum: /[\d\.]/,
        money: /[\d\.\s,]/,
        num: /[\d\-\.]/,
        hex: /[0-9a-f]/i,
        email: /[a-z0-9_\.\-@]/i,
        alpha: /[a-z_]/i,
        alphanum: /[a-z0-9_]/i
    };
    Keys = [
        9, 13, 27, 8, 46, 36, , 35, 37, 39
    ]
    KEYS = {
        TAB: 9,
        RETURN: 13,
        ESC: 27,
        BACKSPACE: 8,
        DELETE: 46
    };
    SAFARI_KEYS = {
        63234: 37,
        63235: 39,
        63232: 38,
        63233: 40,
        63276: 33,
        63277: 34,
        63272: 46,
        63273: 36,
        63275: 35 // end
    };
    ControlInitialized() {
        // let textBox = document.getElementById(this.LayoutElement.ID);
        // console.log(textBox);
    }


    // textBox: ElementRef;
    // @ViewChild('textBox') set text(element: ElementRef) {
    //    this.textBox = element;
    // }
    setFocus() {
        const textBox = document.getElementById(this.LayoutElement.ID);
        if (textBox) {
            textBox.focus();
        }
    }
    CheckValidation(text = null, keypress = false) {
        let ok = true;
        if (this.LayoutElement && this.LayoutElement.UseValidation) {
            let regex = null;
            if (this.LayoutElement.KeyFilter) {
                if (this.LayoutElement.KeyFilter == KeyFilter.regex) {
                    regex = new RegExp(this.LayoutElement.RegExp);
                } else {
                    let key = KeyFilter[this.LayoutElement.KeyFilter];
                    regex = this.DEFAULT_MASKS[key];
                }
            }
            if (!regex) {
                return true;
            }
            let data = text;
            if (!text && this.DataSourceValue) {
                data = this.DataSourceValue.toString();
            }
            if (!keypress || this.LayoutElement.ValidateOnly) {
                data = this.DataSourceValue.toString();
            }

            if (data) {
                if (this.LayoutElement.CheckWholeExpression) {
                    ok = regex.test(data);
                } else {
                    for (let char of data) {
                        if (!regex.test(char)) {
                            ok = false;
                            break;
                        }
                    }
                }
            }
            if (this.LayoutElement.ValidateOnly) {
                this.triggerEvent('ValidationFailed', !ok);
                if (keypress) {
                    ok = true;
                }
            }
        }
        if (this.LayoutElement.ValidateOnly) {
            if (this.Valid != ok) {
                this.Valid = ok;
                this.cdRef.detectChanges();
            }
        } else {
            this.Valid = true;
        }
        return ok;
    }
    onChange(ev) {
        this.CheckValidation();
        if (this.LayoutElement.InputType === 'number') {

            let setMaxValue = false;
            let setMinValue = false;

            let valueNumberTemp = Number(this.DataSourceValue);
            if (this.DataSourceValue < this.Minimum) {
                valueNumberTemp = Number(this.Minimum);
                setMinValue = true;

            } else if (this.DataSourceValue > this.Maximum) {
                valueNumberTemp = Number(this.Maximum);
                setMaxValue = true;
            }

            if (this.DecimalPositions && Number(this.DecimalPositions) > 0) {

                const valueString = valueNumberTemp.toString();
                valueNumberTemp = Number(valueNumberTemp.toFixed(Number(this.DecimalPositions)));

                if (!setMaxValue && !setMinValue && valueString !== valueNumberTemp.toString()) {
                    NotificationHelper.Info('@@Der Wert wurde auf die maximale Nachkommastellenanzahl von {0} gesetzt.', '@@maximal {0} Nachkommstellen zulaessig', { 0: this.DecimalPositions }, { 0: this.DecimalPositions });
                }
            }

            if (setMaxValue) {
                NotificationHelper.Info('@@Der Wert wurde auf das Maximum {0} gesetzt. Die maximale Nachkommastellenanzahl von {1} wurde beachtet.', '@@Maximum ueberschritten', { 0: valueNumberTemp, 1: this.DecimalPositions });
            }
            if (setMinValue) {
                NotificationHelper.Info('@@Der Wert wurde auf das Minimum {0} gesetzt. Die maximale Nachkommastellenanzahl von {1} wurde beachtet.', '@@Minimum unterschritten', { 0: valueNumberTemp, 1: this.DecimalPositions });
            }

            if (!isNaN(valueNumberTemp)) {
                this.DataSource = valueNumberTemp;
            }
        }
        if (this.DataSourceValue != null) {
            const eventValue = '' + this.DataSourceValue;
            this.triggerEvent('textchanged', eventValue);
            this.TextChange.emit(eventValue);
        }
    }
    isSpecialKey(e) {
        let k = e.keyCode || e.charCode;
        return k == 9 || k == 13 || k == 27 || k == 16 || k == 17 || (k >= 18 && k <= 20) ||
            (DomHandler.getBrowser().opera && !e.shiftKey && (k == 8 || (k >= 33 && k <= 35) || (k >= 36 && k <= 39) || (k >= 44 && k <= 45)));
    }
    getKey(e) {
        let k = e.keyCode || e.charCode;
        return DomHandler.getBrowser().safari ? (this.SAFARI_KEYS[k] || k) : k;
    }
    getCharCode(e) {
        return e.charCode || e.keyCode || e.which;
    }
    KeyDown(event) {
        this.cdRef.detach();
        let k = this.getKey(event);
        if (event.key == "F2") {
            LayoutService.KeyPress.next(event);
        }
        if (!event.ctrlKey && this.Keys.indexOf(k) == -1 && !this.LayoutElement.ValidateOnly && !this.CheckValidation(event.key, true)) {
            event.preventDefault();
        }
        if (this.LayoutElementValue.InputType === 'number' && this.invalidChars.includes(event.key)) {
            event.preventDefault();
        } else {
            if (this.LayoutElementValue.UseKeyDownDelay &&
                typeof this.LayoutElementValue.KeyDownDelay === 'number' && this.LayoutElementValue.KeyDownDelay > 0) {
                clearTimeout(this.KeyDownTimeOut);
                this.KeyDownTimeOut = setTimeout(() => {
                    this.triggerEvent('keydown', event);
                }, this.LayoutElementValue.KeyDownDelay);
            } else {
                this.triggerEvent('keydown', event);
            }
        }
    }

    KeyPress(event) {
        this.triggerEvent('keypress', event);
    }
    KeyUp(event) {
        if (this.LayoutElement.ValidateOnly) {
            this.CheckValidation();
        }
        this.triggerEvent('keyup', event);
    }
    SwitchPWVisible() {
        this.PWVisible = !this.PWVisible;
        this.cdRef.detectChanges();
    }

    // Global Search: search filter
    globalSearch(value) {
        if (this.Tooltip == 'Global_Search') {
            this.filteredOptions = this.globalSearchService.search(value);
            this.cdRef.detectChanges();
        }
    }


    // Global Search: navigate to selected route
    navigateRoute(nav) {
        this.globalSearchService.CallNavMenu(nav);
    }
}
export class TextBoxPanel extends BasePanel {
    static override SIDS = ['324598dd-e6f8-4db2-bc43-5328d8e5e884']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID: '324598dd-e6f8-4db2-bc43-5328d8e5e884',
            ID: 'TextBox',
            Caption: '@@TextBox',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'textbox';
            }
        });
        //#region InputTypes
        let inputTypes = [{
            Caption: '@@Color',
            Value: 'color'
        }, {
            Caption: '@@Date',
            Value: 'date'
        }, {
            Caption: '@@DateTime',
            Value: 'datetime-local'
        }, {
            Caption: '@@EMail',
            Value: 'email'
        }, {
            Caption: '@@Month',
            Value: 'month'
        }, {
            Caption: '@@Number',
            Value: 'number'
        }, {
            Caption: '@@Password',
            Value: 'password'
        }, {
            Caption: '@@Search',
            Value: 'search'
        }, {
            Caption: '@@Phone',
            Value: 'tel'
        }, {
            Caption: '@@Text',
            Value: 'text'
        }, {
            Caption: '@@Time',
            Value: 'time'
        }, {
            Caption: '@@URL',
            Value: 'url'
        }, {
            Caption: '@@Week',
            Value: 'week'
        }]

        //#endregion InputTypes
        let inputModes = [{
            Caption: '@@None',
            Value: 'none'
        }, {
            Caption: '@@Text',
            Value: 'text'
        }, {
            Caption: '@@Decimal',
            Value: 'decimal'
        }, {
            Caption: '@@Numeric',
            Value: 'numeric'
        }, {
            Caption: '@@Telephone',
            Value: 'tel'
        }, {
            Caption: '@@Search',
            Value: 'search'
        }, {
            Caption: '@@Email',
            Value: 'email'
        }, {
            Caption: '@@URL',
            Value: 'url'
        }]

        let variants = [
            {
                Caption: '@@Outline',
                Value: 'outline'
            },
            {
                Caption: '@@Fill',
                Value: 'fill'
            },
            {
                Caption: '@@Standard',
                Value:''
            }
        ]
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "TextBox",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            Column: 1,
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "Tooltip",
            Parent: "TextBox",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Tooltip",
            Column: 1,
            Row: 2,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "InputType",
            Parent: "TextBox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Input type",
            Column: 1,
            Row: 3,
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: inputTypes,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "InputMode",
            Parent: "TextBox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Input mode",
            Column: 1,
            Row: 4,
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: inputModes,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });

        PROPERTIES.push({
            ID: "Variant",
            Parent: "TextBox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Variant",
            Column: 1,
            Row: 5,
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: variants,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "ReadOnly",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ReadOnly"
            },
            Column: 1,
            Row: 6
        });
        PROPERTIES.push({
            ID: "UseValidation",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseValidation"
            },
            Column: 1,
            Row: 7
        });
        PROPERTIES.push({
            ID: "KeyFilter",
            Parent: "TextBox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Filter type",
            Column: 1,
            Row: 8,
            CheckVisibility: (item) => {
                return item.UseValidation;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: KeyFilter
            }
        })
        PROPERTIES.push({
            ID: "RegExp",
            Parent: "TextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@RegExp",
            Column: 1,
            Row: 9,
            CheckVisibility: (item) => {
                return item.KeyFilter == 9 && item.UseValidation;
            }
        });
        PROPERTIES.push({
            ID: "ValidateOnly",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ValidateOnly"
            },
            Column: 1,
            Row: 10,
            CheckVisibility: (item) => {
                return item.UseValidation && (item.KeyFilter === 0 || item.KeyFilter > 0);
            }
        });
        PROPERTIES.push({
            ID: "CheckWholeExpression",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@CheckWholeExpression"
            },
            Column: 1,
            Row: 11,
            CheckVisibility: (item) => {
                return item.UseValidation && item.KeyFilter == 9;
            }
        });
        PROPERTIES.push({
            ID: "Message",
            Parent: "TextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Message",
            Column: 1,
            Row: 12,
            CheckVisibility: (item) => {
                return item.UseValidation && (item.KeyFilter === 0 || item.KeyFilter > 0);
            }
        });
        PROPERTIES.push({
            ID: "Minimum",
            Parent: "TextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Minimum",
            Column: 1,
            Row: 13,
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Maximum",
            Parent: "TextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Maximum",
            Column: 1,
            Row: 14,
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "DecimalPositions",
            Parent: "TextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Nachkommastellen",
            Column: 1,
            Row: 15,
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "UseKeyDownDelay",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseKeyDownDelay"
            },
            Column: 1,
            Row: 16
        });
        PROPERTIES.push({
            ID: "KeyDownDelay",
            Parent: "TextBox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Delay in ms",
            Column: 1,
            Row: 17,
            CheckVisibility: (item) => {
                return item.UseKeyDownDelay;
            },
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "UseFloatingLabel",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseFloatingLabel"
            },
            Column: 1,
            Row: 18
        });
        PROPERTIES.push({
            ID: "ShowPassword",
            Parent: "TextBox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ShowPassword"
            },
            Column: 1,
            Row: 19,
            CheckVisibility: (item) => {
                return item.InputType == 'password';
            }
        });
    }
}