import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { UnitSelectionControl } from '../../../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutUnit } from "../../../models/basic/layoutunit.model";
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { SideNavMode } from '../../../models/enums/sidenavmode.enum';
import { SideNavPosition } from '../../../models/enums/sidenavposition.enum';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { SideNavService } from '../../../services/sidenav.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-sidenav-toggle',
    templateUrl: './sidenav.control.html',
    styleUrls: ['./sidenav.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavControl extends IBaseComponent {
    static Type: any = 'sidenav-toggle';
    static Default = JSON.parse(JSON.stringify(defaultProperties['sidenav-toggle']));
    ControlInitialized() {
        
    }
    toggle() {
        SideNavService.Toggle.next(null);
    }
}

export class SideNavPanel extends BasePanel {
    static override SIDS = ['b65c7495-2367-4d24-b987-59d4d08489cc']
    private static SideNavSub: BehaviorSubject<any> = new BehaviorSubject(null);
    static override SetSelectedLayout(layout) {
        if (layout) {
            if (!layout.SideNav) {
                layout.SideNav = {};
            }
            SideNavPanel.SideNavSub.next(layout.SideNav);
        }
    }
    static InitPanel() {
        this.InitSelectedLayout();
        PROPERTYGROUPS.push({
            SID:'b65c7495-2367-4d24-b987-59d4d08489cc',
            ID: 'sidenav',
            Caption: '@@SideNavToggle',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'sidenav-toggle';
            }
        });
        PROPERTIES.push({
            ID: "Mode",
            Parent: "sidenav",
            Content: new ComponentPortal(ComboboxThemeControl),
            DataSource: SideNavPanel.SideNavSub,
            Label: "@@Mode",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: SideNavMode
            }
        });
        PROPERTIES.push({
            ID: "Position",
            Parent: "sidenav",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Position",
            DataSource: SideNavPanel.SideNavSub,
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: SideNavPosition
            }
        });
        PROPERTIES.push({
            ID: "Width",
            Parent: "sidenav",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "@@Width",
            DataSource: SideNavPanel.SideNavSub,
            InitArgs: {
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "Height",
            Parent: "sidenav",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "@@Height",
            DataSource: SideNavPanel.SideNavSub,
            InitArgs: {
                Minimum: 0
            }
        });
    }
}