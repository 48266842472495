import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { ThemeHelper } from '../../../helpers/theme.helpers';
import { Comparer } from '../../../models/enums/comparer.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { SideNavService } from '../../../services/sidenav.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-themeswitch',
    templateUrl: './themeswitch.control.html',
    styleUrls: ['./themeswitch.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeSwitchControl extends IBaseComponent {
    static Type: any = 'themeswitch';
    static Default = JSON.parse(JSON.stringify(defaultProperties.themeswitch));
    Themes;
    get ActTheme() {
        if (ThemeHelper && ThemeHelper.AcitveTheme) {
            return ThemeHelper.AcitveTheme['SID'];
        } else {
            return null;
        }
    }
    @ViewChild('button', { read: ElementRef }) button: ElementRef<HTMLInputElement>;

    constructor(private service: MetaService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);

        this.Subscriptions['NaviStruct'] = SideNavService.SelectedNavigationStructure.subscribe(key => {
            this.Themes = null;
            if (key) {
                this.service.LoadNavigationStructurePropertyByKey(key, 'AvailableThemes').subscribe(themes => {
                    if (Array.isArray(themes) && themes.length > 0) {
                        const options = new RequestOptions();
                        const filter = new RequestFilter();
                        options.Filters = [filter];
                        filter.Name = '_Id';
                        filter.Operator = Comparer.In;
                        filter.Value = themes;
                        this.service.GetThemes(options).subscribe((result) => {
                            this.Themes = result;
                        });
                    }
                });
            }
        });
    }

    ControlInitialized() {
        
    }

    ChangeTheme(theme) {
        ThemeHelper.SetTheme(ThemeHelper.ThemesMap[theme]);
    }

    setFocus() {
        if (this.button && this.button.nativeElement) {
            this.button.nativeElement.focus();
        }
    }
}
