import { LayoutElement } from 'src/app/models/layoutelement.model';
import { ContentType } from 'src/app/models/enums/contenttype.enum';
import { AxisTextPosition } from 'src/app/models/enums/axistextposition.enum';
import { SliderOrientation } from 'src/app/models/enums/slider.orientation.enum';
import { PagingType } from 'src/app/models/enums/pagingtype.enum';

export const defaultProperties = {
  textbox: {
    Source: 'textbox',
    Placeholder: 'Please type ...',
    Type: 'textbox',
    Layout: {
      _Editable: true,
      Placeholder: 'Enter Text',
      Height: { Type: 0, Value: 52 },
      Width: { Type: 0, Value: 250 },
      BackgroundColor: {
        StartPoint: {
          X: 0,
          Y: 0.5,
        },
        EndPoint: {
          X: 1,
          Y: 0.5,
        },
        Colors: [
          {
            Color: {
              A: 255,
              R: 255,
              G: 255,
              B: 255,
            },
            Offset: 0,
          },
        ],
        Type: 1,
      },
      UseBorder: true,
    },
  },
  textblock: {
    Type: 'textblock',
    Caption: 'Text',
    Layout: {
      _Editable: true,
      Caption: 'Hello World!',
      Height: { Type: 0, Value: 30 },
      Width: { Type: 0, Value: 150 },
      Font: {
        FontColor: null,
        FontFamilyName: null,
        FontSize: 14,
        Bold: null,
        Italic: null,
        Underline: null,
      },
    },
  },
  accordion: {
    Caption: '@@Accordion',
    Type: 'accordion',
    Layout: {
      _Editable: true,
      Elements: [
        ...(() => {
          const elem1 = new LayoutElement();
          elem1.ContentType = ContentType.Grid;
          elem1.ElementType = 'grid';
          elem1.Name = 'Panel 1';
          const elem2 = new LayoutElement();
          elem2.ContentType = ContentType.Grid;
          elem2.ElementType = 'grid';
          elem2.Name = 'Panel 2';
          const elem3 = new LayoutElement();
          elem3.ContentType = ContentType.Grid;
          elem3.ElementType = 'grid';
          elem3.Name = 'Panel 2';
          return [elem1, elem2, elem3];
        })(),
      ],
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 300 },
    },
  },
  appswitch: { SelectedItem: null, Type: 'appswitch' },
  autocomplete: {
    Caption: 'Autocomplete',
    Type: 'autocomplete',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 300 },
      Height: { Type: 0, Value: 50 },
    },
  },
  button: {
    Caption: 'Button',
    Type: 'button',
    Layout: {
      ButtonType: 'mat-raised-button',
      Editable: true,
      Caption: 'Button',
      CaptionPosition: 'right',
      Height: { Type: 0, Value: 40 },
      Width: { Type: 0, Value: 150 },
      UseBorder: true,
      Border: {
        LeftBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        TopBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        RightBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        BottomBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        BorderRadius: {
          BottomLeft: { Type: 0, Value: 6 },
          BottomRight: { Type: 0, Value: 6 },
          TopLeft: { Type: 0, Value: 6 },
          TopRight: { Type: 0, Value: 6 },
        },
      },
    },
  },
  carousel: {
    IsCheckedValue: false,
    Type: 'carousel',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 400 },
    },
  },
  charts: {
    Caption: 'Charts',
    Type: 'charts',
    Layout: {
      vAxisTextPosition: AxisTextPosition.out,
      hAxisTextPosition: AxisTextPosition.out,
      _Editable: true,
      Height: { Type: 0, Value: 300 },
      Width: { Type: 0, Value: 400 },
    },
  },
  checkbox: {
    IsCheckedValue: false,
    Type: 'checkbox',
    Layout: {
      _Editable: true,
      Caption: 'Toggle',
      Height: { Type: 0, Value: 20 },
      Width: { Type: 0, Value: 100 },
    },
  },
  chiplist: {
    Placeholder: 'Please type ...',
    Type: 'chiplist',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 300 },
      Height: { Type: 0, Value: 150 },
    },
  },
  combobox: {
    SelectedItem: null,
    Type: 'combobox',
    Layout: {
      _Editable: true,
      Height: { Type: 0, Value: 30 },
      Width: { Type: 0, Value: 250 },
    },
  },
  content: { Type: 'content' },
  datacapsuletag: {
    Editable: true,
    Layout: {
      DisableAutoLoad: true,
      EmptyEntry: true,
      Width: { Type: 0, Value: 100 },
      Height: { Type: 0, Value: 30 },
    },
    Type: 'datacapsuletag',
  },
  datetimepicker: {
    Source: [],
    Type: 'datetimepicker',
    Layout: {
      _Editable: true,
      Height: { Type: 0, Value: 52 },
      Width: { Type: 0, Value: 250 },
      UseBorder: true,
      Border: {
        LeftBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        TopBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        RightBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        BottomBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        BorderRadius: null,
      },
    },
  },
  download: {
    Placeholder: 'Please type ...',
    Type: 'download',
    Layout: {
      Caption: 'Download',
      ButtonType: 'mat-button',
      _Editable: true,
      Width: { Type: 0, Value: 150 },
      Height: { Type: 0, Value: 40 },
    },
  },
  dropdown: {
    Editable: true,
    Type: 'dropdown',
    Layout: {
      _Editable: true,
      Height: { Type: 0, Value: 50 },
      Width: { Type: 0, Value: 250 },
    },
  },
  editor: {
    Type: 'editor',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 300 },
    },
  },
  embed: {
    Type: 'embed',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 300 },
      Height: { Type: 0, Value: 200 },
    },
  },
  filterdropdown: {
    Caption: 'FilterDropdown',
    Type: 'filterdropdown',
    Layout: {
      Height: { Type: 0, Value: 50 },
      Width: { Type: 0, Value: 200 },
    },
  },
  fromto: {
    Caption: 'Von-Bis-Filter',
    Type: 'fromto',
    Layout: {
      Height: { Type: 0, Value: 100 },
      Width: { Type: 0, Value: 250 },
    },
  },
  googlemap: {
    Type: 'googlemap',
    Layout: {
      Longitude: 69.3451,
      Latitude: 30.3753,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 400 },
    },
  },
  href: {
    Type: 'href',
    Caption: 'Text',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 150 },
      Height: { Type: 0, Value: 30 },
    },
  },
  hyperpay: {
    Type: 'hyperpay',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 200 },
    },
  },
  icon: {
    Type: 'icon',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 30 },
      Height: { Type: 0, Value: 30 },
    },
  },
  iframe: {
    Type: 'iframe',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 400 },
    },
  },
  image: {
    IsCheckedValue: false,
    Type: 'image',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 300 },
      Height: { Type: 0, Value: 200 },
    },
  },
  inputgroup: {
    Source: 'inputgroup',
    Placeholder: 'Please type ...',
    Type: 'inputgroup',
    Layout: {
      _Editable: true,
      Placeholder: 'Placeholder',
      Height: { Type: 0, Value: 52 },
      Width: { Type: 0, Value: 250 },
      UseBorder: true,
      Border: {
        LeftBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        TopBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        RightBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        BottomBorder: {
          Thickness: {
            Type: 0,
            Value: 2,
          },
          Style: 0,
          Color: {
            StartPoint: {
              X: 0,
              Y: 0,
            },
            EndPoint: {
              X: 0,
              Y: 0,
            },
            Colors: [
              {
                Color: {
                  A: 255,
                  R: 185,
                  G: 219,
                  B: 237,
                },
                Offset: 0,
              },
            ],
            Type: 1,
          },
        },
        BorderRadius: null,
      },
    },
  },
  languagemenu: {
    Editable: true,
    Type: 'languagemenu',
    Layout: {
      Width: { Type: 0, Value: 125 },
      Height: { Type: 0, Value: 30 },
    },
  },
  listbox: {
    SelectedItem: null,
    Type: 'listbox',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 350 },
    },
  },
  listdetail: { SelectedItem: null, Type: 'listdetail' },
  loading: {
    SelectedItem: null,
    Type: 'loading',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 40 },
      Height: { Type: 0, Value: 40 },
    },
  },
  marker: {
    Type: 'marker',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 50 },
      Height: { Type: 0, Value: 300 },
    },
  },
  messagecenter: {
    Type: 'messagecenter',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 50 },
      Height: { Type: 0, Value: 30 },
    },
  },
  objectviewer: {
    IsCheckedValue: false,
    Type: 'objectviewer',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 400 },
    },
  },
  pageindex: { Type: 'pageindex', Caption: 'PageIndex' },
  sumcomponent: { Type: 'sumcomponent', Caption: 'Summe' },
  pdfviewer: {
    Type: 'pdfviewer',
    Layout: {
      _Editable: true,
    },
  },
  profilemanagement: {
    Type: 'profilemanagement',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 200 },
    },
  },
  qr: {
    SelectedItem: null,
    Type: 'qr',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 150 },
      Height: { Type: 0, Value: 150 },
    },
  },
  radiobutton: {
    Type: 'radiobutton',
    Layout: {
      _Editable: true,
      CustomValues: [{ Caption: 'Radio 1', Value: '' }],
      Height: { Type: 0, Value: 20 },
      Width: { Type: 0, Value: 100 },
    },
  },
  scheduler: {
    Source: [],
    Type: 'scheduler',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 350 },
    },
  },
  'sidenav-toggle': { Editable: true, Type: 'sidenav-toggle' },
  reporting: {
    Source: 'simplereporting',
    Type: 'reporting',
    Layout: {
      _Editable: true,
      ChartType: 'bar',
      Height: { Type: 0, Value: 350 },
      Width: { Type: 0, Value: 350 },
    },
  },
  slider: {
    Type: 'slider',
    Layout: {
      DataSource: 0,
      Minimum: 0,
      Maximum: 150,
      Step: 1,
      Range: false,
      Orientation: SliderOrientation.Horizontal,
      thumbLabel: true,
      _Editable: true,
      Width: { Type: 0, Value: 200 },
      Height: { Type: 0, Value: 30 },
    },
  },
  stepper: {
    Caption: '@@Stepper',
    Type: 'stepper',
    Layout: {
      _Editable: true,
      Elements: [
        (() => {
          const elem = new LayoutElement();
          elem.ContentType = ContentType.Grid;
          elem.ElementType = 'grid';
          elem.Name = 'Step 1';
          return elem;
        })(),
      ],
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 200 },
    },
  },
  tab: {
    Caption: '@@Tab',
    Type: 'tab',
    Layout: {
      _Editable: true,
      Elements: [
        ...(() => {
          const elem1 = new LayoutElement();
          elem1.ContentType = ContentType.Grid;
          elem1.ElementType = 'grid';
          elem1.Name = `Tab ${1}`;
          elem1.Caption = `Tab ${1}`;
          const elem2 = new LayoutElement();
          elem2.ContentType = ContentType.Grid;
          elem2.ElementType = 'grid';
          elem2.Name = `Tab ${2}`;
          elem2.Caption = `Tab ${2}`;
          return [elem1, elem2];
        })(),
      ],
      Width: { Type: 0, Value: 800 },
      Height: { Type: 0, Value: 500 },
    },
  },
  datatable: {
    Type: 'datatable',
    Layout: {
      PagingType: PagingType.Scroll,
      _Editable: true,
      Height: { Type: 0, Value: 250 },
      Width: { Type: 0, Value: 300 },
      CustomBaseStyle: {
        HeaderBackground: {
          A: 15,
          R: 157,
          G: 190,
          B: 223,
        },
        HeaderForeground: {
          A: 255,
          R: null,
          G: null,
          B: null,
        },
        Line: {
          A: 255,
          R: 23,
          G: 50,
          B: 94,
        },
      },
    },
  },
  tablecolumnvisibility: {
    Type: 'tablecolumnvisibility',
    Layout: {
      Caption: 'Column Visibility',
      ButtonType: 'mat-button',
      _Editable: true,
      Width: { Type: 0, Value: 150 },
      Height: { Type: 0, Value: 30 },
    },
  },
  primetable: { Type: 'primetable' },
  textarea: {
    Source: 'textarea',
    Placeholder: 'Please type ...',
    Type: 'textarea',
    Layout: {
      _Editable: true,
      Placeholder: 'Enter your text here...',
      Height: { Type: 0, Value: 200 },
      Width: { Type: 0, Value: 300 },
      BackgroundColor: {
        StartPoint: {
          X: 0,
          Y: 0.5,
        },
        EndPoint: {
          X: 1,
          Y: 0.5,
        },
        Colors: [
          {
            Color: {
              A: 240,
              R: 240,
              G: 240,
              B: 255,
            },
            Offset: 0,
          },
        ],
        Type: 1,
      },
    },
  },
  themeswitch: {
    Type: 'themeswitch',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 30 },
      Height: { Type: 0, Value: 30 },
    },
  },
  timespan: {
    Source: 'timespan',
    Placeholder: 'Please type ...',
    Type: 'timespan',
    Layout: {
      _Editable: true,
      Height: { Type: 0, Value: 250 },
      Width: { Type: 0, Value: 250 },
    },
  },
  togglebutton: {
    Caption: 'ToggleButton',
    Type: 'togglebutton',
    Layout: {
      Editable: true,
      Caption: 'ToggleButton',
      Height: { Type: 0, Value: 20 },
      Width: { Type: 0, Value: 100 },
    },
  },
  translationtextbox: {
    Type: 'translationtextbox',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 50 },
    },
  },
  tree: {
    Editable: true,
    Type: 'tree',
    Layout: {
      _Editable: true,
    },
  },
  upload: {
    Placeholder: 'Please type ...',
    Type: 'upload',
    Layout: {
      Caption: 'Choose File',
      ButtonType: 'mat-button',
      _Editable: true,
      Width: { Type: 0, Value: 150 },
      Height: { Type: 0, Value: 40 },
    },
  },
  usermenu: {
    Editable: true,
    Type: 'usermenu',
    Variant: 'named',
    Layout: {
      Width: { Type: 0, Value: 35 },
      Height: { Type: 0, Value: 35 },
    },
  },
  video: {
    IsCheckedValue: false,
    Type: 'video',
    Layout: {
      _Editable: true,
      Width: { Type: 0, Value: 400 },
      Height: { Type: 0, Value: 275 },
    },
  },
};
