import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, Pipe, PipeTransform } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { KeyFilter } from '../../../models/enums/keyfilter.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-inputgroup',
    templateUrl: './inputgroup.control.html',
    styleUrls: ['./inputgroup.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputGroupControl extends IBaseComponent {
    static Type: any = 'inputgroup';
    static Default = JSON.parse(JSON.stringify(defaultProperties.inputgroup));

    Valid = true;
    KeyDownTimeOut;

    AppBuilderPanel = InputGroupPanel;
    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion
    //#region InputType
    @Input()
    get InputType(): string {
        return this.LayoutElement.InputType;
    }
    set InputType(val: string) {
        this.LayoutElement.InputType = val;
        this.InputTypeChange.emit(this.LayoutElement.InputType);
    }

    @Output() InputTypeChange = new EventEmitter<string>();
    //#endregion

    //#region Minimum
    @Input()
    get Minimum(): string {
        return this.LayoutElement.Minimum;
    }
    set Minimum(val: string) {
        this.LayoutElement.Minimum = val;
        this.MinimumChange.emit(this.LayoutElement.Minimum);
    }

    @Output() MinimumChange = new EventEmitter<string>();
    //#endregion

    //#region Maximum
    @Input()
    get Maximum(): string {
        return this.LayoutElement.Maximum;
    }
    set Maximum(val: string) {
        this.LayoutElement.Maximum = val;
        this.MaximumChange.emit(this.LayoutElement.Maximum);
    }

    @Output() MaximumChange = new EventEmitter<string>();
    //#endregion

    //#region Nachkommastellen
    @Input()
    get DecimalPositions(): string {
        return this.LayoutElement.DecimalPositions;
    }
    set DecimalPositions(val: string) {
        this.LayoutElement.DecimalPositions = val;
        if (this.DataSourceValue && this.DecimalPositions && Number(this.DecimalPositions) > 0) {
            this.DataSourceValue = Number(this.DataSourceValue).toFixed(Number(this.DecimalPositions));
        }
        this.DecimalPositionsChange.emit(this.LayoutElement.DecimalPositions);
    }

    @Output() DecimalPositionsChange = new EventEmitter<string>();
    //#endregion

    //#region Step
    @Input()
    get Step(): string {
        if (this.LayoutElement.Step !== undefined) {
            return this.LayoutElement.Step;
        }
        return 'any;';
    }
    set Step(val: string) {
        this.LayoutElement.Step = val;
        this.StepChange.emit(this.LayoutElement.Step);
    }

    @Output() StepChange = new EventEmitter<string>();
    //#endregion

    //#region ReadOnly
    ReadOnlyValue;
    get ReadOnly(): boolean {
        if (typeof this.ReadOnlyValue === 'boolean') {
            return this.ReadOnlyValue;
        }
        return this.LayoutElement.ReadOnly;
    }
    set ReadOnly(val) {
        this.ReadOnlyValue = val;
    }
    //#endregion

    @Output() TextChange = new EventEmitter<string>();
    invalidChars = ['e'];
    ButtonsBefore = [];
    ButtonsAfter = [];

    constructor(private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('textchanged');
        this.EventList.push('keypress');
        this.EventList.push('keydown');
        this.PropertyList.push(new ElementProperty('ReadOnly', 'boolean', '@@ReadOnly'));
        this.PropertyList.push(new ElementProperty('DataSourceValue', 'string', '@@Text'));
    }

    ControlInitialized() {
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            if (this.LayoutElementValue && this.LayoutElementValue.Elements) {
                const index = this.LayoutElementValue.Elements.indexOf(item);
                if (index > -1) {
                    this.LayoutElementValue.Elements.splice(index, 1);
                    LayoutService.SelectedItem.next(null);
                    LayoutService.OnLayoutElementRemoved({
                        ElementID: item.ID,
                        ElementName: item.Name ? item.Name : item.ID,
                        ParentID: this.LayoutElementValue.ID
                    });
                    this.checkButtons();
                    this.cdRef.detectChanges();
                }
            }
        });
    }

    onLayoutElementSet() {
        this.checkButtons();
    }

    onLayoutElementChanged() {
        this.checkButtons();
    }

    checkButtons() {
        const buttonsBefore = [];
        const buttonsAfter = [];
        if (this.LayoutElementValue && this.LayoutElementValue.Elements && this.LayoutElementValue.AddButtons) {
            this.LayoutElementValue.Elements.forEach(x => {
                if (x.IsBefore == true) {
                    buttonsBefore.push(x);
                } else {
                    buttonsAfter.push(x);
                }
            });
        }
        this.ButtonsBefore = buttonsBefore;
        this.ButtonsAfter = buttonsAfter;
    }

    setFocus() {
        const textBox = document.getElementById(this.LayoutElement.ID);
        if (textBox) {
            textBox.focus();
        }
    }

    onChange(ev) {
        if (this.LayoutElement.InputType === 'number') {

            let setMaxValue = false;
            let setMinValue = false;

            let valueNumberTemp = Number(this.DataSourceValue);
            if (this.DataSourceValue < this.Minimum) {
                valueNumberTemp = Number(this.Minimum);
                setMinValue = true;

            } else if (this.DataSourceValue > this.Maximum) {
                valueNumberTemp = Number(this.Maximum);
                setMaxValue = true;
            }

            if (this.DecimalPositions && Number(this.DecimalPositions) > 0) {

                const valueString = valueNumberTemp.toString();
                valueNumberTemp = Number(valueNumberTemp.toFixed(Number(this.DecimalPositions)));

                if (!setMaxValue && !setMinValue && valueString !== valueNumberTemp.toString()) {
                    NotificationHelper.Info('@@Der Wert wurde auf die maximale Nachkommastellenanzahl von {0} gesetzt.', '@@maximal {0} Nachkommstellen zulaessig', { 0: this.DecimalPositions }, { 0: this.DecimalPositions });
                }
            }

            if (setMaxValue) {
                NotificationHelper.Info('@@Der Wert wurde auf das Maximum {0} gesetzt. Die maximale Nachkommastellenanzahl von {1} wurde beachtet.', '@@Maximum ueberschritten', { 0: valueNumberTemp, 1: this.DecimalPositions });
            }
            if (setMinValue) {
                NotificationHelper.Info('@@Der Wert wurde auf das Minimum {0} gesetzt. Die maximale Nachkommastellenanzahl von {1} wurde beachtet.', '@@Minimum unterschritten', { 0: valueNumberTemp, 1: this.DecimalPositions });
            }

            if (valueNumberTemp) {
                this.DataSource = valueNumberTemp;
            }
        }
        if (typeof this.DataSourceValue === 'string') {
            this.triggerEvent('textchanged', this.DataSourceValue);
            this.TextChange.emit(this.DataSourceValue);
        }
    }

    KeyDown(event) {
        this.cdRef.detach();
        if (this.LayoutElementValue.InputType === 'number' && this.invalidChars.includes(event.key)) {
            event.preventDefault();
        } else {
            if (this.LayoutElementValue.UseKeyDownDelay &&
                typeof this.LayoutElementValue.KeyDownDelay === 'number' && this.LayoutElementValue.KeyDownDelay > 0) {
                clearTimeout(this.KeyDownTimeOut);
                this.KeyDownTimeOut = setTimeout(() => {
                    this.triggerEvent('keydown', event);
                }, this.LayoutElementValue.KeyDownDelay);
            } else {
                this.triggerEvent('keydown', event);
            }
        }
    }

    KeyPress(event) {
        this.triggerEvent('keypress', event);
    }
}
export class InputGroupPanel extends BasePanel {
    static override SIDS = ['475b52d1-ff73-44c3-ba09-b51900eab178']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'475b52d1-ff73-44c3-ba09-b51900eab178',
            ID: 'InputGroup',
            Caption: '@@InputGroup',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'inputgroup';
            }
        });
        //#region InputTypes
        let inputTypes = [{
            Caption: '@@Color',
            Value: 'color'
        }, {
            Caption: '@@Date',
            Value: 'date'
        }, {
            Caption: '@@DateTime',
            Value: 'datetime-local'
        }, {
            Caption: '@@EMail',
            Value: 'email'
        }, {
            Caption: '@@Month',
            Value: 'month'
        }, {
            Caption: '@@Number',
            Value: 'number'
        }, {
            Caption: '@@Password',
            Value: 'password'
        }, {
            Caption: '@@Search',
            Value: 'search'
        }, {
            Caption: '@@Phone',
            Value: 'tel'
        }, {
            Caption: '@@Text',
            Value: 'text'
        }, {
            Caption: '@@Time',
            Value: 'time'
        }, {
            Caption: '@@URL',
            Value: 'url'
        }, {
            Caption: '@@Week',
            Value: 'week'
        }]

        //#endregion InputTypes
        let inputModes = [{
            Caption: '@@None',
            Value: 'none'
        }, {
            Caption: '@@Text',
            Value: 'text'
        }, {
            Caption: '@@Decimal',
            Value: 'decimal'
        }, {
            Caption: '@@Numeric',
            Value: 'numeric'
        }, {
            Caption: '@@Telephone',
            Value: 'tel'
        }, {
            Caption: '@@Search',
            Value: 'search'
        }, {
            Caption: '@@Email',
            Value: 'email'
        }, {
            Caption: '@@URL',
            Value: 'url'
        }]
        //#endregion
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "InputGroup",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            Column: 1,
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "Tooltip",
            Parent: "InputGroup",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Tooltip",
            Column: 1,
            Row: 2,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "InputType",
            Parent: "InputGroup",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Input type",
            Column: 1,
            Row: 3,
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: inputTypes,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "InputMode",
            Parent: "InputGroup",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Input mode",
            Column: 1,
            Row: 4,
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: inputModes,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "ReadOnly",
            Parent: "InputGroup",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ReadOnly"
            },
            Column: 1,
            Row: 5
        });
        PROPERTIES.push({
            ID: "UseValidation",
            Parent: "InputGroup",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseValidation"
            },
            Column: 1,
            Row: 6
        });
        PROPERTIES.push({
            ID: "KeyFilter",
            Parent: "InputGroup",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Filter type",
            Column: 1,
            Row: 7,
            CheckVisibility: (item) => {
                return item.UseValidation;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: KeyFilter
            }
        })
        PROPERTIES.push({
            ID: "RegExp",
            Parent: "InputGroup",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@RegExp",
            Column: 1,
            Row: 8,
            CheckVisibility: (item) => {
                return item.KeyFilter == 9 && item.UseValidation;
            }
        });
        PROPERTIES.push({
            ID: "ValidateOnly",
            Parent: "InputGroup",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ValidateOnly"
            },
            Column: 1,
            Row: 9,
            CheckVisibility: (item) => {
                return item.UseValidation && (item.KeyFilter === 0 || item.KeyFilter > 0);
            }
        });
        PROPERTIES.push({
            ID: "CheckWholeExpression",
            Parent: "InputGroup",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@CheckWholeExpression"
            },
            Column: 1,
            Row: 10,
            CheckVisibility: (item) => {
                return item.UseValidation && item.KeyFilter == 9;
            }
        });
        PROPERTIES.push({
            ID: "Minimum",
            Parent: "InputGroup",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Minimum",
            Column: 1,
            Row: 11,
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Maximum",
            Parent: "InputGroup",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Maximum",
            Column: 1,
            Row: 12,
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "DecimalPositions",
            Parent: "InputGroup",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Nachkommastellen",
            Column: 1,
            Row: 13,
            CheckVisibility: (item) => {
                return item.InputType == 'number';
            },
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "UseKeyDownDelay",
            Parent: "InputGroup",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseKeyDownDelay"
            },
            Column: 1,
            Row: 14
        });
        PROPERTIES.push({
            ID: "KeyDownDelay",
            Parent: "InputGroup",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Delay in ms",
            Column: 1,
            Row: 15,
            CheckVisibility: (item) => {
                return item.UseKeyDownDelay;
            },
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "UseFloatingLabel",
            Parent: "InputGroup",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseFloatingLabel"
            },
            Column: 1,
            Row: 16
        });
        PROPERTIES.push({
            ID: "Elements",
            Parent: "InputGroup",
            Content: new ComponentPortal(ListBoxThemeControl),
            Label: "@@Buttons",
            Column: 1,
            Row: 17,
            Properties: [
                {
                    ID: "Name",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Caption",
                    LabelPosition: MenuTabLabelPosition.Top
                },
                {
                    ID: "IsBefore",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(CheckBoxThemeControl),
                    InitArgs: {
                        Caption: "@@IsBefore"
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    const item = new LayoutElement();
                    item.ElementType = 'button';
                    item.Editable = true;
                    item['IsBefore'] = true;
                    item.Width = new LayoutUnit();
                    return item;
                }
            },
            LabelPosition: MenuTabLabelPosition.Top
        });
    }
}