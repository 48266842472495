import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { DropDownControl } from '../../common/dropdowncontrol/dropdown.control';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent extends IBaseComponent {

    static Type: any = 'dropdown';
    static Default = JSON.parse(JSON.stringify(defaultProperties.dropdown));

    dragItem;
    dragType;
    ButtonContentElement;
    DropDownContentElement;
    ShowDropDownContent = true;
    @ViewChild('dropDown') dropDown: DropDownControl;

    constructor(private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('OnOpened');
        this.EventList.push('OnClosed');
    }
    ControlInitialized() {
        this.Subscriptions['ViewType'] = LayoutService.ViewType.subscribe((viewType) => {
            this.ShowDropDownContent = viewType === ViewType.Edit;
        });
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            this.dragItem = item.item;
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
    }

    onOpened() {
        this.triggerEvent('OnOpened');
        this.cdRef.detectChanges();
    }

    onClosed() {
        this.triggerEvent('OnClosed');
    }

    removeItem(item) {
        if (this.LayoutElementValue && this.LayoutElementValue.Elements) {
            const index = this.LayoutElementValue.Elements.indexOf(item);
            if (index > -1) {
                this.LayoutElementValue.Elements.splice(index, 1);
                LayoutService.SelectedItem.next(null);
                LayoutService.OnLayoutElementRemoved({
                    ElementID: item.ID,
                    ElementName: item.Name ? item.Name : item.ID,
                    ParentID: this.LayoutElementValue.ID
                });
            }
        }
    }

    itemDrop(event, buttonContent) {
        if (this.LayoutElementValue) {
            let found = false;
            if (this.LayoutElementValue.Elements) {
                if (buttonContent) {
                    found = this.LayoutElementValue.Elements.some(x => x.IsButtonContent);
                } else {
                    found = this.LayoutElementValue.Elements.some(x => !x.IsButtonContent);
                }
            } else {
                this.LayoutElementValue.Elements = [];
            }
            if (!found) {
                const insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
                insert['IsButtonContent'] = buttonContent;
                this.LayoutElementValue.Elements.push(insert);
                if (buttonContent) {
                    this.ButtonContentElement = insert;
                } else {
                    this.DropDownContentElement = insert;
                }
                LayoutService.OnLayoutElementAdded({
                    ElementContent: JSON.stringify(insert),
                    Index: this.LayoutElementValue.Elements.length - 1,
                    ParentID: this.LayoutElementValue.ID
                });
            }
        }
        event.currentTarget.style.backgroundColor = 'transparent';
    }

    itemSelected(item) {
        if (!item.Selected) {
            LayoutService.SelectedItem.next(item);
        }
    }

    onLayoutElementSet() {
        this.ButtonContentElement = null;
        this.DropDownContentElement = null;
        if (this.LayoutElementValue && this.LayoutElementValue.Elements) {
            this.LayoutElementValue.Elements.some(x => {
                if (x.IsButtonContent) {
                    if (!this.ButtonContentElement) {
                        this.ButtonContentElement = x;
                        this.ButtonContentElement.Parent = this.LayoutElementValue;
                    }
                } else {
                    if (!this.DropDownContentElement) {
                        this.DropDownContentElement = x;
                        this.DropDownContentElement.Parent = this.LayoutElementValue;
                    }
                }
                return this.ButtonContentElement && this.DropDownContentElement;
            });
            this.cdRef.detectChanges();
        }
    }

    dropdownClicked() {
        this.ShowDropDownContent = !this.ShowDropDownContent;
    }

    setFocus() {
        if (this.dropDown) {
            this.dropDown.setFocus();
        }
    }

    close() {
        if (this.dropDown) {
            this.dropDown.close();
        }
    }
}
