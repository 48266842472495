import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CapsuleHelper } from '../../../helpers/capsule.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-data-capsule-tag',
    templateUrl: 'data.capsule.tag.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCapsuleTagControl extends IBaseComponent {
    static Type: any = 'datacapsuletag';
    static Default = JSON.parse(JSON.stringify(defaultProperties.datacapsuletag));

    TagList;

    get CapsuleTag() {
        if (this.DataSourceValue) {
            return this.DataSourceValue._CapsuleTag;
        }
        return null;
    }
    set CapsuleTag(val) {
        if (this.DataSourceValue) {
            this.DataSourceValue._CapsuleTag = val;
        }
    }

    ControlInitialized() {
        CapsuleHelper.GetCapsuleTags().then(r => {
            if (r) {
                MetaHelper.FindTableProperties(this.LayoutValue, this.LayoutElementValue).then(result => {
                    if (result && result.DataCapsuleTags) {
                        this.TagList = result.DataCapsuleTags;
                        this.cdRef.detectChanges();
                    }
                });
            }
        });
    }
}
