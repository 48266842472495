import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
    EventEmitter, HostListener, Inject, Input, Output, ViewChild, ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResizedEvent } from 'angular-resize-event';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { Table, TableService } from 'primeng/table';
import { Subject, Subscription } from 'rxjs';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { MetaHelper } from '../../../../helpers/meta.helper';
import { RTLHelper } from '../../../../helpers/rtl.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { CustomBaseStyle } from '../../../../models/basic/custombasestyle.model';
import { LayoutUnit } from '../../../../models/basic/layoutunit.model';
import { Row } from '../../../../models/basic/row.model';
import { Comparer } from '../../../../models/enums/comparer.enum';
import { Concat } from '../../../../models/enums/contact.enum';
import { Order } from '../../../../models/enums/order.enum';
import { PagingType } from '../../../../models/enums/pagingtype.enum';
import { ResponsiveType } from '../../../../models/enums/responsivetype.enum';
import { SelectMode } from '../../../../models/enums/selectmode.enum';
import { TableStyle } from '../../../../models/enums/tablestyle.enum';
import { UnitType } from '../../../../models/enums/unittype.enum';
import { ElementProperty } from '../../../../models/layoutbase.model';
import { RequestColumn } from '../../../../models/rest/requestcolumn';
import { RequestFilter } from '../../../../models/rest/requestfilter';
import { RequestOptions } from '../../../../models/rest/requestoptions';
import { RequestSort } from '../../../../models/rest/requestsort';
import { Color } from '../../../../models/style/color.model';
import { DynamicDataService } from '../../../../services/dynamicdata.service';
import { LayoutService } from '../../../../services/layout.service';
import { IBaseComponent } from '../../base.component';
import { DataTableDataSource, DataTableRowDataSource } from './datatable.datasource';
import { DataTableCommunicationService } from "../../../../services/datatable-communication.service";
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';
@Component({
    selector: 'evi-datatable',
    templateUrl: './datatable.control.html',
    styleUrls: ['./datatable.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableControl extends IBaseComponent {
    static Type: any = 'datatable';
    static Default = JSON.parse(JSON.stringify(defaultProperties.datatable));

    //#region Properties
    initialized = false;
    JSON = JSON;
    ViewInitialized = false;
    //#region Data
    DataSourceElement;
    DataSourceID;
    FlatTableSource: any;
    AdditionalFilter: RequestFilter;
    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        this.DataSourceChange.emit(this.DataSourceValue);
        if (val) {
            this.doInit(true);
        }
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion


    //#region FilterValue
    FilterValue: RequestOptions = new RequestOptions();

    @Input()
    get Filter() {
        return this.FilterValue;
    }
    set Filter(val) {
        this.FilterValue = val;
        this.FilterChange.emit(this.FilterValue);
        this.cdRef.detectChanges();
    }

    @Output() FilterChange = new EventEmitter<any>();
    //#endregion

    //#region ExternalDataSource
    ExternalDataSourceValue;

    @Input()
    get ExternalDataSource() {
        return this.ExternalDataSourceValue;
    }
    set ExternalDataSource(val) {
        this.ExternalDataSourceValue = val;
        this.ExternalDataSourceChange.emit(this.ExternalDataSourceValue);
        if (val) {
            this.doInit(false);
        }
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.datableComService.getSortColumn().subscribe((sort) => {
            if (sort !== null) {
                this.SortColumn = sort;
            }
            else this.SortColumn = null;
        })

        this.datableComService.getSortOrder().subscribe((order) => {
            if (order !== null) {
                this.SortOrder = order;
            }
            else this.SortOrder = null;
        })
    }

    @Output() ExternalDataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region Source
    @Input()
    get Source() {
        if (this.FlatTableSource) {
            switch (this.PagingType) {
                case PagingType.Pager:
                    return this.FlatTableSource.PagedData;
                case PagingType.Scroll:
                case PagingType.Endless:
                    return this.FlatTableSource.cachedData;
            }
        }
    }
    @Output() SourceChange = new EventEmitter<any>();
    //#endregion
    //#region DataSourceRows
    get DataSourceRows() {
        if (this.FlatTableSource) {
            return this.FlatTableSource.cachedData;
        } else if (this.Rows) {
            return this.Rows
        } else {
            return null;
        }
    }
    set DataSourceRows(val) {
        if (this.FlatTableSource) {
            this.FlatTableSource.cachedData = val;
        } else if (this.Rows) {
            this.Rows = val;
        }
        this.cdRef.detectChanges();
    }
    //#endregion
    //#region Rows
    RowsValue;
    @Input()
    get Rows() {
        return this.RowsValue;
    }
    set Rows(val) {
        if (Array.isArray(val)) {
            if (val.length > 0) {
                const pd = [];
                let count = 0;
                val.forEach((row) => {
                    const toPush = new Row();
                    toPush.index = count++;
                    toPush.data = row;
                    pd.push(toPush);
                });
                this.RowsValue = pd;
            } else {
                this.RowsValue = val;
            }
        } else {
            this.RowsValue = null;
        }
        this.RowsChange.emit(this.RowsValue);
        this.loading = false;
        this.cdRef.detectChanges();
    }
    @Output() RowsChange = new EventEmitter<any>();
    //#endregion
    get RowCount() {
        if (this.Rows) {
            return this.Rows.length;
        }
        if (this.FlatTableSource) {
            switch (this.PagingType) {
                case PagingType.Pager:
                    return this.FlatTableSource.cachedData.length;
                case PagingType.Scroll:
                case PagingType.Endless:
                    return this.FlatTableSource.cachedData.length;
            }
        }
    }

    get TotalRecords() {
        if (this.FlatTableSource) {
            return this.FlatTableSource.FilteredCount;
        } else {
            return 0;
        }
    }
    //#endregion


    //#region MobileBreakpoint
    //MobileBreakpointValue;
    //@Input()
    //get MobileBreakpoint() {
    //    if (this.LayoutElement) {
    //        return this.LayoutElement.MobileBreakpoint;
    //    } else {
    //        return this.MobileBreakpointValue;
    //    }
    //}
    //set MobileBreakpoint(val) {
    //    if (this.LayoutElement) {
    //        this.LayoutElement.MobileBreakpoint = val;
    //        this.MobileBreakpointChange.emit(this.LayoutElement.MobileBreakpoint);
    //    } else {
    //        this.MobileBreakpointValue = val;
    //        this.MobileBreakpointChange.emit(this.MobileBreakpointValue);
    //    }
    //}
    //@Output() MobileBreakpointChange = new EventEmitter<any>();
    //#endregion
    //#region TableStyle
    //TableStyleValue: TableStyle;

    //@Input()
    //get TableStyle() {
    //    if (this.LayoutElement) {
    //        return this.LayoutElement.TableStyle;
    //    } else {
    //        return this.TableStyleValue;
    //    }
    //}
    //set TableStyle(val) {
    //    if (this.LayoutElement) {
    //        this.LayoutElement.TableStyle = val;
    //        this.TableStyleChange.emit(this.LayoutElement.TableStyle);
    //    } else {
    //        this.TableStyleValue = val;
    //        this.TableStyleChange.emit(this.TableStyleValue);
    //    }
    //}

    //@Output() TableStyleChange = new EventEmitter<any>();
    //#endregion

    DisableAutoLoad = false;

    //#region CustomBaseStyle
    get CustomBaseStyleBinding(): CustomBaseStyle {
        if (this.LayoutElementValue) {
            let value = new CustomBaseStyle();
            if (this.LayoutElementValue.CustomBaseStyle) {
                if (this.LayoutElementValue.CustomBaseStyle.HeaderBackground) {
                    value.HeaderBackground = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.HeaderBackground);
                }
                if (this.LayoutElementValue.CustomBaseStyle.HeaderForeground) {
                    value.HeaderForeground = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.HeaderForeground);
                }
                if (this.LayoutElementValue.CustomBaseStyle.Line) {
                    value.Line = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.Line);
                }
                if (this.LayoutElementValue.CustomBaseStyle.StickyBackground) {
                    value.StickyBackground = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.StickyBackground);
                }
            }
            return value;
        }
        return null;
    }
    CustomBaseStyleValue;

    @Input()
    get CustomBaseStyle() {
        if (this.LayoutElement) {
            return this.LayoutElement.CustomBaseStyle;
        } else {
            return this.CustomBaseStyleValue;
        }
    }
    set CustomBaseStyle(val) {
        if (this.LayoutElement) {
            this.LayoutElement.CustomBaseStyle = val;
            this.CustomBaseStyleChange.emit(this.LayoutElement.CustomBaseStyle);
        } else {
            this.CustomBaseStyleValue = val;
            this.CustomBaseStyleChange.emit(this.CustomBaseStyleValue);
        }
    }

    @Output() CustomBaseStyleChange = new EventEmitter<any>();
    CellStyles;
    //#endregion
    //#region PagingType
    PagingTypeValue: PagingType = PagingType.Scroll;

    @Input()
    get PagingType() {
        if (this.LayoutElement) {
            return this.LayoutElement.PagingType;
        } else {
            return this.PagingTypeValue;
        }
    }
    set PagingType(val) {
        if (this.LayoutElement) {
            this.LayoutElement.PagingType = val;
            this.PagingTypeChange.emit(this.LayoutElement.PagingType);
        } else {
            this.PagingTypeValue = val;
            this.PagingTypeChange.emit(this.PagingTypeValue);
        }
    }

    @Output() PagingTypeChange = new EventEmitter<any>();
    //#endregion
    //#region PageSize
    PageSizeValue: number = 300;

    @Input()
    get PageSize() {
        return this.PageSizeValue;
    }
    set PageSize(val) {
        this.PageSizeValue = val;
        if (this.FlatTableSource) {
            this.FlatTableSource.pageSize = val;
        }
        this.PageSizeChange.emit(this.PageSizeValue);
    }

    @Output() PageSizeChange = new EventEmitter<any>();
    //#endregion
    //#region ResponsiveType
    ResponsiveTypeValue: ResponsiveType;

    @Input()
    get ResponsiveType() {
        if (this.LayoutElement) {
            return this.LayoutElement.ResponsiveType;
        } else {
            return this.ResponsiveTypeValue;
        }
    }
    set ResponsiveType(val) {
        if (this.LayoutElement) {
            this.LayoutElement.ResponsiveType = val;
            this.ResponsiveTypeChange.emit(this.LayoutElement.ResponsiveType);
        } else {
            this.ResponsiveTypeValue = val;
            this.ResponsiveTypeChange.emit(this.ResponsiveTypeValue);
        }
    }

    @Output() ResponsiveTypeChange = new EventEmitter<any>();
    //#endregion
    //#region SelectMode
    SelectModeValue: SelectMode;
    Select = 'single';
    @Input()
    get SelectMode() {
        if (this.LayoutElement) {
            return this.LayoutElement.SelectMode;
        } else {
            return this.SelectModeValue;
        }
    }
    set SelectMode(val) {
        if (this.LayoutElement) {
            this.LayoutElement.SelectMode = val;

            this.SelectModeChange.emit(this.LayoutElement.SelectMode);
        } else {
            this.SelectModeValue = val;
            this.SelectModeChange.emit(this.SelectModeValue);
        }
        this.SetSelect();
    }
    SetSelect() {
        this.Selection = null;
        switch (this.SelectMode) {
            case SelectMode.single:
            case SelectMode['fire&forget']:
                this.Select = 'single';
                break;
            case SelectMode.multi:
            case SelectMode.os:
                this.Select = 'multiple';
                break;
        }
        this.cdRef.detectChanges();
    }
    @Output() SelectModeChange = new EventEmitter<any>();
    //#endregion
    //#region RowsExpandable
    RowsExpandableValue: boolean = false;

    @Input()
    get RowsExpandable() {
        if (this.LayoutElement) {
            return this.LayoutElement.RowsExpandable;
        } else {
            return this.RowsExpandableValue;
        }
    }
    set RowsExpandable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.RowsExpandable = val;
            this.RowsExpandableChange.emit(this.LayoutElement.RowsExpandable);
        } else {
            this.RowsExpandableValue = val;
            this.RowsExpandableChange.emit(this.RowsExpandableValue);
        }
    }

    @Output() RowsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region ColumnsExpandable
    ColumnsExpandableValue: boolean = false;

    @Input()
    get ColumnsExpandable() {
        if (this.LayoutElement) {
            return this.LayoutElement.ColumnsExpandable;
        } else {
            return this.ColumnsExpandableValue;
        }
    }
    set ColumnsExpandable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.ColumnsExpandable = val;
            this.ColumnsExpandableChange.emit(this.LayoutElement.ColumnsExpandable);
        } else {
            this.ColumnsExpandableValue = val;
            this.ColumnsExpandableChange.emit(this.ColumnsExpandableValue);
        }
    }

    @Output() ColumnsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region ShowSelectAll
    ShowSelectAllValue: boolean = false;

    @Input()
    get ShowSelectAll() {
        if (this.LayoutElement) {
            return this.LayoutElement.SelectAll;
        } else {
            return this.ShowSelectAllValue;
        }
    }
    set ShowSelectAll(val) {
        if (this.LayoutElement) {
            this.LayoutElement.SelectAll = val;
            this.ShowSelectAllChange.emit(this.LayoutElement.SelectAll);
        } else {
            this.ShowSelectAllValue = val;
            this.ShowSelectAllChange.emit(this.ShowSelectAllValue);
        }
    }

    @Output() ShowSelectAllChange = new EventEmitter<any>();
    //#endregion
    //#region Resizable
    ResizableValue: boolean = false;

    @Input()
    get Resizable() {
        if (this.LayoutElement) {
            return this.LayoutElement.Resizable;
        } else {
            return this.ResizableValue;
        }
    }
    set Resizable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.Resizable = val;
            this.ResizableChange.emit(this.LayoutElement.Resizable);
        } else {
            this.ResizableValue = val;
            this.ResizableChange.emit(this.ResizableValue);
        }
    }

    @Output() ResizableChange = new EventEmitter<any>();
    //#endregion
    //#region ResizeMode
    ResizeModeValue: string = 'fit';

    @Input()
    get ResizeMode() {
        if (this.LayoutElement) {
            if (!this.LayoutElement.ResizeMode) {
                return 'fit';
            }
            return this.LayoutElement.ResizeMode;
        } else {
            if (!this.ResizeModeValue) {
                return 'fit';
            }
            return this.ResizeModeValue;
        }
    }
    set ResizeMode(val) {
        if (this.LayoutElement) {
            this.LayoutElement.ResizeMode = val;
            this.ResizeModeChange.emit(this.LayoutElement.ResizeMode);
        } else {
            this.ResizeModeValue = val;
            this.ResizeModeChange.emit(this.ResizeModeValue);
        }
    }

    @Output() ResizeModeChange = new EventEmitter<any>();
    //#endregion
    //#region EditMode
    EditModeValue: string = 'row';

    @Input()
    get EditMode() {
        if (this.LayoutElement) {
            if (!this.LayoutElement.EditMode) {
                return 'row';
            }
            return this.LayoutElement.EditMode;
        } else {
            if (!this.EditModeValue) {
                return 'row';
            }
            return this.EditModeValue;
        }
    }
    set EditMode(val) {
        if (this.LayoutElement) {
            this.LayoutElement.EditMode = val;
            this.EditModeChange.emit(this.LayoutElement.EditMode);
        } else {
            this.EditModeValue = val;
            this.EditModeChange.emit(this.EditModeValue);
        }
    }

    @Output() EditModeChange = new EventEmitter<any>();
    //#endregion
    //#region LineHeight
    LineHeightValue: number;

    @Input()
    get LineHeight() {
        if (this.LayoutElement) {
            if (typeof this.LayoutElement.LineHeight === 'number' && this.LayoutElement.LineHeight >= 0) {
                return this.LayoutElement.LineHeight;
            } else {
                return 40;
            }
        } else {
            if (typeof this.LineHeightValue === 'number' && this.LineHeightValue >= 0) {
                return this.LineHeightValue;
            } else {
                return 40;
            }
        }
    }
    set LineHeight(val) {
        if (this.LayoutElement) {
            this.LayoutElement.LineHeight = val;
            this.LineHeightChange.emit(this.LayoutElement.LineHeight);
        } else {
            this.LineHeightValue = val;
            this.LineHeightChange.emit(this.LineHeightValue);
        }
    }

    @Output() LineHeightChange = new EventEmitter<any>();
    //#endregion
    //#region Columns
    ColumnsValue;
    UserSpecificColumns;
    ResponsiveColumns;
    RequestColumns;
    InternalColumns;
    DefaultSort;
    EnumSources = {};

    loading = true;
    @Input()
    get Columns() {
        if (this.UserSpecificColumns) {
            return this.UserSpecificColumns;
        } else {
            return this.ColumnsValue;
        }
    }
    set Columns(val) {
        this.CreateColumns(val);
        this.ColumnsChange.emit(this.ColumnsValue);
        this.ColumnsChanged.next(null);
    }
    @Output() ColumnsChange = new EventEmitter<any>();
    ColumnsChanged: Subject<any> = new Subject();
    //#region FixedColumnsLeft
    FixedColumnsLeftValue;
    @Input()
    get FixedColumnsLeft() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.FixedColumnsLeft;
        } else {
            return this.FixedColumnsLeftValue;
        }
    }
    set FixedColumnsLeft(val) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.FixedColumnsLeft = val;
            this.FixedColumnsRightChange.emit(this.LayoutElementValue.FixedColumnsLeft);
        } else {
            this.FixedColumnsLeftValue = val;
            this.FixedColumnsLeftChange.emit(this.FixedColumnsLeftValue);
        }
        this.CreateColumns(this.Columns);
    }
    @Output() FixedColumnsLeftChange = new EventEmitter<any>();
    //#endregion
    //#region FixedColumnsRight
    FixedColumnsRightValue;
    @Input()
    get FixedColumnsRight() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.FixedColumnsRight;
        } else {
            return this.FixedColumnsRightValue;
        }
    }
    set FixedColumnsRight(val) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.FixedColumnsRight = val;
            this.FixedColumnsRightChange.emit(this.LayoutElementValue.FixedColumnsRight);
        } else {
            this.FixedColumnsRightValue = val;
            this.FixedColumnsRightChange.emit(this.FixedColumnsRightValue);
        }
        this.CreateColumns(this.Columns);
    }
    @Output() FixedColumnsRightChange = new EventEmitter<any>();
    //#endregion
    //#endregion
    //#endregion
    FilterOptions = [];
    IsRtl = false;
    //#region LifeCycle
    constructor(private translate: TranslateService, public service: DynamicDataService, private datableComService: DataTableCommunicationService, private cdref: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdref, data);
        this.FilterOptions = EnumHelper.GetDropdownValues(Comparer);
        this.EventList.push('SelectionChanged');
        this.EventList.push('DoubleClick');
        this.EventList.push('ValueChanged');
        this.EventList.push('OnFocus');
        this.PropertyList.push(new ElementProperty('Filter', 'list', '@@Filter'));
        this.PropertyList.push(new ElementProperty('Columns', 'list', '@@Spalten'));
        this.PropertyList.push(new ElementProperty('DataSourceRows', 'list', '@@Zeilen'));
        this.PropertyList.push(new ElementProperty('InternalColumns', 'list', '@@InterneSpalten'));
        this.IsRtl = RTLHelper.Direction == 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir == 'rtl';
        });
    }
    PaginatorSteps = [5, 10, 25, 100];
    onLayoutElementSet() {
        this.SetSelect();
        this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        let ps = this.LayoutElementValue.PageSize ? parseInt(this.LayoutElementValue.PageSize) : 300;
        if (this.PagingType == PagingType.Pager && this.PaginatorSteps.indexOf(ps) < 0) {
            ps = this.PaginatorSteps[1];
        }
        this.PageSize = ps;
    }
    onLayoutElementChanged() {
        this.initialized = false;
        this.SetSelect();
        //this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad; Disable AutoLoad nur initial setzen, nicht bei jeder Layout-�nderung
        let ps = this.LayoutElementValue.PageSize ? parseInt(this.LayoutElementValue.PageSize) : 300;
        if (this.PagingType == PagingType.Pager && this.PaginatorSteps.indexOf(ps) < 0) {
            ps = this.PaginatorSteps[1];
        }
        this.PageSize = ps;
        this.doInit(false);
    }
    ControlInitialized() {
        this.doInit(false);
    }

    doInit(fromDSChange: boolean) {
        this.Selection = [];
        const subDT = this.Subscriptions['DTDataSource'];
        if (subDT && subDT.unsubscribe) {
            subDT.unsubscribe();
        }
        if (this.Rows) {
            if (fromDSChange) {
                if (this.DataSourceValue) {
                    this.Rows = this.DataSourceValue;
                }
            } else {
                this.InitializeDataSource(() => new DataTableRowDataSource(this));
            }
        } else if (this.ExternalDataSource) {
            this.InitializeDataSource(() => this.ExternalDataSource);
        } else if (!fromDSChange) {
            MetaHelper.FindDataBindingProperties(this.Layout, this.LayoutElementValue).then(result => {
                if (result) {
                    if (result.IsDataBinding) {
                        if (result.IsShared) {
                            this.DataSourceID = result.Table.SID;
                            this.DataSourceElement = this.LayoutElementValue;
                            this.AdditionalFilter = new RequestFilter();
                            if (result.KeyColumns && result.KeyColumns.length === 1) {
                                this.AdditionalFilter.Name = result.KeyColumns[0];
                                if (this.DataSourceValue != null) {
                                    this.AdditionalFilter.Operator = Comparer.Equal;
                                    this.AdditionalFilter.Value = this.DataSourceValue;
                                } else {
                                    this.AdditionalFilter.Operator = Comparer.In;
                                    this.AdditionalFilter.Value = [];
                                }
                                this.Subscriptions['DTDataSource'] = this.DataSourceChange.subscribe(x => {
                                    if (this.DataSourceValue != null) {
                                        this.AdditionalFilter.Operator = Comparer.Equal;
                                        this.AdditionalFilter.Value = this.DataSourceValue;
                                    } else {
                                        this.AdditionalFilter.Operator = Comparer.In;
                                        this.AdditionalFilter.Value = [];
                                    }
                                    this.Rerender();
                                });
                            } else {
                                this.AdditionalFilter.Name = '_Id';
                                this.AdditionalFilter.Operator = Comparer.In;
                                this.AdditionalFilter.Value = [];
                                if (Array.isArray(this.DataSourceValue)) {
                                    this.AdditionalFilter.Value.push(...this.DataSourceValue);
                                }
                                this.Subscriptions['DTDataSource'] = this.DataSourceChange.subscribe(x => {
                                    this.AdditionalFilter.Value = [];
                                    if (Array.isArray(x)) {
                                        this.AdditionalFilter.Value.push(...x);
                                    }
                                    this.Rerender();
                                });
                            }
                            this.Columns = this.LayoutElementValue.Columns;
                            this.InitializeDataSource(() => new DataTableDataSource(this.DataSourceID, this.service, TableStyle.DataTable));
                        } else {
                            this.DataSourceElement = this.LayoutElementValue;
                            //this.onRefresh();
                            this.Rows = this.DataSourceValue;
                            this.Subscriptions['DTDataSource'] = this.DataSourceChange.subscribe(x => {
                                this.Rows = x;
                            });
                            this.Columns = this.LayoutElementValue.Columns;
                            this.InitializeDataSource(() => new DataTableRowDataSource(this));
                        }
                    } else {
                        this.DataSourceID = result.Table.SID;
                        this.DataSourceElement = MetaHelper.FindValidParent(this.Layout, this.LayoutElementValue);
                        this.Columns = this.LayoutElementValue.Columns;
                        this.InitializeDataSource(() => new DataTableDataSource(this.DataSourceID, this.service, TableStyle.DataTable));
                    }
                }
            });
        }
        this.cdRef.detectChanges();
    }

    private setFlatTableSource(source) {
        this.FlatTableSource = source;
        if (this.Subscriptions['Initialized']) {
            this.Subscriptions['Initialized'].unsubscribe();
        }
        this.Subscriptions['Initialized'] = this.FlatTableSource.Initialized.subscribe((res) => {
            this.initialized = res;
            this.cdRef.detectChanges();
        });
        if (this.Subscriptions['Loading']) {
            this.Subscriptions['Loading'].unsubscribe();
        }
        this.Subscriptions['Loading'] = this.FlatTableSource.Loading.subscribe((res) => {
            this.loading = res;
            this.cdRef.detectChanges();
        });
    }

    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        super.ngAfterViewInit();
        this.cdRef.detectChanges();
    }
    //#endregion

    //#region Table&Data
    //#region Columns
    CreateColumns(val) {
        let doc = document.getElementById(this.LayoutElement.ID);
        let maxWidthPX = 0;
        let docWidth = 0;
        if (doc) {
            docWidth = doc.offsetWidth;
            maxWidthPX = docWidth;
            if (this.SelectMode == 1) {
                maxWidthPX -= Math.ceil(3.4 * 16);
            }
        }
        this.InternalColumns = val;
        this.RequestColumns = [];
        this.DefaultSort = [];
        let cols = [];
        this.ResponsiveColumns = [];
        this.EnumSources = {};
        let tempCols = [];
        if (val) {
            tempCols = val.sort((a, b) => {
                if (typeof a.Position === 'number') {
                    if (typeof b.Position === 'number') {
                        return a.Position - b.Position;
                    } else {
                        return 1;
                    }
                } else if (typeof b.Position === 'number') {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        let count = 0;
        let visiblecolumns = 0;
        const noSizeCols = [];
        tempCols.forEach((col) => {
            if (col.IsVisible) {
                visiblecolumns += 1;
            }
        });
        for (let i = 0; i < tempCols.length; i++) {
            const col = tempCols[i];
            if (col.FetchData || col.FetchData == null) {
                const column = new RequestColumn();
                column.Name = col.Name;
                column.IsCommaSeparated = col.IsCommaSeparated ? true : false;
                this.RequestColumns.push(column);
            }
            if(this.SortColumn!==null && this.SortOrder!==null){
                const sort = new RequestSort();
                sort.Name = this.SortColumn;
                if(this.SortOrder=="asc"){
                    sort.Order = Order.ASC;
                }
                else{
                    sort.Order = Order.DESC;
                }
                this.DefaultSort = [];  //Remove previous sort
                this.DefaultSort.push(sort);
            }
            if (typeof col.Order === 'number') {
                const sort = new RequestSort();
                sort.Name = col.Name;
                sort.Order = col.Order;
                this.DefaultSort.push(sort);
            }

            if (col.IsVisible) {
                const title = col.Caption ? col.Caption : col.Name;
                this.translate.get(title).subscribe((res: string) => {
                    this.GetColumnToPush(col, res).then((toPush) => {
                        count += 1;
                        if (this.FixedColumnsLeft && this.FixedColumnsLeft >= count) {
                            toPush['IsSticky'] = 'left';
                        } else if (this.FixedColumnsRight && this.FixedColumnsRight + count > visiblecolumns) {
                            toPush['IsSticky'] = 'right';
                        }

                        if (col.ResponsiveTitle) {
                            this.ResponsiveColumns.push(toPush);
                        }
                        if (toPush.Width && typeof toPush.Width.Value == 'number') {
                            if (toPush.Width.Type === UnitType.Pixel) {
                                maxWidthPX -= toPush.Width.Value;
                            } else if (toPush.Width.Type === UnitType.Percent) {
                                maxWidthPX -= docWidth * toPush.Width.Value;
                            }
                            toPush.CalculatedWidth = LayoutUnit.ToStyle(toPush.Width);
                        } else {
                            noSizeCols.push(toPush);
                        }
                        cols.push(toPush);

                        if (count === visiblecolumns) {
                            cols = cols.sort((a, b) => {
                                if (typeof a.Position === 'number') {
                                    if (typeof b.Position === 'number') {
                                        return a.Position - b.Position;
                                    } else {
                                        return 1;
                                    }
                                } else if (typeof b.Position === 'number') {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });
                            if (noSizeCols.length > 0) {
                                let colSize = Math.floor(maxWidthPX / noSizeCols.length);
                                if (colSize < 50) {
                                    colSize = 200;
                                }
                                noSizeCols.forEach((x) => {
                                    x.Width = new LayoutUnit();
                                    x.Width.Value = colSize;
                                    x.Width.Type = UnitType.Pixel;
                                    x.CalculatedWidth = LayoutUnit.ToStyle(x.Width);
                                });
                            }
                            this.ColumnsValue = cols;

                            this.LoadUserSettings();
                            if (this.ExternalDataSource) {
                                this.InitializeDataSource(() => this.ExternalDataSource);
                            }
                            this.cdRef.detectChanges();
                        }
                    });
                });
            }
        }
    }
    private GetColumnToPush(col, translation) {
        return new Promise<any>((resolve, reject) => {
            const toPush: any = {
                Caption: translation,
                Data: col.Name.replace(/\./g, '_'),
                Name: col.Name,
                IsEditable: col.IsEditable ? true : false,
                IsSortable: col.IsSortable ? true : false,
                IsFilterable: col.IsFilterable ? true : false,
                IsCommaSeparated: col.IsCommaSeparated ? true : false,
                OrderState: 'NONE',
                Type: col.Type,
                Type2: col.Type2,
                Type3: col.Type3,
                Width: col.Width,
                SearchValue: null,
                IsList: col.IsList ? true : false,
                ParentIsList: col.ParentIsList,
                IsVisible: col.IsVisible ? true : false,
                IsSticky: col.IsSticky,
                Prefix: col.Prefix,
                Suffix: col.Suffix,
                Position: col.Position,
                NumericPrecision: col.NumericPrecision,
                DateFormat: col.DateFormat,
                DateFormatCulture: col.DateFormatCulture,
                StyleID: 0,
                UseIcons: col.UseIcons,
                IconMapping: col.IconMapping,
                DefaultIcon: col.DefaultIcon
            };
            if (col.Type2 == '876cf188-243b-49ed-91ab-b2cf27216a30' && col.Type3 !== '00000000-0000-0000-0000-000000000000') {
                EnumHelper.GetEnumValues(col.Type3).then((data) => {
                    this.EnumSources[col.Type3] = [...data];
                    toPush['IsEnum'] = true;
                    toPush['DisplayMemberPath'] = 'Caption';
                    toPush['ValueMemberPath'] = 'Value';
                    resolve(toPush);
                });
            } else {
                resolve(toPush);
            }
        });
    }
    //#endregion
    SortColumn: string;
    SortOrder: string;
    InitializeDataSource(getDS) {
        if (!this.FlatTableSource) {
            this.setFlatTableSource(getDS());
        }
        this.Filter = FilterHelper.PrepareFilter(this.DataSourceElement);
        if (this.AdditionalFilter) {
            FilterHelper.AddSearchFilter(this.Filter, this.AdditionalFilter);
        }
        this.FlatTableSource.SetFilter(this.Filter.Filters);
        this.FlatTableSource.UseCaching = this.LayoutElementValue.UseCaching;
        this.FlatTableSource.PagingType = this.LayoutElementValue.PagingType;
        this.FlatTableSource.pageSize = this.PageSize;
        this.FlatTableSource.Columns = this.Columns;
        this.FlatTableSource.SetRequestColumns(this.RequestColumns);
        this.FlatTableSource.SetOrder(this.DefaultSort);
        if (!this.DisableAutoLoad) {
            this.FlatTableSource.Initialize();
        }
    }
    OnPage(event) {
        if (this.PagingType == PagingType.Pager) {
            if (this.PageSize != event.rows) {
                this.PageSize = event.rows;
                if (this.FlatTableSource) {
                    this.FlatTableSource.RefreshAndFetch(event.first, event.first + event.rows);
                }
            } else if (this.FlatTableSource) {
                this.FlatTableSource.fetch(event.first, event.first + event.rows);
            }
        }
    }
    LazyLoad(event: LazyLoadEvent) {
        if (!this.DisableAutoLoad) {
            if (this.RequestColumns && this.RequestColumns.length > 0) {
                let options = FilterHelper.PrepareFilter(this.DataSourceElement);
                if (this.AdditionalFilter) {
                    FilterHelper.AddSearchFilter(options, this.AdditionalFilter);
                }
                if (event.multiSortMeta && event.multiSortMeta.length > 0) {
                    let list = [];

                    for (let i = 0; i < event.multiSortMeta.length; i++) {
                        let sort = event.multiSortMeta[i];
                        let column = JSON.parse(sort.field);
                        if (column.IsSortable) {
                            let s = new RequestSort();
                            s.Name = column.Name;
                            s.Order = sort.order == -1 ? Order.DESC : Order.ASC;
                            s.Position = i;
                            list.push(s);
                        }
                    }
                    if (list.length > 0) {
                        options.Sort = list;
                    }
                }
                if (event.filters) {
                    let requestFilters = new RequestFilter();
                    requestFilters.ConcatOperator = Concat.And;
                    let columns = Object.keys(event.filters); {
                        for (let i = 0; i < columns.length; i++) {
                            let column: any = event.filters[columns[i]];
                            let filters = new RequestFilter();

                            if (column[0] && column[0].operator == 'and') {
                                filters.ConcatOperator = Concat.And;
                            } else {
                                filters.ConcatOperator = Concat.Or;
                            }
                            if (Array.isArray(column)) {
                                column.forEach((filter) => {
                                    this.ConvertFilter(filter, filters, columns[i]);
                                });
                            } else {
                                this.ConvertFilter(column, filters, columns[i]);
                            }
                            if (filters.Filters) {
                                if (!requestFilters.Filters) {
                                    requestFilters.Filters = [];
                                }
                                requestFilters.Filters.push(filters);
                            }
                        }
                    }
                    if (requestFilters.Filters) {
                        if (options.Filters) {
                            options.Filters.push(requestFilters);
                        } else {
                            options.Filters = [requestFilters];
                        }
                    }
                }
                if (this.FlatTableSource) {
                    if (JSON.stringify(options) != JSON.stringify(this.Filter)) {
                        this.Filter = options;
                        this.FlatTableSource.SetFilter(options.Filters);
                        this.FlatTableSource.SetOrder(options.Sort);
                        this.FlatTableSource.Refresh();
                    } else {
                        if (this.PagingType != PagingType.Pager) {
                            this.FlatTableSource.fetch(event.first, event.first + event.rows);
                        }
                    }
                }
            }
        } else {
            this.DisableAutoLoad = false;
        }
    }
    ConvertFilter(filter, filters, name) {
        if (filter.value != null) {
            if (!filters.Filters) {
                filters.Filters = [];
            }
            let f = new RequestFilter();
            f.Name = name;
            f.Value = filter.value;
            f.Operator = Comparer.Equal;
            switch (filter.matchMode) {
                case FilterMatchMode.EQUALS:
                    f.Operator = Comparer.Equal;
                    break;
                case FilterMatchMode.NOT_EQUALS:
                    f.Operator = Comparer.NotEqual;
                    break;
                case FilterMatchMode.CONTAINS:
                    f.Operator = Comparer.Like;
                    break;
                case FilterMatchMode.LESS_THAN:
                    f.Operator = Comparer.Smaller;
                    break;
                case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
                    f.Operator = Comparer.SmallerEqual;
                    break;
                case FilterMatchMode.GREATER_THAN:
                    f.Operator = Comparer.Greater;
                    break;
                case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
                    f.Operator = Comparer.GreaterEqual;
                    break;
            }
            filters.Filters.push(f);
        }
        return
    }
    //#endregion
    //#region Actions
    clicked() {
        this.triggerEvent('OnFocus', this.DataSourceValue);
    }

    @Output() OnRowDoubleClicked = new EventEmitter<any>();
    RowDoubleClicked(event) {
        this.triggerEvent('DoubleClick', { RowIndex: event.index, RowData: event.data });
        this.OnRowDoubleClicked.emit(event);
    }

    ValueChanged(item) {
        this.triggerEvent('ValueChanged', item);
    }
    clones: any = {};
    onCellEdit(event) {
        if (this.EditMode == 'cell') {
            this.ValueChanged(event.data.data);
        }
    }
    onRowEditInit(row) {
        this.clones[row.index] = { ...row.data };
    }

    onRowEditSave(row) {
        delete this.clones[row.index];
        this.ValueChanged(row.data);
    }

    onRowEditCancel(row, index: number) {
        row.data = this.clones[row.index];
        delete this.clones[row.index];
    }
    ExecuteRefresh() {
        this.DisableAutoLoad = false;
        this.Rerender();
    }
    Rerender() {
        if (this.FlatTableSource) {
            this.initialized = false;
            setTimeout(() => {
                this.doInit(false);
            }, 50);
        }
        this.cdRef.detectChanges();
    }
    //#region Selection
    Selection;
    @Output() RowSelectionChanged = new EventEmitter<any>();
    onRowSelect(event) {
        this.emitSelection();
    }
    onRowUnselect(event) {
        this.emitSelection();
    }
    HeaderSelected = false;
    onHeaderSelect(event) {
        //event.
        this.emitSelection();
    }
    emitSelection() {
        const selection = [];
        if (this.Selection) {
            if (Array.isArray(this.Selection)) {
                if (this.Selection.length > 0) {
                    this.Selection.forEach(x => {
                        selection.push(x.data);
                    });
                }
            } else {
                selection.push(this.Selection.data);
            }
        }
        this.triggerEvent('SelectionChanged', selection);
        this.RowSelectionChanged.emit(selection);
    }
    //#endregion
    ColResized(event) {
        if (event && event.element) {
            let col = this.Columns.find((value) => value.Caption == event.element.innerText);
            if (col) {
                col.CalculatedWidth = event.element.clientWidth + 'px';
            }
        }
        VariableHelper.ValueChanged(this.Layout, {
            ID: this.LayoutElement.ID,
            Value: this.Columns
        });
    }
    ColsReordered(event) {
        if (event.columns) {
            for (let i = 0; i < event.columns.length; i++) {
                event.columns[i].Position = i;
            }
        }
        VariableHelper.ValueChanged(this.Layout, {
            ID: this.LayoutElement.ID,
            Value: this.Columns
        });
    }
    //#endregion

    LoadUserSettings() {
        if (this.Layout) {
            this.UserSpecificColumns = null;
            if (VariableHelper.ActiveVariablesMap[this.Layout._Id + '||' + this.LayoutElement.ID]) {
                let retVal = [];
                const cols = VariableHelper.ActiveVariablesMap[this.Layout._Id + '||' + this.LayoutElement.ID].Value;
                if (cols && this.ColumnsValue) {
                    this.ColumnsValue.forEach((item) => {
                        const col = cols.find((value) => value.Name == item.Name);
                        if (col && col.CalculatedWidth) {
                            col.Width.Value = col.CalculatedWidth;
                            col.Width.Type = 0;
                            retVal.push(col);
                        } else {
                            retVal.push(item);
                        }
                    })
                    this.UserSpecificColumns = retVal;
                    this.UserSpecificColumns = this.UserSpecificColumns.sort((a, b) => {
                        if (a.Position > b.Position) {
                            return 1;
                        }
                        if (a.Position < b.Position) {
                            return -1;
                        }
                        return 0;
                    });
                }
            }
            this.cdRef.detectChanges();
        }
    }
    GetUserSpecificValue() {
        return this.Columns;
    }
    SetUserSpecificValue(val) {
        this.LoadUserSettings();
    }


    onResize(event: ResizedEvent) {
        if (event && event.newRect) {
            if (this.ColumnsValue) {
                let givenSizes = 0;
                let noSizeCols = [];
                this.ColumnsValue.forEach((col) => {
                    let x = null;
                    if (this.LayoutElementValue.Columns) {
                        x = this.LayoutElementValue.Columns.find((value) => value.Name == col.Name);
                    }
                    if (x && x.Width && typeof x.Width.Value === 'number') {
                        col.Width = x.Width;
                        col.CalculatedWidth = LayoutUnit.ToStyle(col.Width);
                        if (col.Width.Type === UnitType.Pixel) {
                            givenSizes += col.Width.Value;
                        } else if (col.Width.Type === UnitType.Percent) {
                            givenSizes += event.newRect.width * col.Width.Value;
                        }
                    } else {
                        noSizeCols.push(col);
                    }
                });
                if (noSizeCols.length > 0) {
                    if (this.SelectMode == 1) {
                        givenSizes += Math.ceil(3.4 * 16);
                    }
                    let colSize = Math.floor((event.newRect.width - givenSizes) / noSizeCols.length);
                    if (colSize < 50) {
                        colSize = 200;
                    }
                    noSizeCols.forEach((x) => {
                        x.Width = new LayoutUnit();
                        x.Width.Value = colSize;
                        x.Width.Type = UnitType.Pixel;
                        x.CalculatedWidth = LayoutUnit.ToStyle(x.Width);
                    });
                }
            }
            this.cdRef.detectChanges();
        }
    }

    @HostListener('window:keydown', ['$event'])
    @HostListener('window:click', ['$event'])
    keyboardInput(event) {
        if (event.shiftKey && event.type == "click") {
            console.log("shiftkey+left mouse");
            this.Rerender();
            // console.log(this.DefaultSort);
            // this.FlatTableSource.SetOrder(this.DefaultSort);
        }
    }
}

@Component({
    selector: 'evi-tableHeaderCheckbox',
    template: `
        <div class="p-checkbox p-component" (click)="onClick($event)">
            <div class="p-hidden-accessible">
                <input #cb type="checkbox" [attr.id]="inputId" [attr.name]="name" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
                [disabled]="isDisabled()" [attr.aria-label]="ariaLabel">
            </div>
            <div #box [ngClass]="{'p-checkbox-box':true,
                'p-highlight':checked, 'p-disabled': isDisabled()}" role="checkbox" [attr.aria-checked]="checked">
                <span class="p-checkbox-icon" [ngClass]="{'pi pi-check':checked}"></span>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'p-element'
    }
})
export class EviTableHeaderCheckbox {

    @ViewChild('box') boxViewChild: ElementRef;

    @Input() disabled: boolean;

    @Input() inputId: string;

    @Input() name: string;

    @Input() ariaLabel: string;

    checked: boolean;

    selectionChangeSubscription: Subscription;

    valueChangeSubscription: Subscription;

    constructor(public dt: Table, public tableService: TableService, public cd: ChangeDetectorRef) {
        this.valueChangeSubscription = this.dt.tableService.valueSource$.subscribe(() => {
            this.checked = this.updateCheckedState();
        });

        this.selectionChangeSubscription = this.dt.tableService.selectionSource$.subscribe(() => {
            this.checked = this.updateCheckedState();
        });
    }

    ngOnInit() {
        this.checked = this.updateCheckedState();
    }

    onClick(event: Event) {
        if (!this.disabled) {
            if (this.dt.value && this.dt.value.length > 0) {
                this.dt.toggleRowsWithCheckbox(event, !this.checked);
            }
            this.checked = !this.checked;
            this.checked = this.updateCheckedState();
        }

        DomHandler.clearSelection();
    }

    onFocus() {
        DomHandler.addClass(this.boxViewChild.nativeElement, 'p-focus');
    }

    onBlur() {
        DomHandler.removeClass(this.boxViewChild.nativeElement, 'p-focus');
    }

    isDisabled() {
        return this.disabled || !this.dt.value || !this.dt.value.length;
    }

    ngOnDestroy() {
        if (this.selectionChangeSubscription) {
            this.selectionChangeSubscription.unsubscribe();
        }

        if (this.valueChangeSubscription) {
            this.valueChangeSubscription.unsubscribe();
        }
    }

    updateCheckedState() {
        this.cd.markForCheck();
        if (this.dt.filteredValue && !this.dt.lazy) {
            const val = this.dt.filteredValue;
            return (val && val.length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.isAllFilteredValuesChecked());
        }
        else {
            const val = this.dt.value;
            const length = val ? val.length : 0;
            let retVal = (val && length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.dt.selection.length === length);
            if (retVal) {
                val.forEach((item) => {
                    if (this.dt.selection.indexOf(item) == -1) {
                        retVal = false;
                    }
                })
            }
            return retVal;
        }
    }

    isAllFilteredValuesChecked() {
        if (!this.dt.filteredValue) {
            return false;
        }
        else {
            for (let rowData of this.dt.filteredValue) {
                if (!this.dt.isSelected(rowData)) {
                    return false;
                }
            }
            return true;
        }
    }
}