import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '../../../cache/cache.service';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { SideNavService } from '../../../services/sidenav.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'appswitch',
    templateUrl: 'appswitch.component.html',
    styleUrls: ['appswitch.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationSwitcherComponent extends IBaseComponent {

    static Type: any = 'appswitch';
    static Default = JSON.parse(JSON.stringify(defaultProperties.appswitch));

    Applications = [];
    SelectedApplication;

    constructor(private service: MetaService, private router: Router, cdRef: ChangeDetectorRef,
        @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ControlInitialized() {
        PermissionHelper.ActiveNavigationPermissions.subscribe((permissions) => {
            if (!permissions) {
                permissions = {};
            }
            this.service.GetNavigationStructures().subscribe(result => {
                const list = [];
                if (result) {
                    result.forEach((entry) => {
                        if (permissions[entry.SID] !== true) {
                            list.push({
                                Caption: entry.Caption,
                                ID: entry.SID
                            });
                        }
                    });
                }
                this.Applications = list;
                this.cdRef.detectChanges();
            });

        });
        SideNavService.SelectedNavigationStructure.subscribe((navStruct) => {
            this.SelectedApplication = navStruct;
        });
    }
    navigate(item) {
        this.service.LoadNavigationStructurePropertyByKey(item.Caption, 'LandingPage').subscribe(lp => {
            let page = '';
            if (typeof lp === 'string') {
                page = lp;
            }
            this.router.navigate([item.Caption + page]);
        });
    }
}
