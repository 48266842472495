import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { LanguageService } from '../../../services/language.service';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-hyperpay',
    templateUrl: './hyperpay.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HyperPayControl extends IBaseComponent {
    static Type: any = 'hyperpay';
    static Default = JSON.parse(JSON.stringify(defaultProperties.hyperpay));

    _ViewInitialized = false;

    _Language = "en";

    CheckoutIDValue: string;
    @Input()
    get CheckoutID() {
        return this.CheckoutIDValue;
    }
    set CheckoutID(val) {
        this.CheckoutIDValue = val;
        this.CheckControl();
    }

    ResultURLValue: string;
    @Input()
    get ResultURL() {
        return this.ResultURLValue;
    }
    set ResultURL(val) {
        this.ResultURLValue = val;
        this.CheckControl();
    }

    @ViewChild('scriptDIV') scriptDIV: ElementRef;

    constructor(translate: TranslateService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('CheckoutID', 'string', '@@CheckoutID'));
        this.PropertyList.push(new ElementProperty('ResultURL', 'string', '@@ResultURL'));
        this.setLocale(translate.currentLang);
    }

    setLocale(lang) {
        const langinfo = LanguageService.Languages.getValue().find(l => l.LCID.toString() === lang);
        if (langinfo) {
            this._Language = langinfo.Name.slice(0, 2);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this._ViewInitialized = true;
        this.CheckControl();
    }
    ControlInitialized() {
    }
    CheckControl() {
        if (this.scriptDIV && this.scriptDIV.nativeElement) {
            this.scriptDIV.nativeElement.innerHTML = '';
            if (this.CheckoutIDValue && this.ResultURLValue) {
                const le = this.LayoutElementValue;
                let options = 'var wpwlOptions = { locale: "' + this._Language + '"';
                if (le && le.HyperpayStyle) {
                    options += ', style: "' + le.HyperpayStyle + '"';
                }
                options += '}';
                const optScript = document.createElement('script');
                optScript.type = 'text/javascript';
                optScript.innerText = options;
                this.scriptDIV.nativeElement.appendChild(optScript);
                const payScript = document.createElement('script');
                payScript.type = 'text/javascript';
                payScript.async = true;
                payScript.src = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=' + this.CheckoutIDValue;
                this.scriptDIV.nativeElement.appendChild(payScript);
                const form = document.createElement('form');
                form.action = this.ResultURLValue;
                form.classList.add('paymentWidgets');
                if (le && Array.isArray(le.DataBrands) && le.DataBrands.length > 0) {
                    form.setAttribute('data-brands', le.DataBrands.join(' '));
                } else {
                    form.setAttribute('data-brands', 'VISA MASTER AMEX');
                }
                this.scriptDIV.nativeElement.appendChild(form);
            }
            this.cdRef.detectChanges();
        }
    }
}

export class HyperPayPanel extends BasePanel {
    static override SIDS = ['23f3c349-f0ed-4971-85f6-d80b7a60a602']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID: '23f3c349-f0ed-4971-85f6-d80b7a60a602',
            ID: 'hyperpay',
            Caption: '@@HyperPay',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'hyperpay';
            }
        });

        PROPERTIES.push({
            ID: "HyperpayStyle",
            Parent: "hyperpay",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Style",
            Row: 1,
            InitArgs: {
                Placeholder: "@@Style",
                Multiple: false,
                ItemsSource: [
                    {
                        Caption: '@@Card',
                        Value: 'card'
                    }, {
                        Caption: '@@Plain',
                        Value: 'plain'
                    }],
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "DataBrands",
            Parent: "hyperpay",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Brands",
            Row: 2,
            InitArgs: {
                Placeholder: "@@Brands",
                Multiple: true,
                ItemsSource: [
                    {
                        Caption: '@@Visa',
                        Value: 'VISA'
                    }, {
                        Caption: '@@Mastercard',
                        Value: 'MASTER'
                    }, {
                        Caption: '@@American Express',
                        Value: 'AMEX'
                    }, {
                        Caption: '@@GooglePay',
                        Value: 'GOOGLEPAY'
                    }, {
                        Caption: '@@ApplePay',
                        Value: 'APPLEPAY'
                    }, {
                        Caption: '@@Mada',
                        Value: 'MADA'
                    }],
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
    }
}