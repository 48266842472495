import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { LayoutUnitThicknessComponent } from '../../../appbuilder/controls/layoutunitthickness/layoutunitthickness.component';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { UnitSelectionControl } from '../../../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { LayoutUnitThickness } from '../../../models/basic/layoutunitthickness.model';
import { Alignment } from '../../../models/enums/alignment.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-button',
    templateUrl: './button.control.html',
    styleUrls: ['./button.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonControl extends IBaseComponent {
    static Type: any = 'button';
    static Default = JSON.parse(JSON.stringify(defaultProperties.button));

    //#region Caption
    CaptionValue;
    @Input()
    get Caption() {
        if (this.CaptionValue != null) {
            return this.CaptionValue;
        }
        return this.LayoutElement.Caption;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    //#region CaptionPosition

    @Input()
    get CaptionPosition() {
        return this.LayoutElement.CaptionPosition;
    }
    set CaptionPosition(val) {
        this.LayoutElement.CaptionPosition = val;
        this.CaptionPositionChange.emit(this.LayoutElement.CaptionPosition);
        this.cdRef.detectChanges();
    }

    @Output() CaptionPositionChange = new EventEmitter<any>();
    //#endregion

    //#region ButtonType
    @Input()
    get ButtonType() {
        return this.LayoutElement.ButtonType;
    }
    set ButtonType(val) {
        this.LayoutElement.ButtonType = val;
        this.ButtonTypeChange.emit(this.LayoutElement.ButtonType);
        this.cdRef.detectChanges();
    }

    @Output() ButtonTypeChange = new EventEmitter<any>();
    //#endregion

    //#region Icon
    IconValue;
    @Input()
    get Icon() {
        if (this.IconValue != null) {
            return this.IconValue;
        }
        return this.LayoutElement.Icon;
    }
    set Icon(val) {
        this.IconValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    //#region IconWidth
    @Input()
    get IconWidth() {
        return this.LayoutElement.IconWidth;
    }
    set IconWidth(val) {
        this.LayoutElement.IconWidth = val;
        this.IconWidthChange.emit(this.LayoutElement.IconWidth);
        this.cdRef.detectChanges();
    }

    @Output() IconWidthChange = new EventEmitter<any>();
    //#endregion

    //#region IconHeight
    @Input()
    get IconHeight() {
        return this.LayoutElement.IconHeight;
    }
    set IconHeight(val) {
        this.LayoutElement.IconHeight = val;
        this.IconHeightChange.emit(this.LayoutElement.IconHeight);
        this.cdRef.detectChanges();
    }

    @Output() IconHeightChange = new EventEmitter<any>();
    //#endregion


    //#region IconPadding
    @Input()
    get IconPadding() {
        return this.LayoutElement.IconPadding;
    }
    set IconPadding(val) {
        this.LayoutElement.IconPadding = val;
        this.IconPaddingChange.emit(this.LayoutElement.IconPadding);
        this.cdRef.detectChanges();
    }

    @Output() IconPaddingChange = new EventEmitter<any>();
    //#endregion

    //#region IconMargin
    @Input()
    get IconMargin() {
        return this.LayoutElement.IconMargin;
    }
    set IconMargin(val) {
        this.LayoutElement.IconMargin = val;
        this.IconMarginChange.emit(this.LayoutElement.IconMargin);
        this.cdRef.detectChanges();
    }

    @Output() IconMarginChange = new EventEmitter<any>();
    //#endregion
    //#region Tooltip
    TooltipValue;
    @Input()
    get Tooltip() {
        if (this.TooltipValue != null) {
            return this.TooltipValue;
        }
        return this.LayoutElement.Tooltip;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    @Output() Click = new EventEmitter<any>();

    HorStyle = {
        'justify-content': 'center'
    };
    VertStyle = {
        'justify-content': 'center'
    };
    IconStyle = of({
        width: '24px',
        height: '24px'
    });

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('click');
        this.PropertyList.push(new ElementProperty('Caption', 'string', '@@Text'));
        this.PropertyList.push(new ElementProperty('Icon', 'string', '@@Icon'));
        this.PropertyList.push(new ElementProperty('Tooltip', 'string', '@@Tooltip'));
    }

    ControlInitialized() {        
    }

    onClick(ev) {
        this.triggerEvent('click', this.DataSourceValue);
        this.Click.emit(ev);
    }

    setFocus() {
        let control = document.getElementsByName(this.LayoutElement.ID);
        if (control && control.length == 1) {
            control[0].focus();
        }
    }
}
export class ButtonPanel extends BasePanel {
    static override SIDS = ['5a4ec382-fd55-4164-8edb-2c6c266914f1']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'5a4ec382-fd55-4164-8edb-2c6c266914f1',
            ID: 'Button',
            Caption: '@@Button',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'button' || item.ElementType == 'upload' || item.ElementType == 'download' || item.ElementType == 'tablecolumnvisibility';
            }
        });
        //#region ButtonTypes
        let buttonTypes = [
        {
            Caption: '@@Basic',
            Value: 'mat-button'
        },{
            Caption: '@@Raised',
            Value: 'mat-raised-button'
        },{
            Caption: '@@Stroked',
            Value: 'mat-stroked-button'
        },{
            Caption: '@@Flat',
            Value: 'mat-flat-button'
        },{
            Caption: '@@Icon',
            Value: 'mat-icon-button'
        },{
            Caption: '@@Fab',
            Value: 'mat-fab'
        },{
            Caption: '@@MiniFab',
            Value: 'mat-mini-fab'
        }]
        //#endregion ButtonTypes

        //#region CaptionPositions
        let captionPositions = [{
            Caption: '@@Oben',
            Value: 'top'
        },{
            Caption: '@@Rechts',
            Value: 'right'
        },{
            Caption: '@@Unten',
            Value: 'bottom'
        },{
            Caption: '@@Links',
            Value: 'left'
        }]
        //#endregion CaptionPositions
        PROPERTIES.push({
            ID: "Caption",
            Parent: "Button",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Caption",
            Column: 1,
            Row:1,
            InitArgs: {
                DataType:'string'
            }
        });
        PROPERTIES.push({
            ID: "Tooltip",
            Parent: "Button",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Tooltip",
            Column: 1,
            Row: 2,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "ButtonType",
            Parent: "Button",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Type",
            Column: 1,
            Row: 3,
            InitArgs: {
                Placeholder: "@@Type",
                Multiple: false,
                ItemsSource: buttonTypes,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        

        MediaService.IconList.subscribe((result) => {
            if (result) {
                let icons = [];
                result.forEach((icon) => {
                    icons.push({ Caption: icon, Icon: icon, Value: icon })
                });
                PROPERTIES.push({
                    ID: "Icon",
                    Parent: "Button",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@Icon",
                    Column: 1,
                    Row: 4,
                    InitArgs: {
                        Placeholder: "@@Icon",
                        Multiple: false,
                        ItemsSource: icons,
                        ValueMemberPath: "Value",
                        DisplayMemberPath: "Caption",
                        IconMemberPath: "Icon"
                    }
                });
            }
        });
        
        
        PROPERTIES.push({
            ID: "CaptionPosition",
            Parent: "Button",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@CaptionIconPosition",
            Column: 1,
            Row: 5,
            CheckVisibility: (item) => {
                return item.Icon;
            },
            InitArgs: {
                Placeholder: "@@CaptionIconPosition",
                Multiple: false,
                ItemsSource: captionPositions,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
        PROPERTIES.push({
            ID: "IconWidth",
            Parent: "Button",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "@@IconWidth",
            LabelPosition: MenuTabLabelPosition.Top,
            Column: 1,
            Row: 6,
            CheckVisibility: (item) => {
                return item.Icon;
            },
            InitArgs: {
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "IconHeight",
            Parent: "Button",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "@@IconHeight",
            LabelPosition: MenuTabLabelPosition.Top,
            Column: 1,
            Row: 7,
            CheckVisibility: (item) => {
                return item.Icon;
            },
            InitArgs: {
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "IconAlignment",
            Parent: "Button",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Icon align",
            Column: 1,
            Row: 8,
            CheckVisibility: (item) => {
                return item.Icon;
            },
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: Alignment
            }
        });
        PROPERTIES.push({
            ID: "IconMargin",
            Parent: "Button",
            Content: new ComponentPortal(LayoutUnitThicknessComponent),
            Type: LayoutUnitThickness,
            Label: "@@IconMargin",
            LabelPosition: MenuTabLabelPosition.Top,
            Column: 1,
            Row: 9,
            CheckVisibility: (item) => {
                return item.Icon;
            },
            InitArgs: {
                IsBorderRadius: false
            }
        });
        PROPERTIES.push({
            ID: "IconPadding",
            Parent: "Button",
            Content: new ComponentPortal(LayoutUnitThicknessComponent),
            Type: LayoutUnitThickness,
            Label: "@@IconPadding",
            LabelPosition: MenuTabLabelPosition.Top,
            Column: 1,
            Row: 10,
            CheckVisibility: (item) => {
                return item.Icon;
            },
            InitArgs: {
                IsBorderRadius: false
            }
        });
    }
}