import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { IBaseComponent } from '../base.component';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutService } from '../../../services/layout.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ComponentPortal } from '@angular/cdk/portal';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { ThemeHelper } from '../../../helpers/theme.helpers';
import { MetaService } from '../../../services/meta.service';
import { Theme } from 'src/app/models/style/theme.model';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
	selector: 'evi-togglebutton',
	templateUrl: './togglebutton.control.html',
	styleUrls: ['./togglebutton.control.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonControl extends IBaseComponent {
	static Type: any = 'togglebutton';
	static Default = JSON.parse(JSON.stringify(defaultProperties.togglebutton));
	//#region Caption
	CaptionValue;
	@Input()
	get Caption() {
		if (this.CaptionValue != null) {
			return this.CaptionValue;
		}
		return this.LayoutElement.Caption;
	}
	set Caption(val) {
		this.CaptionValue = val;
	}
	//#endregion

	//#region Icon
	@Input()
	get Icon() {
		return this.LayoutElement.Icon;
	}
	set Icon(val) {
		this.LayoutElement.Icon = val;
		this.IconChange.emit(this.LayoutElement.Icon);
	}

	@Output() IconChange = new EventEmitter<any>();
	//#endregion

	//#region IsChecked
	@Input()
	get IsChecked() {
		return this.DataSourceValue;
	}
	set IsChecked(val) {
		this.DataSourceValue = val;
		this.IsCheckedChange.emit(this.DataSourceValue);
	}

	@Output() IsCheckedChange = new EventEmitter<any>();
	//#endregion

	@ViewChild('togglebutton', { read: ElementRef })
	togglebutton: ElementRef<HTMLInputElement>;

	constructor(
		private metaService: MetaService,
		cdRef: ChangeDetectorRef,
		@Inject(LayoutService.CONTAINER_DATA) public data
	) {
		super(cdRef, data);
		this.EventList.push('checked');
		this.EventList.push('unchecked');
		this.PropertyList.push(
			new ElementProperty('IsChecked', 'boolean', '@@Togglestate')
		);
		this.PropertyList.push(new ElementProperty('Caption', 'string', '@@Text'));
	}

	ControlInitialized() {}
	CurrentTheme: Theme;
	Clicked() {
		this.IsChecked = !this.IsChecked;
		if (this.IsChecked) {
			this.setDarkTheme(false);
			this.triggerEvent('checked');
		} else {
			this.setDarkTheme(true);

			this.triggerEvent('unchecked');
		}
	}
	ngOnInit(): void {
		if (this.Caption.toLowerCase().includes('dark mode')) {
			this.metaService.GetDefaultTheme().subscribe((theme: Theme) => {
                this.CurrentTheme = theme;
                if (theme.IsDarkTheme) {    
                    this.IsChecked = true;
                    this.triggerEvent('checked');
                }
			});
		}
	}
	setFocus() {
		if (this.togglebutton && this.togglebutton.nativeElement) {
			this.togglebutton.nativeElement.focus();
		}
	}

    setDarkTheme(isDark: boolean) {
        if (this.Caption.toLowerCase().includes('dark mode')) {
            
            const darkTheme: Theme = this.CurrentTheme;
            darkTheme.IsDarkTheme = isDark;
            this.metaService.SaveTheme(darkTheme).subscribe((result) => {
                ThemeHelper.SetTheme(darkTheme);
            });
        }
	}
}
export class ToggleButtonPanel extends BasePanel{
    static override SIDS = ['06be69d6-4bb8-4c2e-a7a3-bcd46fc46122']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'06be69d6-4bb8-4c2e-a7a3-bcd46fc46122',
            ID: 'togglebutton',
            Caption: '@@ToggleButton',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'togglebutton';
            }
        });
        PROPERTIES.push({
            ID: "Caption",
            Parent: "togglebutton",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Caption",
            Column: 1,
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        });
    }
}