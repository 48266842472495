import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { QRErrorCorrectionLevel } from '../../../models/enums/errorcorrectionlevel.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'qr',
    templateUrl: 'qr.component.html',
    styleUrls: ['qr.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QRComponent extends IBaseComponent {

    static Type: any = 'qr';
    static Default = JSON.parse(JSON.stringify(defaultProperties.qr));
    ErrorCorrectionLevelValue:any = QRErrorCorrectionLevel.M;
    get ErrorCorrectionLevel() {
        if (this.LayoutElementValue) {
            return QRErrorCorrectionLevel[this.LayoutElementValue.ErrorCorrectionLevel];
        } else {
            return QRErrorCorrectionLevel[this.ErrorCorrectionLevelValue];
        }
    }
    set ErrorCorrectionLevel(value) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.ErrorCorrectionLevel = value;
        } else {
            this.ErrorCorrectionLevelValue = value;
        }
    }

    WidthValue = 256;
    get Width() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.QRSize;
        } else {
            return this.WidthValue;
        }
    }
    set Width(value) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.QRSize = value;
        } else {
            this.WidthValue = value;
        }
    }

    PixelPerModuleValue = 20;
    get PixelPerModule() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.PixelPerModule;
        } else {
            return this.PixelPerModuleValue;
        }
    }
    set PixelPerModule(value) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.PixelPerModule = value;
        } else {
            this.PixelPerModuleValue = value;
        }
    }

    @Input() status;

    ControlInitialized() {
    }
}
export class QRPanel extends BasePanel {
    static override SIDS = ['b3568934-7794-47d7-86da-ae0192035a27']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'b3568934-7794-47d7-86da-ae0192035a27',
            ID: 'QR',
            Caption: '@@QR',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'qr';
            }
        })

        PROPERTIES.push({
            ID: "QRSize",
            Parent: "QR",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Size",
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "PixelPerModule",
            Parent: "QR",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Pixel per Module",
            InitArgs: {
                InputType: "number",
                Minimum: 0,
                Step: 1
            }
        });
        PROPERTIES.push({
            ID: "ErrorCorrectionLevel",
            Parent: "QR",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ErrorCorrectionLevel",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: QRErrorCorrectionLevel
            }
        })

    }
}