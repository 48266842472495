import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	Renderer2,
	HostListener,
} from '@angular/core';
import { UUID } from 'angular2-uuid';
import { IntersectionHelper } from '../../../helpers/IntersectionHelper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import {
	ContextEvent,
	ContextEventHelper,
} from '../../../models/contextevent.model';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { UnitType } from '../../../models/enums/unittype.enum';
import { MultiLayoutElement } from '../../../models/layoutelement.model';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import {
	PROPERTYGROUPS,
	REGISTRY,
} from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
	selector: 'layout-item',
	templateUrl: './layout.item.component.html',
	styleUrls: ['./layout.item.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutItem implements OnInit, OnDestroy, AfterViewInit {
	//#region Properties
	private componentRef;
	Subscriptions = {};
	MetaTableProperties: any;
	ParentDisabled;
	InVisible = false;
	Initialized = false;
	selectedPortal: Portal<any>;
	//#region FromBaseLayout
	FromBaseLayoutValue = false;

	isBaseLayout = false;

	@Input()
	get FromBaseLayout() {
		return this.FromBaseLayoutValue;
	}
	set FromBaseLayout(val) {
		this.FromBaseLayoutValue = val;
		this.FromBaseLayoutChange.emit(this.FromBaseLayoutValue);
	}

	@Output() FromBaseLayoutChange = new EventEmitter<any>();
	//#endregion
	//#region Component
	ComponentValue;
	LastSourceValue;

	@Input()
	get Component() {
		return this.ComponentValue;
	}
	set Component(val) {
		this.ComponentValue = val;
		if (val && val.Elements && val.Elements.length > 0) {
			val.Elements.forEach((child) => (child.Parent = this.ComponentValue));
		}
		this.cdRef.detectChanges();
		this.Initialized = false;
		this.CheckParentDisabled();
		this.RenderContent();
		this.ComponentChange.emit(this.ComponentValue);
	}

	@Output() ComponentChange = new EventEmitter<any>();
	//#endregion
	RefreshCount = 0;
	//#region Layout
	LayoutValue;

	@Input()
	get Layout() {
		return this.LayoutValue;
	}
	set Layout(val) {
		this.LayoutValue = val;
		this.CheckParentDisabled();
		this.RenderContent();
		this.LayoutChange.emit(this.LayoutValue);
	}

	@Output() LayoutChange = new EventEmitter<any>();
	//#endregion
	//#region Disabled
	DisabledValue;

	@Input()
	get Disabled() {
		if (this.ParentDisabled) {
			return true;
		}
		return this.DisabledValue;
	}
	set Disabled(val) {
		this.DisabledValue = val;
		this.DisabledChange.emit(this.DisabledValue);
	}

	@Output() DisabledChange = new EventEmitter<any>();
	//#endregion
	//#region ParentIsFlex
	ParentIsFlexValue;

	@Input()
	get ParentIsFlex() {
		return this.ParentIsFlexValue;
	}
	set ParentIsFlex(val) {
		this.ParentIsFlexValue = val;
		this.ParentIsFlexChange.emit(this.ParentIsFlexValue);
	}

	@Output() ParentIsFlexChange = new EventEmitter<any>();
	//#endregion
	//#region Parent
	ParentValue;

	@Input()
	get Parent() {
		return this.ParentValue;
	}
	set Parent(val) {
		this.ParentValue = val;
		this.ParentChange.emit(this.ParentValue);
	}

	@Output() ParentChange = new EventEmitter<any>();
	//#endregion

	//#region DataSource
	DataSourceValue;
	@Input()
	get DataSource() {
		return this.DataSourceValue;
	}
	set DataSource(val) {
		this.DataSourceValue = val;
		if (this.componentRef && this.componentRef.instance) {
			this.componentRef.instance.DataSource = this.GetSourceValue();
		}
		this.DataSourceChange.emit(this.DataSourceValue);
	}

	@Output() DataSourceChange = new EventEmitter<any>();
	//#endregion
	//#region TriggerEvents
	TriggerEventsValue = false;
	get TriggerEvents() {
		return this.TriggerEventsValue;
	}
	set TriggerEvents(val) {
		this.TriggerEventsValue = val;
		this.cdRef.detectChanges();
	}
	//#endregion
	//#region ItemSelected
	@Output() ItemSelected = new EventEmitter<any>();
	//#endregion
	//#endregion

	//#region LifeCycle
	constructor(
		private factoryResolver: ComponentFactoryResolver,
		public cdRef: ChangeDetectorRef,
		public _injector: Injector,
		private renderer: Renderer2
	) {}
	ID = UUID.UUID();
	SelectedValue = false;
	get Selected() {
		return this.SelectedValue;
	}
	set Selected(value) {
		this.SelectedValue = value;
		this.cdRef.detectChanges();
	}
	ngOnInit(): void {
		if (this.Component) {
			this.Subscriptions['TriggerEvents'] =
				LayoutService.TriggerEventsInEditMode.subscribe((mode) => {
					this.TriggerEvents = mode;
				});
			this.Subscriptions['SelectedItem'] = LayoutService.SelectedItem.subscribe(
				(item) => {
					if (this.Component.ViewType === ViewType.Edit) {
						this.Selected = this.Component == item;
						this.isBaseLayout = item?._Name == 'Base';
					} else {
						this.Selected = false;
					}
				}
			);
			this.Subscriptions['SelectedItems'] =
				LayoutService.SelectedItems.subscribe((items) => {
					if (items && items.length > 0) {
						this.Selected = items.indexOf(this.Component) > -1;
					}
				});
			this.Subscriptions['ViewType'] = LayoutService.ViewType.subscribe(
				(item) => {
					if (item == ViewType.View) {
						this.Selected = false;
						this.Hover = false;
					}
				}
			);
			if (this.Component.ComponentChanged) {
				this.Subscriptions['ComponentChanged'] =
					this.Component.ComponentChanged.subscribe(() => {
						this.Initialized = false;
						this.RenderContent();
					});
			}
			if (this.Component.ValueChanged) {
				this.Subscriptions['ValuesChanged'] =
					this.Component.ValuesChanged.subscribe((x) => {
						if (x == 'HandleIntersection') {
							this.ApplyIntersection();
						}
					});
			}
			if (
				this.Component['PermissionType'] &&
				this.Component['PermissionType'] === 'baselayout'
			) {
				this.Subscriptions['permissions'] =
					PermissionHelper.BaseLayoutPermissions.subscribe((permissions) => {
						if (permissions) {
							if (permissions[this.Component.ID]) {
								this.InVisible = permissions[this.Component.ID].InVisible;
								if (this.InVisible && this.Component.Initialized) {
									this.Component.Initialized.next(true);
								}
								this.Disabled = permissions[this.Component.ID].Disabled;
								if (this.Disabled) {
									this.Component.Editable = false;
								}
							} else {
								this.InVisible = false;
								this.Disabled = false;
							}
						} else {
							this.InVisible = false;
							this.Disabled = false;
						}
						this.cdRef.detectChanges();
					});
			} else {
				this.Subscriptions['permissions'] =
					PermissionHelper.ActiveContentPermissions.subscribe((permissions) => {
						if (permissions) {
							if (permissions[this.Component.ID]) {
								this.InVisible = permissions[this.Component.ID].InVisible;
								if (this.InVisible && this.Component.Initialized) {
									this.Component.Initialized.next(true);
								}
								this.Disabled = permissions[this.Component.ID].Disabled;
								if (this.Disabled) {
									this.Component.Editable = false;
								}
							} else {
								this.InVisible = false;
								this.Disabled = false;
							}
						} else {
							this.InVisible = false;
							this.Disabled = false;
						}
						this.cdRef.detectChanges();
					});
			}
		}
	}

	ngAfterViewInit(): void {
		//this.RenderContent();
		this.ApplyIntersection();
	}

	ngOnDestroy(): void {
		const keys = Object.keys(this.Subscriptions);
		keys.forEach((key) => {
			this.Subscriptions[key].unsubscribe();
		});
		if (this.leMainDIV && this.HandlesIntersection) {
			this.HandlesIntersection = false;
			IntersectionHelper.Unobserve(this.leMainDIV.nativeElement);
		}
	}

	createInjector(dataToPass): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(LayoutService.CONTAINER_DATA, dataToPass);
		return new PortalInjector(this._injector, injectorTokens);
	}

	RenderContent() {
		if (
			!this.Initialized &&
			this.Component &&
			this.Component.ElementType &&
			REGISTRY[this.Component.ElementType] &&
			this.LayoutValue
		) {
			this.Initialized = true;
			//let start = moment();
			this.ReadTableProperties().then(() => {
				//let end = moment();
				this.Component.MetaTableProperties = this.MetaTableProperties;
				this.selectedPortal = new ComponentPortal(
					REGISTRY[this.Component.ElementType].Control,
					null,
					this.createInjector({
						Layout: this.LayoutValue,
						Disabled: this.Disabled,
						DataSource: this.GetSourceValue(),
						LayoutElement: this.Component,
						Parent: this.Parent,
						FromBaseLayout: this.FromBaseLayout,
					})
				);
				this.cdRef.detectChanges();
			});
		}
	}
	ComponentAttached(event) {
		this.componentRef = event;
		//this.componentRef.instance.Layout = this.Layout;
		//this.componentRef.instance.Disabled = this.Disabled;
		//this.componentRef.instance.DataSource = this.GetSourceValue();
		//this.componentRef.instance.LayoutElement = this.Component;
		this.componentRef.instance.SetProperties().subscribe();
		//this.componentRef.instance.Parent = this.Parent;
		//this.componentRef.instance.FromBaseLayout = this.FromBaseLayout;
		this.Subscriptions['PropertyChanged'] =
			LayoutService.PropertyChanged.subscribe((prop) => {
				if (this.MetaTableProperties) {
					let mtp = this.MetaTableProperties.Fields.find(
						(value) =>
							value.Name == prop.PropertyName &&
							value.ID == this.Component.DataSource
					);
					if (mtp) {
						this.componentRef.instance.DataSource = this.GetSourceValue();
					}
				}
				this.RefreshCount += 1;
				this.cdRef.detectChanges();
			});
		//this.Subscriptions['ValuesChanged'] = this.Component.ValuesChanged.subscribe((prop) => {
		//    this.RefreshCount += 1;
		//    this.cdRef.detectChanges();
		//});
		this.Subscriptions['WorkflowStyleChanged'] =
			this.Component.WorkflowStyleChanged.subscribe((prop) => {
				this.RefreshCount += 1;
				this.cdRef.detectChanges();
			});
		this.componentRef.instance.DataSourceChange.subscribe((val) => {
			this.RefreshDataSource(val);
		});
		this.componentRef.instance.StyleChange.subscribe((lay) => {
			this.RefreshCount += 1;
			this.cdRef.detectChanges();
		});
		this.RefreshCount += 1;
		this.cdRef.detectChanges();
	}
	//#endregion
	RefreshDataSource(val) {
		if (this.LastSourceValue !== val) {
			if (this.MetaTableProperties) {
				if (this.MetaTableProperties.SID === this.Component.DataSource) {
					this.DataSourceValue = val;
					this.LastSourceValue = val;
				} else if (this.DataSourceValue) {
					this.MetaTableProperties.Fields.some((prop) => {
						if (prop.ID === this.Component.DataSource) {
							if (this.DataSourceValue[prop.Name] !== val) {
								if ((prop.Default != null && val == null) || val === '') {
									if (prop.IsList) {
										this.DataSourceValue[prop.Name] = [prop.Default];
										this.componentRef.instance.DataSource = [prop.Default];
									} else {
										this.DataSourceValue[prop.Name] = prop.Default;
										this.componentRef.instance.DataSource = prop.Default;
									}
								} else {
									this.DataSourceValue[prop.Name] = val;
								}
							}
							this.LastSourceValue = this.DataSourceValue[prop.Name];
							return true;
						}
						return false;
					});
				}
			}
		}
	}

	ReadTableProperties() {
		return new Promise((resolve, reject) => {
			if (!this.MetaTableProperties && this.Layout && this.Component) {
				MetaHelper.FindTablePropertiesSub(
					this.Layout,
					this.Component
				).subscribe((result) => {
					this.MetaTableProperties = result;
					resolve(true);
				});
			} else {
				resolve(true);
			}
		});
	}

	GetSourceValue() {
		let retVal = null;
		if (this.DataSourceValue) {
			retVal = this.DataSourceValue;
			if (
				this.MetaTableProperties &&
				this.MetaTableProperties.SID !== this.Component.DataSource
			) {
				const field = this.MetaTableProperties.Fields.find(
					(prop) => prop.ID === this.Component.DataSource
				);
				if (field) {
					if (
						field.Default != null &&
						this.DataSourceValue[field.Name] == null
					) {
						if (field.IsList) {
							this.DataSourceValue[field.Name] = [field.Default];
						} else {
							this.DataSourceValue[field.Name] = field.Default;
						}
					}
					retVal = this.DataSourceValue[field.Name];
				}
			}
		}
		this.LastSourceValue = retVal;
		return retVal;
	}
	_LabelClass = 'above';
	get LabelClass() {
		return this._LabelClass;
	}
	set LabelClass(value) {
		this._LabelClass = value;
		this.cdRef.detectChanges();
	}
	//#region Hover
	CheckLE() {
		if (this.Layout && this.Component) {
			let layout = document.getElementById('LE_' + this.Layout.ID);
			let component = document.getElementById('LE_' + this.Component.ID);
			if (layout && component) {
				let layoutRect = layout.getBoundingClientRect();
				let componentRect = component.getBoundingClientRect();
				if (
					componentRect.y == layoutRect.y &&
					componentRect.height == layoutRect.height
				) {
					this.LabelClass = '';
				} else if (componentRect.y - 30 <= layoutRect.y) {
					this.LabelClass = 'below';
				} else {
					this.LabelClass = 'above';
				}
			}
		}
	}

	private getEdge(event: MouseEvent, element: HTMLElement) {
		const offset = 10;
		const rect = element.getBoundingClientRect();
		const { clientX: x, clientY: y } = event;
		const { top, bottom, left, right } = rect;

		if (y <= top + offset) {
			return 'top';
		}

		if (y >= bottom - offset) {
			return 'bottom';
		}

		if (x <= left + offset) {
			return 'left';
		}

		if (x >= right - offset) {
			return 'right';
		}
	}
	private handleEdge(
		edge: 'left' | 'right' | 'top' | 'bottom',
		event: MouseEvent
	): void {
		// if (edge === 'right' || edge === 'bottom') {
		// 	this.updateElementDimensions(event);
		// }
		this.updateElementDimensions(event);
	}
	ShowResizeEdges = false;
	OnMounseEnter() {
		if (this.shouldStartResize()) {	
			this.ShowResizeEdges = true;
		}
	}
	OnMounseLeave() {
		if (this.shouldStartResize()) {	
			this.ShowResizeEdges = false;
		}
	}
	@ViewChild('resizableElement') resizableElement: ElementRef;
	private isResizing = false;
	private startX: number;
	private startY: number;
	private startWidth: number;
	private startHeight: number;

	@HostListener('mousemove', ['$event'])
	onMouseMove(event: MouseEvent): void {
		const edge = this.getEdge(event, this.resizableElement.nativeElement);
		this.handleEdge(edge, event);
	}

	@HostListener('mouseup', ['$event'])
	onMouseUp(event: MouseEvent): void {
		if (this.isResizing) {
			this.isResizing = false;
		}
	}
	@HostListener('mouseleave', ['$event'])
	onMouseLeave(event: MouseEvent): void {
		this.isResizing = false;
	}
	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent): void {
		this.startResize(event);
	}
	private shouldStartResize(): boolean {
		return (
			this.Component &&
			!this.isSpecialElementType() &&
			this.Component.ViewType == ViewType.Edit &&
			!this.TriggerEvents
		);
	}

	private isSpecialElementType(): boolean {
		const specialElementTypes = [
			'grid',
			'bootstrapgrid',
			'bootstraprepeat',
			'flex',
			'repeat',
			'raster',
			'canvas',
			'expansionpanel',
		];
		return specialElementTypes.includes(this.Component.ElementType);
	}

	startResize(event: MouseEvent): void {
		if (this.shouldStartResize()) {
			event.preventDefault();
			event.stopPropagation();
			this.isResizing = true;
			this.startX = event.clientX;
			this.startY = event.clientY;

			this.startWidth = this.convertToPixels(this.Component.Width, 'Width');
			this.startHeight = this.convertToPixels(this.Component.Height, 'Height');
		}
	}

	private convertToPixels(
		dimension: LayoutUnit,
		property: 'Width' | 'Height'
	): number {
		if (dimension?.Type === undefined) {
			const parentValue = this.getParentSize(property);
			return parentValue;
		}

		switch (dimension.Type) {
			case UnitType.Pixel:
				return dimension.Value;

			case UnitType.Percent:
				const parentValue = this.getParentSize(property);
				const convertedValue = (Number(dimension.Value) / 100) * parentValue;
				return convertedValue;

			case UnitType.em:
				const fontSize = this.getStylePropertyValue(
					'font-size',
					this.resizableElement.nativeElement
				);
				return Number(dimension.Value) * parseFloat(fontSize.split('px')[0]);

			case UnitType.vw:
				const viewportWidth = Math.max(
					document.documentElement.clientWidth || 0,
					window.innerWidth || 0
				);
				return (Number(dimension.Value) / 100) * viewportWidth;

			case UnitType.vh:
				const viewportHeight = Math.max(
					document.documentElement.clientHeight || 0,
					window.innerHeight || 0
				);
				return (Number(dimension.Value) / 100) * viewportHeight;

			default:
				return (parentValue / 100) * parentValue;
		}
	}
	private getParentSize(property: 'Width' | 'Height'): number {
		const parentElement = this.resizableElement.nativeElement.parentElement;
		if (parentElement && property === 'Width') return parentElement.clientWidth;
		return parentElement.clientHeight;
	}

	private updateElementDimensions(event: MouseEvent): void {
		if (this.isResizing) {
			const newWidth = this.startWidth + (event.clientX - this.startX);
			const newHeight = this.startHeight + (event.clientY - this.startY);

			const parentWidth =
				this.resizableElement.nativeElement.parentElement.clientWidth;
			const parentHeight =
				this.resizableElement.nativeElement.parentElement.clientHeight;

			this.updateDimension(
				'Width',
				newWidth,
				parentWidth,
				this.Component.Width
			);
			this.updateDimension(
				'Height',
				newHeight,
				parentHeight,
				this.Component.Height
			);
		}
	}

	private updateDimension(
		property: 'Width' | 'Height',
		newValue: number,
		parentSize: number,
		currentDimension: LayoutUnit
	): void {
		if (currentDimension?.Type === undefined) {
			currentDimension = new LayoutUnit();
			currentDimension.Type = UnitType.Pixel;
			currentDimension.Value = newValue;
			this.renderer.setStyle(
				this.resizableElement.nativeElement,
				property,
				`${newValue}px`
			);
			this.Component[property] = currentDimension;
			return;
		}

		const propertyToSet = property === 'Width' ? 'width' : 'height';

		this.renderer.setStyle(
			this.resizableElement.nativeElement,
			propertyToSet,
			`${newValue}px`
		);

		const convertedValue = this.convertPxToUnit(
			newValue,
			currentDimension.Type,
			property
		);
		this.Component[property] = convertedValue;
		// if (newValue <= parentSize - 10) {
		// 	this.renderer.setStyle(this.resizableElement.nativeElement, propertyToSet, `${newValue}px`);

		// 	const convertedValue = this.convertPxToUnit(newValue, currentDimension.Type, property);
		// 	this.Component[property] = convertedValue;
		// } else {
		// 	this.renderer.setStyle( this.resizableElement.nativeElement, propertyToSet, '100%');
		// 	this.Component[property] = { ...currentDimension, Value: '100', Type: UnitType.Percent };
		// }
	}

	private convertPxToUnit(
		px: number,
		unit: UnitType,
		property: 'Width' | 'Height'
	): LayoutUnit {
		const newValue = new LayoutUnit();
		newValue.Type = unit;

		switch (unit) {
			case UnitType.Pixel:
				newValue.Value = px;
				return newValue;

			case UnitType.Percent:
				const parentWidth = this.getParentSize(property);
				newValue.Value = (px / parentWidth) * 100;
				return newValue;

			case UnitType.em:
				const fontSize = this.getStylePropertyValue(
					'font-size',
					this.resizableElement.nativeElement
				);
				newValue.Value = px / parseFloat(fontSize.split('px')[0]);
				return newValue;

			case UnitType.vw:
				const viewportWidth = Math.max(
					document.documentElement.clientWidth || 0,
					window.innerWidth || 0
				);
				newValue.Value = (px / viewportWidth) * 100;
				return newValue;

			case UnitType.vh:
				const viewportHeight = Math.max(
					document.documentElement.clientHeight || 0,
					window.innerHeight || 0
				);
				newValue.Value = (px / viewportHeight) * 100;
				return newValue;

			default:
				return newValue;
		}
	}

	private getStylePropertyValue(property: string, element: any): string {
		return window.getComputedStyle(element).getPropertyValue(property);
	}

	HoverValue = false;
	get Hover() {
		return this.HoverValue;
	}
	set Hover(value) {
		if (this.HoverValue != value) {
			if (this.Component.ViewType === ViewType.Edit) {
				this.HoverValue = value;
				this.CheckLE();
				this.cdRef.detectChanges();
			} else {
				this.HoverValue = false;
			}
		}
	}
	FlagHoverValue = false;
	get FlagHover() {
		return this.FlagHoverValue;
	}
	set FlagHover(value) {
		this.FlagHoverValue = value;
		this.cdRef.detectChanges();
	}
	ActionsMouseEnter(event) {
		this.FlagHover = true;
		event.stopPropagation();
	}
	ActionsMouseOut(event) {
		this.FlagHover = false;
		event.stopPropagation();
	}
	InternalChange = false;
	mouseEnter(event) {
		if (this.Component && this.Component.Element) {
			this.Component.Element.triggerEvent('MouseEnter');
		}
	}
	mouseLeave(event) {
		if (this.Component && this.Component.Element) {
			this.Component.Element.triggerEvent('MouseLeave');
		}
	}
	mouseOver(event) {
		event.stopPropagation();
		if (this.Component.ViewType === ViewType.Edit) {
			const BaseEdit = LayoutService.BaseLayoutEditMode.getValue();
			if (BaseEdit) {
				this.Hover = true;
			} else if (!this.FromBaseLayout) {
				this.Hover = true;
			}
		}
		this.InternalChange = true;
	}
	mouseOut(event) {
		event.stopPropagation();
		this.Hover = false;
	}
	//#endregion
	ItemClick(event) {
		if (this.Component.ViewType === ViewType.Edit) {
			const BaseEdit = LayoutService.BaseLayoutEditMode.getValue();
			if (BaseEdit) {
				LayoutService.SelectedItem.next(this.Component);
			} else if (!this.FromBaseLayout) {
				LayoutService.SelectedItem.next(this.Component);
			}
			event.stopPropagation();
		}
		const item = new ContextEvent();
		item.Element = this.Component;
		item.Event = event;
		this.isBaseLayout = item.Element._Name == 'Base';
		LayoutService.HostClick.next(item);
		if (
			this.Component &&
			this.Component.Element &&
			this.Component.Element.Editable &&
			!this.Component.Element.Disabled
		) {
			this.Component.Element.triggerEvent('MouseClick', this.Component);
		}
	}
	//ItemDoubleClick(event) {
	//    this.Component.Element.triggerEvent('MouseDoubleClick', this.Component);
	//}
	RightClick(event: MouseEvent) {
		const item = new ContextEvent();
		item.Element = this.Component;
		item.Event = event;
		LayoutService.HostClick.next(item);
		event.stopPropagation();
	}
	open(event: MouseEvent) {
		LayoutService.SelectedItem.next(this.Component);

		const ev = new ContextEvent();
		ev.Element = this.Component;
		ev.Event = event;
		ContextEventHelper.Open.next(ev);

		event.stopPropagation();
	}
	NoContext(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
	}
	CheckParentDisabled() {
		if (this.ComponentValue && this.LayoutValue) {
			this.ParentDisabled = MetaHelper.FindDisabledParent(
				this.LayoutValue,
				this.ComponentValue
			);
			this.ValidParent = MetaHelper.FindValidParent(
				this.Layout,
				this.Component
			);
		}
	}
	ValidParent = null;

	selectedMenu = null;
	selectedGroup = null;
	OpenMenu(event) {
		event.stopPropagation();
		this.selectedMenu = null;
		this.selectedGroup = null;
		if (this.Component) {
			const regEntry = REGISTRY[this.Component.ElementType];
			if (regEntry) {
				if (regEntry.MenuTab) {
					this.selectedMenu = new ComponentPortal(regEntry.MenuTab, null, null);
				}
				if (regEntry.Panel) {
					if (PROPERTYGROUPS) {
						let panel = PROPERTYGROUPS.find(
							(val) =>
								regEntry.Panel.SIDS && regEntry.Panel.SIDS.indexOf(val.SID) > -1
						);
						this.selectedGroup = panel;
						if (panel) {
							this.selectedMenu = new ComponentPortal(
								panel.Content,
								null,
								null
							);
						}
					}
				}
			}
		}
	}
	MenuComponentAttached(event, item) {
		event.instance.PropertyGroup = this.selectedGroup;
	}
	MenuClosed() {
		this.selectedMenu = null;
	}
	Delete(event) {
		event.stopPropagation();
		const elem = LayoutService.SelectedItem.getValue();
		if (elem) {
			if (elem instanceof MultiLayoutElement) {
				return;
			}
			let templatePath = '';
			const layout = LayoutService.SelectedLayout.getValue();
			if (layout && layout.Workflows) {
				const parents = MetaHelper.FindParentPath(layout, elem);
				if (parents) {
					parents.forEach((x) => {
						if (x.ElementType === 'template') {
							templatePath += x.Name + '.';
						}
					});
				}
			}
			LayoutHelper.OnDeleteElement(elem, templatePath, layout, null);
		}
	}
	ShowActions = false;
	ChangeMode(event) {
		event.stopPropagation();
		this.ShowActions != this.ShowActions;
	}
	//#region Intersection
	HandlesIntersection = false;
	@ViewChild('leMainDIV') leMainDIV: ElementRef;
	ApplyIntersection() {
		if (this.leMainDIV) {
			if (this.Component && this.Component.HandleIntersection) {
				this.HandlesIntersection = true;
				IntersectionHelper.Observe(this.leMainDIV.nativeElement, (visible) => {
					if (this.componentRef) {
						this.componentRef.instance.handleIntersection(visible);
					}
				});
			} else if (this.HandlesIntersection) {
				this.HandlesIntersection = false;
				IntersectionHelper.Unobserve(this.leMainDIV.nativeElement);
			}
		}
	}
	//#endregion
}
