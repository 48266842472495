import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { of, Subject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FileSelectionThemeControl } from '../../../appbuilder/controls/fileselection/fileselection.theme.control';
import {
    CarouselMenuTabOptionsChanged, LayoutUnitThicknessComponent
} from '../../../appbuilder/controls/layoutunitthickness/layoutunitthickness.component';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextAreaThemeControl } from '../../../appbuilder/controls/textarea/textarea.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { UnitSelectionControl } from '../../../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { LayoutUnitThickness } from '../../../models/basic/layoutunitthickness.model';
import { MediaSource } from '../../../models/datamodel/mediasource.model';
import { AnimationMode } from '../../../models/enums/animationmode.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { MetaService } from '../../../services/meta.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-carousel',
    templateUrl: './carousel.control.html',
    styleUrls: ['./carousel.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselControl extends IBaseComponent {

    static Type: any = 'carousel';
    static Default = JSON.parse(JSON.stringify(defaultProperties.carousel));
    Template;
    TemplateOriginal;
    URL;
    titleText;
    selectedSlide;
    slickconfig;
    slickconfig2;
    EditMode = false;

    //#region AnimationMode
    @Input()
    get AnimationMode() {
        if (!this.LayoutElement.AnimationMode) {
            return AnimationMode[0];
        } else {
            return AnimationMode[this.LayoutElement.AnimationMode];
        }

    }
    set AnimationMode(val) {
        this.LayoutElement.AnimationMode = val;
    }
    //#endregion

    //#region Images
    ImagesValue;

    @Input()
    get Images() {
        if (this.LayoutElement && this.LayoutElement.Images && this.MediaSources && !this.ImagesValue) {
            const result = [];
            this.LayoutElement.Images.forEach((imageSource) => {
                if (imageSource.FileName) {
                    const ms = this.MediaSources[imageSource.MediaSource];
                    if (ms) {
                        const item = {
                            src: MediaSource.GetURL(ms, imageSource.FileName),
                            desc: imageSource.description,
                            title: imageSource.title
                        };
                        result.push(item);
                    }
                }
            });
            this.ImagesValue = result;
        }
        return of(this.ImagesValue);
    }
    set Images(val) {
        this.ImagesValue = val;
        this.ImagesChange.emit(this.ImagesValue);
        this.cdRef.detectChanges();
    }

    @Output() ImagesChange = new EventEmitter<any>();
    //#endregion

    CheckLayoutElement;
    createOptions;
    //#region AutoPlay
    @Input()
    get AutoPlay() {
        return this.LayoutElement.AutoPlay;
    }
    set AutoPlay(val) {
        this.CheckLayoutElement();
        this.LayoutElement.AutoPlay = val;
        this.createOptions();
        this.AutoPlayChange.emit(this.LayoutElement.AutoPlay);
    }

    @Output() AutoPlayChange = new EventEmitter<any>();
    //#endregion

    //#region AutoPlaySpeed
    @Input()
    get AutoPlaySpeed() {
        return this.LayoutElement.AutoPlaySpeed;
    }
    set AutoPlaySpeed(val) {
        this.CheckLayoutElement();
        this.LayoutElement.AutoPlaySpeed = val;
        this.createOptions();
        this.AutoPlaySpeedChange.emit(this.LayoutElement.AutoPlaySpeed);
    }

    @Output() AutoPlaySpeedChange = new EventEmitter<any>();
    //#endregion

    //#region Arrows
    @Input()
    get Arrows() {
        return this.LayoutElement.Arrows;
    }
    set Arrows(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Arrows = val;
        this.createOptions();
        this.ArrowsChange.emit(this.LayoutElement.Arrows);
    }

    @Output() ArrowsChange = new EventEmitter<any>();
    //#endregion

    //#region ArrowsNav
    @Input()
    get ArrowsNav() {
        return this.LayoutElement.ArrowsNav;
    }
    set ArrowsNav(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Arrows = val;
        this.createOptions();
        this.ArrowsNavChange.emit(this.LayoutElement.ArrowsNav);
    }

    @Output() ArrowsNavChange = new EventEmitter<any>();
    //#endregion

    //#region Dots
    @Input()
    get Dots() {
        return this.LayoutElement.Dots;
    }
    set Dots(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Dots = val;
        this.createOptions();
        this.DotsChange.emit(this.LayoutElement.Dots);
    }

    @Output() DotsChange = new EventEmitter<any>();
    //#endregion

    //#region DotsNav
    @Input()
    get DotsNav() {
        return this.LayoutElement.DotsNav;
    }
    set DotsNav(val) {
        this.CheckLayoutElement();
        this.LayoutElement.DotsNav = val;
        this.createOptions();
        this.DotsNavChange.emit(this.LayoutElement.DotsNav);
    }

    @Output() DotsNavChange = new EventEmitter<any>();
    //#endregion
    //#region FocusOnSelectNav
    @Input()
    get FocusOnSelectNav() {
        return this.LayoutElement.FocusOnSelectNav;
    }
    set FocusOnSelectNav(val) {
        this.CheckLayoutElement();
        this.LayoutElement.FocusOnSelectNav = val;
        this.createOptions();
        this.FocusOnSelectNavChange.emit(this.LayoutElement.FocusOnSelectNav);
    }

    @Output() FocusOnSelectNavChange = new EventEmitter<any>();
    //#endregion
    //#region CenterModeNav
    @Input()
    get CenterModeNav() {
        return this.LayoutElement.CenterModeNav;
    }
    set CenterModeNav(val) {
        this.CheckLayoutElement();
        this.LayoutElement.CenterModeNav = val;
        this.createOptions();
        this.CenterModeNavChange.emit(this.LayoutElement.CenterModeNav);
    }

    @Output() CenterModeNavChange = new EventEmitter<any>();
    //#endregion

    //#region Fade
    @Input()
    get Fade() {
        return this.LayoutElement.Fade;
    }
    set Fade(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Fade = val;
        this.createOptions();
        this.FadeChange.emit(this.LayoutElement.Fade);
    }

    @Output() FadeChange = new EventEmitter<any>();
    //#endregion

    //#region Infinite
    @Input()
    get Infinite() {
        return this.LayoutElement.Infinite;
    }
    set Infinite(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Infinite = val;
        this.createOptions();
        this.InfiniteChange.emit(this.LayoutElement.Infinite);
    }

    @Output() InfiniteChange = new EventEmitter<any>();
    //#endregion

    //#region touchMove
    @Input()
    get TouchMove() {
        return this.LayoutElement.TouchMove;
    }
    set TouchMove(val) {
        this.CheckLayoutElement();
        this.LayoutElement.TouchMove = val;
        this.createOptions();
        this.TouchMoveChange.emit(this.LayoutElement.TouchMove);
    }

    @Output() TouchMoveChange = new EventEmitter<any>();
    //#endregion

    //#region SlidesToShow
    @Input()
    get SlidesToShow() {
        return this.LayoutElement.SlidesToShow;
    }
    set SlidesToShow(val) {
        this.CheckLayoutElement();
        this.LayoutElement.SlidesToShow = val;
        this.createOptions();
        this.SlidesToShowChange.emit(this.LayoutElement.SlidesToShow);
    }

    @Output() SlidesToShowChange = new EventEmitter<any>();
    //#endregion

    //#region SlidesToScroll
    @Input()
    get SlidesToScroll() {
        return this.LayoutElement.SlidesToScroll;
    }
    set SlidesToScroll(val) {
        this.CheckLayoutElement();
        this.LayoutElement.SlidesToScroll = val;
        this.createOptions();
        this.SlidesToScrollChange.emit(this.LayoutElement.SlidesToScroll);
    }

    @Output() SlidesToScrollChange = new EventEmitter<any>();
    //#endregion

    //#region SlidesToShowNav
    @Input()
    get SlidesToShowNav() {
        return this.LayoutElement.SlidesToShowNav;
    }
    set SlidesToShowNav(val) {
        this.CheckLayoutElement();
        this.LayoutElement.SlidesToShowNav = val;
        this.createOptions();
        this.SlidesToShowNavChange.emit(this.LayoutElement.SlidesToShowNav);
    }

    @Output() SlidesToShowNavChange = new EventEmitter<any>();
    //#endregion

    //#region SlidesToScrollNav
    @Input()
    get SlidesToScrollNav() {
        return this.LayoutElement.SlidesToScrollNav;
    }
    set SlidesToScrollNav(val) {
        this.CheckLayoutElement();
        this.LayoutElement.SlidesToScrollNav = val;
        this.createOptions();
        this.SlidesToScrollNavChange.emit(this.LayoutElement.SlidesToScrollNav);
    }

    @Output() SlidesToScrollNavChange = new EventEmitter<any>();
    //#endregion

    //#region ShowFilmStrip
    @Input()
    get ShowFilmStrip() {
        return this.LayoutElement.ShowFilmStrip;
    }
    set ShowFilmStrip(val) {
        this.CheckLayoutElement();
        this.LayoutElement.ShowFilmStrip = val;
        this.createOptions();
        this.ShowFilmStripChange.emit(this.LayoutElement.ShowFilmStrip);
    }

    @Output() ShowFilmStripChange = new EventEmitter<any>();
    //#endregion
    MediaSources = {};
    UniqueClass = {};
    Templates = [];

    slickModal;
    @ViewChild('slickModal') set modal(modal: SlickCarouselComponent) {
        if (modal) {
            this.slickModal = modal;
        }
    };
    slickModalNav;
    @ViewChild('slickModalNav') set modalnav(modalnav: SlickCarouselComponent) {
        if (modalnav) {
            this.slickModalNav = modalnav;
        }
    };

    GenerateTemplates() {
        this.Templates = [];
        this.DataSource$ = of([]);
        if (this.Template && this.DataSourceValue && Array.isArray(this.DataSourceValue)) {
            for (var i = 0; i < this.DataSourceValue.length; i++) {
                //#TODO: possible performance issue if to many DataSource Entries are bound ... 
                let item = plainToClass(LayoutElement, this.TemplateOriginal);
                item['LoadByReference'] = false;
                item['Parent'] = this.LayoutElement.Parent;
                item.ID = UUID.UUID();
                this.Templates[i] = item
            }
            this.cdRef.detectChanges();
            this.DataSource$ = of(this.DataSourceValue);
        }
    }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data, private service: MetaService) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Images', 'list', '@@Images'));
        this.Subscriptions['OptionsChanged'] = CarouselPanel.OptionsChanged.subscribe((oc) => {
            try {
                if (oc.Name === this.LayoutElement.Name) {
                    this.ImagesValue = null;
                }
            } catch (ex) {
                console.error(ex);
            }
        });
        this.Subscriptions['Overlay'] = LayoutService.OverlayStatus.subscribe((overlay) => {
            this.EditMode = overlay;
            //if (!this.EditMode) {
            //    this.updateRepeatElements();
            //}
        });
        this.DataSourceChange.subscribe(() => {
            this.GenerateTemplates();
        });
    }
    ControlInitialized() {
        this.InitConfig();
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.InitConfig();
        });
    }
    ngOnInit(): void {
        this.Subscriptions['MediaSources'] = MediaService.MediaSources.subscribe((sources) => {
            if (sources) {
                sources.forEach((source) => {
                    this.MediaSources[source.SID] = source;
                });
            }
        });
        super.ngOnInit();
    }
    changeSlide(text, slide) {
        this.titleText = text;
        this.selectedSlide = slide;
    }
    IsRtl = false;
    FilmStripVertical = false;
    onLayoutElementSet() {
        const unique = {};
        unique[this.LayoutElementValue.ID] = true;
        this.UniqueClass = unique;

        if (this.LayoutElementValue && this.LayoutElementValue.TemplateID && this.LayoutElementValue.UseDataSource) {
            this.service.LoadTemplate(this.LayoutElementValue.TemplateID).subscribe((value) => {
                this.Template = plainToClass(LayoutElement, value);
                this.TemplateOriginal = value;
                this.GenerateTemplates();
                this.cdRef.detectChanges();
            });
        }
        this.IsRtl = RTLHelper.Direction == 'rtl';
        this.Subscriptions['RTL']=RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir == 'rtl';
            this.InitConfig();
        });
    }
    GetMainStyle() {
        const result = {};
        if (this.LayoutElementValue.NavPosition) {
            switch (this.LayoutElementValue.NavPosition) {
                case 'top':
                case 'bottom':
                    result['height'] = '1px';
                    break;
                case 'left':
                    result['height'] = '100%';
                    result['width'] = 'calc(100% - ' + LayoutUnit.ToStyle(this.LayoutElementValue.NavSize) + ')';
                    result['float'] = 'right';
                    break;
                case 'right':
                    result['height'] = '100%';
                    result['width'] = 'calc(100% - ' + LayoutUnit.ToStyle(this.LayoutElementValue.NavSize) + ')';
                    result['float'] = 'left';
                    break;
            }
        }
        return result;
    }
    GetFilmStripStyle() {
        const result = {};        
        if (this.LayoutElementValue.NavPosition) {
            switch (this.LayoutElementValue.NavPosition) {
                case 'top':
                case 'bottom':
                    result['height'] = LayoutUnit.ToStyle(this.LayoutElementValue.NavSize);
                    break;
                case 'left': 
                case 'right':
                    result['width'] = LayoutUnit.ToStyle(this.LayoutElementValue.NavSize);
                    result['height'] = '100%';
                    result['float']=this.LayoutElementValue.NavPosition;
                    break;
            }
        } else {
            result['height'] = '200px';
        }        
        return result;
    }
    GetFilmStripClass() {
        let result = "";
        if (this.LayoutElementValue.NavPosition) {
            switch (this.LayoutElementValue.NavPosition) {
                case 'top':
                    result = "flex-column-reverse";
                    break;
                case 'bottom':
                    result = "flex-column";
                    break;
                case 'left':
                    //result = "flex-row-reverse";
                    break;
                case 'right':
                    //result = "flex-row";
                    break;
            }
        } else {
            result = "flex-column";
        }
        return result;
    }
    InitConfig() {
        this.slickconfig = null;
        this.slickconfig2 = null;
        this.cdRef.detectChanges();
        setTimeout(() => {
            this.slickconfig = {
                accessibility: this.Editable && !this.Disabled,
                draggable: this.Editable && !this.Disabled,
                slidesToShow: this.SlidesToShow,
                slidesToScroll: this.SlidesToScroll,
                arrows: false,
                fade: this.Fade,
                autoplay: this.AutoPlay,
                autoplaySpeed: this.AutoPlaySpeed,
                dots: this.Editable && !this.Disabled && this.Dots,
                infinite: this.Infinite,
                touchMove: this.TouchMove,
                dotsClass: 'custom-dots',
                rtl: this.IsRtl,
                customPaging: function (slider, i) {
                    return '<span class="dot"></span>';
                },
            };
            if (this.ShowFilmStrip) {
                this.slickconfig['asNavFor'] = '.slider-nav.' + this.LayoutElementValue.ID;
            }

            this.FilmStripVertical = this.LayoutElementValue.NavPosition && (this.LayoutElementValue.NavPosition == 'left' || this.LayoutElementValue.NavPosition == 'right');
            
            this.slickconfig2 = {
                accessibility: this.Editable && !this.Disabled,
                draggable: this.Editable && !this.Disabled,
                slidesToShow: this.SlidesToShowNav,
                slidesToScroll: this.SlidesToScrollNav,
                asNavFor: '.slider-for.' + this.LayoutElementValue.ID,
                dots: this.Editable && !this.Disabled && this.DotsNav,
                infinite: true,
                vertical: this.FilmStripVertical,
                focusOnSelect: true,
                centerMode: this.CenterModeNav,
                dotsClass: 'custom-dots',
                rtl: this.IsRtl,
                customPaging: function (slider, i) {
                    return '<span class="dot"></span>';
                },
            };
            this.cdRef.detectChanges();
        }, 50);
    }
    next() {
        if (this.slickModal) {
            this.slickModal.slickNext();
        }
    }
    prev() {
        if (this.slickModal) {
            this.slickModal.slickPrev();
        }
    }
    nextNav() {
        if (this.slickModalNav) {
            this.slickModalNav.slickNext();
        }
    }
    prevNav() {
        if (this.slickModalNav) {
            this.slickModalNav.slickPrev();
        }
    }
    onResized(event) {
        //this.ContainerSize = event.newHeight + 'px';
    }

    FirstTimeVisible = false;
    handleIntersection(visible: boolean) {
        if (visible && !this.FirstTimeVisible) {
            this.FirstTimeVisible = true;
            this.cdRef.detectChanges();
        }
    }
}
export class CarouselPanel extends BasePanel {
    static OptionsChanged: Subject<CarouselMenuTabOptionsChanged> = new Subject()
    static override SIDS = ['044c19e9-b174-44ca-870e-88e1e3336e6f', 'f97099b1-1597-480d-ae5b-eb5c24a51e75', '72250507-bda9-4815-92c3-aef077df2437']
    static optionChanged(prop: string, item) {
        const oc = new CarouselMenuTabOptionsChanged();
        oc.Name = item.Name;
        oc.Property = prop;
        CarouselPanel.OptionsChanged.next(oc);
    }
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'044c19e9-b174-44ca-870e-88e1e3336e6f',
            ID: 'carouselds',
            Caption: '@@Carousel Data',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'carousel';
            }
        })
        PROPERTYGROUPS.push({
            SID:'f97099b1-1597-480d-ae5b-eb5c24a51e75',
            ID: 'carouselset',
            Caption: '@@Carousel Settings',
            Index: 101,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'carousel';
            }
        })
        PROPERTYGROUPS.push({
            SID:'72250507-bda9-4815-92c3-aef077df2437',
            ID: 'carouselnav',
            Caption: '@@Carousel Navigation',
            Index: 102,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'carousel' && item.ShowFilmStrip;
            }
        })
        PROPERTIES.push({
            ID: "UseDataSource",
            Parent: "carouselds",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseDataSource"
            },
            Row: 1
        })
        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "Images",
                Parent: "carouselds",
                Content: new ComponentPortal(ListBoxThemeControl),
                Row: 2,
                Properties: [
                    {
                        ID: "MediaSource",
                        Parent: "carousel",
                        Content: new ComponentPortal(ComboboxThemeControl),
                        Label: "@@SelectMediaSource",
                        InitArgs: {
                            Placeholder: "@@Select",
                            Multiple: false,
                            ItemsSource: data,
                            DisplayMemberPath: 'Caption',
                            ValueMemberPath: 'SID'
                        }
                    }, {
                        ID: "FileName",
                        Parent: "carousel",
                        Content: new ComponentPortal(FileSelectionThemeControl),
                        CheckVisibility: (item, entry) => {
                            return entry.MediaSource;
                        }
                    }, {
                        ID: "Title",
                        Parent: "carousel",
                        Content: new ComponentPortal(TranslationTextBoxThemeControl),
                        Label: "@@Title",
                        InitArgs: {
                            DataType: 'string'
                        }
                    }, {
                        ID: "Description",
                        Parent: "carousel",
                        Content: new ComponentPortal(TextAreaThemeControl),
                        Label: "@@Description"
                    }
                ],
                CheckVisibility: (item) => {
                    return !item.UseDataSource;
                },
                InitArgs: {
                    DisplayMemberPath: 'FileName',
                    GenerateNewItem: () => {
                        return {};
                    }
                }
            });
        });
        let metaService = InjectorHelper.InjectorInstance.get<MetaService>(MetaService)
        metaService.ReadTemplateInfos().subscribe((templates) => {
            if (templates) {
                const list = [];
                templates.forEach(wf => {
                    list.push({
                        Caption: wf.Caption,
                        ID: wf.SID,
                        IsCapsule: wf.IsCapsule,
                        IsOverridden: wf.IsOverridden
                    });
                });
                PROPERTIES.push({
                    ID: "TemplateID",
                    Parent: "carouselds",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@SelectTemplate",
                    Row:2,
                    CheckVisibility: (item) => {
                        return item.UseDataSource;
                    },
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        ItemsSource: list,
                        DisplayMemberPath: 'Caption',
                        ValueMemberPath: 'ID'
                    }
                })
            }
        });
        PROPERTIES.push({
            ID: "ShowTitle",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ShowTitle"
            },
            Row:3
        });
        PROPERTIES.push({
            ID: "AutoPlay",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@AutoPlay"
            },
            Row:4
        });
        PROPERTIES.push({
            ID: "Arrows",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Arrows"
            },
            Row:5
        });
        MediaService.IconList.subscribe((icons) => {
            if (icons) {
                let Icons = [];
                icons.forEach((icon) => {
                    Icons.push({ Value: icon })
                })
                PROPERTIES.push({
                    ID: "IconPrev",
                    Parent: "carouselset",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@PreviousIcon",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        ItemsSource: Icons,
                        DisplayMemberPath: 'Value',
                        ValueMemberPath: 'Value',
                        IconMemberPath: 'Value'
                    },
                    CheckVisibility: (item) => {
                        return item.Arrows;
                    },
                    Row:6
                })
                PROPERTIES.push({
                    ID: "IconNext",
                    Parent: "carouselset",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@NextIcon",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        ItemsSource: Icons,
                        DisplayMemberPath: 'Value',
                        ValueMemberPath: 'Value',
                        IconMemberPath: 'Value'
                    },
                    CheckVisibility: (item) => {
                        return item.Arrows;
                    },
                    Row:7
                })
                PROPERTIES.push({
                    ID: "IconHeight",
                    Parent: "carouselset",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Type: LayoutUnit,
                    Label: "@@IconHeight",
                    CheckVisibility: (item) => {
                        return item.Arrows;
                    },
                    InitArgs: {
                        Minimum: 0
                    },
                    Row:8
                });
                PROPERTIES.push({
                    ID: "IconWidth",
                    Parent: "carouselset",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Type: LayoutUnit,
                    Label: "@@IconWidth",
                    CheckVisibility: (item) => {
                        return item.Arrows;
                    },
                    InitArgs: {
                        Minimum: 0
                    },
                    Row:9
                });


                PROPERTIES.push({
                    ID: "IconPrevNav",
                    Parent: "carouselnav",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@PreviousIcon",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        ItemsSource: Icons,
                        DisplayMemberPath: 'Value',
                        ValueMemberPath: 'Value',
                        IconMemberPath: 'Value'
                    },
                    CheckVisibility: (item) => {
                        return item.ArrowsNav && item.ShowFilmStrip;
                    },
                    Row: 19
                })
                PROPERTIES.push({
                    ID: "IconNextNav",
                    Parent: "carouselnav",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@NextIcon",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        ItemsSource: Icons,
                        DisplayMemberPath: 'Value',
                        ValueMemberPath: 'Value',
                        IconMemberPath: 'Value'
                    },
                    CheckVisibility: (item) => {
                        return item.ArrowsNav && item.ShowFilmStrip;
                    },
                    Row: 20
                })
                PROPERTIES.push({
                    ID: "IconHeightNav",
                    Parent: "carouselnav",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Type: LayoutUnit,
                    Label: "@@IconHeight",
                    CheckVisibility: (item) => {
                        return item.ArrowsNav && item.ShowFilmStrip;
                    },
                    InitArgs: {
                        Minimum: 0
                    },
                    Row: 21
                });
                PROPERTIES.push({
                    ID: "IconWidthNav",
                    Parent: "carouselnav",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Type: LayoutUnit,
                    Label: "@@IconWidth",
                    CheckVisibility: (item) => {
                        return item.ArrowsNav && item.ShowFilmStrip;
                    },
                    InitArgs: {
                        Minimum: 0
                    },
                    Row: 22
                });
            }
        });
        PROPERTIES.push({
            ID: "Dots",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@dots"
            },
            Row:10
        });
        PROPERTIES.push({
            ID: "Fade",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@fade"
            },
            Row:11
        });
        PROPERTIES.push({
            ID: "Infinite",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@infinite"
            },
            Row:12
        });
        PROPERTIES.push({
            ID: "TouchMove",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@touchMove"
            },
            Row:13
        });
        PROPERTIES.push({
            ID: "SlidesToShow",
            Parent: "carouselset",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@slidesToShow",
            Row: 14,
            InitArgs: {
                InputType: "number",
                Minimum:1
            }
        });
        PROPERTIES.push({
            ID: "SlidesToScroll",
            Parent: "carouselset",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@slidesToScroll",
            Row: 15,
            InitArgs: {
                InputType: "number",
                Minimum: 1
            }
        });
        PROPERTIES.push({
            ID: "ShowFilmStrip",
            Parent: "carouselset",
            Content: new ComponentPortal(CheckBoxThemeControl),
            Row: 16,
            InitArgs: {
                Caption: "@@ShowFilmStrip",
                Action: (ev) => {
                    LayoutHelper.RefreshMenuTabs.next(null);
                }
            },
            CheckVisibility: (item) => {
                return !item.UseDataSource;
            }
        });
        PROPERTIES.push({
            ID: "InnerMargin",
            Parent: "carouselset",
            Content: new ComponentPortal(LayoutUnitThicknessComponent),
            Label: "@@Margin",
            Row: 17,
            Type: LayoutUnitThickness,
            InitArgs: {
                Minimum: 0
            }
        });

        PROPERTIES.push({
            ID: "ArrowsNav",
            Parent: "carouselnav",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Arrows"
            },
            Row: 18,
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "DotsNav",
            Parent: "carouselnav",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@dots"
            },
            Row: 23,
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "SlidesToShowNav",
            Parent: "carouselnav",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@NavSlidesToShow",
            Row: 24,
            InitArgs: {
                InputType: "number",
                Minimum: 1
            },
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "SlidesToScrollNav",
            Parent: "carouselnav",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@NavSlidesToScroll",
            Row: 25,
            InitArgs: {
                InputType: "number",
                Minimum: 1
            },
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "FocusOnSelectNav",
            Parent: "carouselnav",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@focusOnSelect"
            },
            Row: 26,
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "CenterModeNav",
            Parent: "carouselnav",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@centerMode"
            },
            Row: 27,
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "NavSize",
            Parent: "carouselnav",
            Content: new ComponentPortal(UnitSelectionControl),
            Label: "@@NavSize",
            LabelPosition: MenuTabLabelPosition.Top,
            Row: 28,
            Type: LayoutUnit,
            InitArgs: {
                Minimum: 0
            },
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            }
        });
        PROPERTIES.push({
            ID: "NavPosition",
            Parent: "carouselnav",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Position",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [{
                    Caption: '@@Oben',
                    Value: 'top'
                }, {
                    Caption: '@@Rechts',
                    Value: 'right'
                }, {
                    Caption: '@@Unten',
                    Value: 'bottom'
                }, {
                    Caption: '@@Links',
                    Value: 'left'
                }],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value'
            },
            CheckVisibility: (item) => {
                return item.ShowFilmStrip;
            },
            Row: 29
        })
    }
}