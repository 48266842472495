import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'loading',
    templateUrl: 'loading.component.html',
    styleUrls: ['loading.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent extends IBaseComponent {

    static Type: any = 'loading';
    static Default = JSON.parse(JSON.stringify(defaultProperties.loading));

    EditMode = false;
    IsComponent = false;

    @Input() status;

    ControlInitialized() {
        this.IsComponent = true;
        this.EditMode = LayoutService.OverlayStatus.getValue();
        this.Subscriptions['Overlay'] = LayoutService.OverlayStatus.subscribe((overlay) => {
            this.EditMode = overlay;
        });
    }
}
