import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-radiobutton',
    templateUrl: './radiobutton.control.html',
    styleUrls: ['./radiobutton.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonControl extends IBaseComponent {
    static Type: any = 'radiobutton';
    static Default = JSON.parse(JSON.stringify(defaultProperties.radiobutton));
    //#region Source
    SourceValue;

    @Input()
    get Source() {
        return this.SourceValue;
    }
    set Source(val) {
        this.SourceValue = val;
        this.SourceChange.emit(this.SourceValue);
        this.cdRef.detectChanges();
    }

    @Output() SourceChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.cdRef.detectChanges();
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region DataSource
    @Input()
    get DataSource() {
        return this.SelectedItemValue;
    }
    set DataSource(val) {
        this.SelectedItemValue = val;
        this.DataSourceChange.emit(this.SelectedItemValue);
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    @ViewChildren('enumButton') enumButton;
    @ViewChild('firstButton') firstButton;
    ButtonsDisabled = true;

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('checked');
    }

    ControlInitialized() {
        this.initSource();
    }

    onLayoutElementChanged() {
        this.initSource();
    }

    initSource() {
        MetaHelper.FindDataBindingProperties(this.Layout, this.LayoutElementValue).then(result => {
            this.ButtonsDisabled = !result || !result.IsDataBinding;
            if (this.LayoutElementValue.IsEnum) { // Enum
                EnumHelper.GetEnumValues(this.LayoutElementValue.DataSourceID).then((data) => {
                    this.Source = data;
                    this.cdRef.detectChanges();
                });
            } else {
                this.Source = this.LayoutElementValue.CustomValues;
                this.cdRef.detectChanges();
            }
        });
    }

    onChange(ev) {
        this.triggerEvent('checked', ev.value);
    }

    setFocus() {
        if (this.firstButton && typeof this.firstButton.focus === 'function') {
            this.firstButton.focus();
        } else if (this.enumButton) {
            const array = this.enumButton.toArray();
            if (array) {
                array.some(button => {
                    if (button && typeof button.focus === 'function') {
                        button.focus();
                        return true;
                    }
                    return false;
                });
            }
        }
    }
}
export class RadioButtonPanel extends BasePanel {
    static override SIDS = ['7c1f7fcb-2bab-4595-adca-a3529874d5c6']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'7c1f7fcb-2bab-4595-adca-a3529874d5c6',
            ID: 'radiobutton',
            Caption: '@@RadioButton',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'radiobutton';
            }
        });
        PROPERTIES.push({
            ID: "CustomValues",
            Parent: "radiobutton",
            Content: new ComponentPortal(ListBoxThemeControl),
            Label: "@@CustomValues",
            Properties: [
                {
                    ID: "Caption",
                    Parent: "radiobutton",
                    Content: new ComponentPortal(TranslationTextBoxThemeControl),
                    Label: "@@Caption",
                    Column: 1,
                    Row: 1,
                    InitArgs: {
                        DataType: 'string'
                    }
                },
                {
                    ID: "Value",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Value",
                    LabelPosition: MenuTabLabelPosition.Top,
                    CheckVisibility: (item) => {
                        return item.DataSourceType == '9b056ce1-ffb9-4168-9e00-977449f2f66d'
                    },
                    InitArgs: {
                        InputType:'text'
                    }
                },
                {
                    ID: "Value",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Value",
                    LabelPosition: MenuTabLabelPosition.Top,
                    CheckVisibility: (item) => {
                        return item.DataSourceType == 'a4299f61-eb3f-4b29-8c4e-144458d17048' || item.DataSourceType == 'c02230ee-05fb-46d2-ab58-5b9dde1f32d5' || item.DataSourceType == 'a2eb35c4-56d5-4acc-a58e-ef855cb5086b'
                    },
                    InitArgs: {
                        InputType: 'number'
                    }
                },
                {
                    ID: "Value",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(CheckBoxThemeControl),
                    InitArgs: {
                        Caption: "@@Value"
                    },
                    CheckVisibility: (item) => {
                        return item.DataSourceType == '02414483-3194-474d-a3d1-0a1a8a36a9e0'
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    const item = { Caption: '', Value: '' }
                    return item;
                },
                DisplayMemberPath:'Caption'
            },
            LabelPosition: MenuTabLabelPosition.Top
        });
    }
}

