import { PortalInjector } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Injector, Input, Output, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { LayoutHelper } from '../../../../helpers/layout.helper';
import { MetaHelper } from '../../../../helpers/meta.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { CustomBaseStyle } from '../../../../models/basic/custombasestyle.model';
import { LayoutUnit } from '../../../../models/basic/layoutunit.model';
import { Row } from '../../../../models/basic/row.model';
import { Comparer } from '../../../../models/enums/comparer.enum';
import { PagingType } from '../../../../models/enums/pagingtype.enum';
import { ResponsiveType } from '../../../../models/enums/responsivetype.enum';
import { SelectMode } from '../../../../models/enums/selectmode.enum';
import { TableStyle } from '../../../../models/enums/tablestyle.enum';
import { UnitType } from '../../../../models/enums/unittype.enum';
import { ElementProperty } from '../../../../models/layoutbase.model';
import { RequestColumn } from '../../../../models/rest/requestcolumn';
import { RequestFilter } from '../../../../models/rest/requestfilter';
import { RequestOptions } from '../../../../models/rest/requestoptions';
import { RequestSort } from '../../../../models/rest/requestsort';
import { Color } from '../../../../models/style/color.model';
import { DynamicDataService } from '../../../../services/dynamicdata.service';
import { LayoutService } from '../../../../services/layout.service';
import { SettingsService } from '../../../../services/settings.service';
import { IBaseComponent } from '../../base.component';
import { defaultProperties } from 'src/app/helpers/defaultProperties.helper';

@Component({
    selector: 'evi-primetable',
    templateUrl: './primetable.control.html',
    styleUrls: ['./primetable.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimeTableControl extends IBaseComponent {
    static Type: any = 'primetable';
    static Default = JSON.parse(JSON.stringify(defaultProperties.primetable));
    //#region Properties
    initialized = false;
    ViewInitialized = false;
    EditMode = false;
    //#region Data
    DataSourceElement;
    DataSourceID;
    FlatTableSource: any;
    Filter: RequestOptions = new RequestOptions();
    AdditionalFilter: RequestFilter;
    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region ExternalDataSource
    ExternalDataSourceValue;

    @Input()
    get ExternalDataSource() {
        return this.ExternalDataSourceValue;
    }
    set ExternalDataSource(val) {
        this.ExternalDataSourceValue = val;
        this.ExternalDataSourceChange.emit(this.ExternalDataSourceValue);
    }

    @Output() ExternalDataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region DataSourceRows
    get DataSourceRows() {
        if (this.FlatTableSource) {
            return this.FlatTableSource.cachedData;
        } else if (this.Rows) {
            return this.Rows
        } else {
            return null;
        }
    }
    set DataSourceRows(val) {
        if (this.FlatTableSource) {
            this.FlatTableSource.cachedData = val;
        } else if (this.Rows) {
            this.Rows = val;
        }
    }
    //#endregion
    //#region Rows
    RowsValue;
    FilteredRows;

    @Input()
    get Rows() {
        return this.RowsValue;
    }
    set Rows(val) {
        this.RowsValue = val;
        if (Array.isArray(val)) {
            this.FilteredRows = FilterHelper.ApplyFilter(this.Filter, val, this.DefaultSort);
        } else {
            this.FilteredRows = null;
        }
        this.RowsChange.emit(this.RowsValue);
    }
    @Output() RowsChange = new EventEmitter<any>();
    //#endregion
    //#endregion

    //#region MobileBreakpoint
    MobileBreakpointValue;
    @Input()
    get MobileBreakpoint() {
        if (this.LayoutElement) {
            return this.LayoutElement.MobileBreakpoint;
        } else {
            return this.MobileBreakpointValue;
        }
    }
    set MobileBreakpoint(val) {
        if (this.LayoutElement) {
            this.LayoutElement.MobileBreakpoint = val;
            this.MobileBreakpointChange.emit(this.LayoutElement.MobileBreakpoint);
        } else {
            this.MobileBreakpointValue = val;
            this.MobileBreakpointChange.emit(this.MobileBreakpointValue);
        }
    }
    @Output() MobileBreakpointChange = new EventEmitter<any>();
    //#endregion
    //#region TableStyle
    TableStyleValue: TableStyle;

    @Input()
    get TableStyle() {
        if (this.LayoutElement) {
            return this.LayoutElement.TableStyle;
        } else {
            return this.TableStyleValue;
        }
    }
    set TableStyle(val) {
        if (this.LayoutElement) {
            this.LayoutElement.TableStyle = val;
            this.TableStyleChange.emit(this.LayoutElement.TableStyle);
        } else {
            this.TableStyleValue = val;
            this.TableStyleChange.emit(this.TableStyleValue);
        }
    }

    @Output() TableStyleChange = new EventEmitter<any>();
    //#endregion
    //#region CustomBaseStyle
    get CustomBaseStyleBinding(): CustomBaseStyle {
        if (this.LayoutElementValue) {
            let value = new CustomBaseStyle();
            if (this.LayoutElementValue.CustomBaseStyle) {
                if (this.LayoutElementValue.CustomBaseStyle.HeaderBackground) {
                    value.HeaderBackground = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.HeaderBackground);
                }
                if (this.LayoutElementValue.CustomBaseStyle.HeaderForeground) {
                    value.HeaderForeground = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.HeaderForeground);
                }
                if (this.LayoutElementValue.CustomBaseStyle.Line) {
                    value.Line = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.Line);
                }
                if (this.LayoutElementValue.CustomBaseStyle.StickyBackground) {
                    value.StickyBackground = Color.HexFromColor(this.LayoutElementValue.CustomBaseStyle.StickyBackground);
                }
            }
            return value;
        }
        return null;
    }
    CustomBaseStyleValue;

    @Input()
    get CustomBaseStyle() {
        if (this.LayoutElement) {
            return this.LayoutElement.CustomBaseStyle;
        } else {
            return this.CustomBaseStyleValue;
        }
    }
    set CustomBaseStyle(val) {
        if (this.LayoutElement) {
            this.LayoutElement.CustomBaseStyle = val;
            this.CustomBaseStyleChange.emit(this.LayoutElement.CustomBaseStyle);
        } else {
            this.CustomBaseStyleValue = val;
            this.CustomBaseStyleChange.emit(this.CustomBaseStyleValue);
        }
    }

    @Output() CustomBaseStyleChange = new EventEmitter<any>();
    CellStyles;
    //#endregion
    //#region PagingType
    PagingTypeValue: PagingType = PagingType.Scroll;

    @Input()
    get PagingType() {
        if (this.LayoutElement) {
            return this.LayoutElement.PagingType;
        } else {
            return this.PagingTypeValue;
        }
    }
    set PagingType(val) {
        if (this.LayoutElement) {
            this.LayoutElement.PagingType = val;
            this.PagingTypeChange.emit(this.LayoutElement.PagingType);
        } else {
            this.PagingTypeValue = val;
            this.PagingTypeChange.emit(this.PagingTypeValue);
        }
    }

    @Output() PagingTypeChange = new EventEmitter<any>();
    //#endregion
    //#region ResponsiveType
    ResponsiveTypeValue: ResponsiveType;

    @Input()
    get ResponsiveType() {
        if (this.LayoutElement) {
            return this.LayoutElement.ResponsiveType;
        } else {
            return this.ResponsiveTypeValue;
        }
    }
    set ResponsiveType(val) {
        if (this.LayoutElement) {
            this.LayoutElement.ResponsiveType = val;
            this.ResponsiveTypeChange.emit(this.LayoutElement.ResponsiveType);
        } else {
            this.ResponsiveTypeValue = val;
            this.ResponsiveTypeChange.emit(this.ResponsiveTypeValue);
        }
    }

    @Output() ResponsiveTypeChange = new EventEmitter<any>();
    //#endregion
    //#region SelectMode
    SelectModeValue: SelectMode;

    @Input()
    get SelectMode() {
        if (this.LayoutElement) {
            return this.LayoutElement.SelectMode;
        } else {
            return this.SelectModeValue;
        }
    }
    set SelectMode(val) {
        if (this.LayoutElement) {
            this.LayoutElement.SelectMode = val;
            this.SelectModeChange.emit(this.LayoutElement.SelectMode);
        } else {
            this.SelectModeValue = val;
            this.SelectModeChange.emit(this.SelectModeValue);
        }
    }

    @Output() SelectModeChange = new EventEmitter<any>();
    //#endregion
    //#region RowsExpandable
    RowsExpandableValue: boolean = false;

    @Input()
    get RowsExpandable() {
        if (this.LayoutElement) {
            return this.LayoutElement.RowsExpandable;
        } else {
            return this.RowsExpandableValue;
        }
    }
    set RowsExpandable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.RowsExpandable = val;
            this.RowsExpandableChange.emit(this.LayoutElement.RowsExpandable);
        } else {
            this.RowsExpandableValue = val;
            this.RowsExpandableChange.emit(this.RowsExpandableValue);
        }
    }

    @Output() RowsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region ColumnsExpandable
    ColumnsExpandableValue: boolean = false;

    @Input()
    get ColumnsExpandable() {
        if (this.LayoutElement) {
            return this.LayoutElement.ColumnsExpandable;
        } else {
            return this.ColumnsExpandableValue;
        }
    }
    set ColumnsExpandable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.ColumnsExpandable = val;
            this.ColumnsExpandableChange.emit(this.LayoutElement.ColumnsExpandable);
        } else {
            this.ColumnsExpandableValue = val;
            this.ColumnsExpandableChange.emit(this.ColumnsExpandableValue);
        }
    }

    @Output() ColumnsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region ShowSelectAll
    ShowSelectAllValue: boolean = false;

    @Input()
    get ShowSelectAll() {
        if (this.LayoutElement) {
            return this.LayoutElement.SelectAll;
        } else {
            return this.ShowSelectAllValue;
        }
    }
    set ShowSelectAll(val) {
        if (this.LayoutElement) {
            this.LayoutElement.SelectAll = val;
            this.ShowSelectAllChange.emit(this.LayoutElement.SelectAll);
        } else {
            this.ShowSelectAllValue = val;
            this.ShowSelectAllChange.emit(this.ShowSelectAllValue);
        }
    }

    @Output() ShowSelectAllChange = new EventEmitter<any>();
    //#endregion
    //#region Resizable
    ResizableValue: boolean = false;

    @Input()
    get Resizable() {
        if (this.LayoutElement) {
            return this.LayoutElement.Resizable;
        } else {
            return this.ResizableValue;
        }
    }
    set Resizable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.Resizable = val;
            this.ResizableChange.emit(this.LayoutElement.Resizable);
        } else {
            this.ResizableValue = val;
            this.ResizableChange.emit(this.ResizableValue);
        }
    }

    @Output() ResizableChange = new EventEmitter<any>();
    //#endregion
    //#region LineHeight
    LineHeightValue: number;

    @Input()
    get LineHeight() {
        if (this.LayoutElement) {
            return this.LayoutElement.LineHeight;
        } else {
            return this.LineHeightValue;
        }
    }
    set LineHeight(val) {
        if (this.LayoutElement) {
            this.LayoutElement.LineHeight = val;
            this.LineHeightChange.emit(this.LayoutElement.LineHeight);
        } else {
            this.LineHeightValue = val;
            this.LineHeightChange.emit(this.LineHeightValue);
        }
    }

    @Output() LineHeightChange = new EventEmitter<any>();
    //#endregion
    //#region Columns
    ColumnsValue;
    UserSpecificColumns;
    ResponsiveColumns;
    RequestColumns;
    InternalColumns;
    DefaultSort;
    @Input()
    get Columns() {
        if (this.UserSpecificColumns) {
            return this.UserSpecificColumns;
        } else {
            return this.ColumnsValue;
        }
    }
    set Columns(val) {
        this.CreateColumns(val);
        this.ColumnsChange.emit(this.ColumnsValue);
    }
    @Output() ColumnsChange = new EventEmitter<any>();

    FixedColumnsLeftValue;
    @Input()
    get FixedColumnsLeft() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.FixedColumnsLeft;
        } else {
            return this.FixedColumnsLeftValue;
        }
    }
    set FixedColumnsLeft(val) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.FixedColumnsLeft = val;
            this.FixedColumnsRightChange.emit(this.LayoutElementValue.FixedColumnsLeft);
        } else {
            this.FixedColumnsLeftValue = val;
            this.FixedColumnsLeftChange.emit(this.FixedColumnsLeftValue);
        }
        this.CreateColumns(this.Columns);
    }
    @Output() FixedColumnsLeftChange = new EventEmitter<any>();

    FixedColumnsRightValue;
    @Input()
    get FixedColumnsRight() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.FixedColumnsRight;
        } else {
            return this.FixedColumnsRightValue;
        }
    }
    set FixedColumnsRight(val) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.FixedColumnsRight = val;
            this.FixedColumnsRightChange.emit(this.LayoutElementValue.FixedColumnsRight);
        } else {
            this.FixedColumnsRightValue = val;
            this.FixedColumnsRightChange.emit(this.FixedColumnsRightValue);
        }
        this.CreateColumns(this.Columns);
    }
    @Output() FixedColumnsRightChange = new EventEmitter<any>();
    //#endregion
    //#endregion
    ScrollHeight="0";
    private container: ElementRef;
    @ViewChild('container') set _container(content: ElementRef) {
        this.container = content;
        if (this.container && this.container.nativeElement && this.ScrollHeight == "0") {
            this.ScrollHeight = this.container.nativeElement.clientHeight+'px';
        }
    }

    //#region LifeCycle
    constructor(private translate: TranslateService, public DataService: DynamicDataService, private settingsService: SettingsService, private cdref: ChangeDetectorRef, public _injector: Injector, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdref, data);
        this.EventList.push('SelectionChanged');
        this.EventList.push('DoubleClick');
        this.EventList.push('ValueChanged');
        this.EventList.push('OnFocus');
        this.PropertyList.push(new ElementProperty('Filter', 'list', '@@Filter'));
        this.PropertyList.push(new ElementProperty('Columns', 'list', '@@Spalten'));
        this.PropertyList.push(new ElementProperty('DataSourceRows', 'list', '@@Zeilen'));
        this.PropertyList.push(new ElementProperty('InternalColumns', 'list', '@@InterneSpalten'));
        //this.PropertyList.push(new ElementProperty('FlatTableSource.cachedData', 'list', '@@DataSource Zeilen'));
    }
    CreateColumns(val) {
        this.InternalColumns = val;
        this.RequestColumns = [];
        this.DefaultSort = [];
        const cols = [];
        this.ResponsiveColumns = [];
        const tempCols = val.sort((a, b) => {
            if (typeof a.Position === 'number') {
                if (typeof b.Position === 'number') {
                    return a.Position - b.Position;
                } else {
                    return 1;
                }
            } else if (typeof b.Position === 'number') {
                return -1;
            } else {
                return 0;
            }
        });
        let colcount = 0;
        let maxWidth = 100;
        let count = 0;
        let visiblecolumns = 0;
        tempCols.forEach((col) => {
            if (col.IsVisible) {
                visiblecolumns += 1;
            }
        });
        for (let i = 0; i < tempCols.length; i++) {
            const col = tempCols[i];
            if (col.FetchData || col.FetchData == null) {
                const column = new RequestColumn();
                column.Name = col.Name;
                column.IsCommaSeparated = col.IsCommaSeparated ? true : false;
                this.RequestColumns.push(column);
            }
            if (typeof col.Order === 'number') {
                const sort = new RequestSort();
                sort.Name = col.Name;
                sort.Order = col.Order;
                this.DefaultSort.push(sort);
            }

            if (col.IsVisible) {
                const title = col.Caption ? col.Caption : col.Name;
                this.translate.get(title).subscribe((res: string) => {
                    if (!col.Width || (col.Width && col.Width.Value == null)) {
                        colcount += 1;
                    } else {

                        if (col.Width.Type === UnitType.Percent) {
                            maxWidth -= col.Width.Value;
                        }
                    }
                    this.GetColumnToPush(col, res).then((toPush) => {
                        count += 1;
                        if (this.FixedColumnsLeft && this.FixedColumnsLeft >= i + 1) {
                            toPush['IsSticky'] = true;
                        } else if (this.FixedColumnsRight && i + this.FixedColumnsRight >= tempCols.length) {
                            toPush['IsSticky'] = true;
                        }
                        if (col.ResponsiveTitle) {
                            this.ResponsiveColumns.push(toPush);
                        }
                        cols.push(toPush);
                        if (count === visiblecolumns) {
                            cols.forEach((x) => {
                                if (!x.Width || !(typeof x.Width.Value === 'number')) {
                                    x.Width = new LayoutUnit();
                                    x.Width.Value = (maxWidth / colcount);
                                    x.Width.Type = UnitType.Percent;
                                }
                            });
                            this.ColumnsValue = cols;
                            this.LoadUserSettings();
                            if (this.FlatTableSource && !this.DisableAutoLoad) {
                                this.FlatTableSource.Columns = this.Columns;                                
                                this.FlatTableSource.SetRequestColumns(this.RequestColumns);
                                this.FlatTableSource.SetOrder(this.DefaultSort);
                                this.FlatTableSource.Initialize();
                            }
                        }
                    });
                });
            }
        }
    }
    GetColumnToPush(col, tanslation) {
        return new Promise((resolve, reject) => {
            const toPush = {
                ContainerFieldID: col.ContainerFieldID,
                Caption: tanslation,
                Data: col.Name.replace(/\./g, '_'),
                Name: col.Name,
                IsSortable: col.IsSortable ? true : false,
                IsFilterable: col.IsFilterable ? true : false,
                IsCommaSeparated: col.IsCommaSeparated ? true : false,
                OrderState: 'NONE',
                Type: col.Type,
                Type2: col.Type2,
                Type3: col.Type3,
                Width: col.Width,
                SearchValue: null,
                IsList: col.IsList ? true : false,
                ParentIsList: col.ParentIsList,
                IsVisible: col.IsVisible ? true : false,
                IsSticky: col.IsSticky,
                Prefix: col.Prefix,
                Suffix: col.Suffix,
                Position: col.Position,
                NumericPrecision: col.NumericPrecision,
                DateFormat: col.DateFormat,
                StyleID: 0
            };
            if (col.Type2 == '876cf188-243b-49ed-91ab-b2cf27216a30' && col.Type3 !== '00000000-0000-0000-0000-000000000000') {
                EnumHelper.GetEnumValues(col.Type3).then((data) => {
                    toPush['EnumSource'] = [...data];
                    toPush['IsEnum'] = true;
                    toPush['DisplayMemberPath'] = 'Caption';
                    toPush['ValueMemberPath'] = 'Value';
                    resolve(toPush);
                });
            } else {
                resolve(toPush);
            }
        });
    }
    onLayoutElementChanged() {
        this.initialized = false;
        this.ControlInitialized();
    }
    dragType;
    dragItem;
    Dragging;
    DisableAutoLoad = false;
    ControlInitialized() {

        this.FillElements(this.LayoutElementValue.HeaderViewDefinition);
        this.FillElements(this.LayoutElementValue.ContentViewDefinition);
        this.FillElements(this.LayoutElementValue.ContentEditDefinition);


        const subDT = this.Subscriptions['DTDataSource'];
        if (subDT && subDT.unsubscribe) {
            subDT.unsubscribe();
        }
        const subInit = this.Subscriptions['Initialized'];
        if (subInit && subInit.unsubscribe) {
            subInit.unsubscribe();
        }
        this.Subscriptions['Overlay'] = LayoutService.OverlayStatus.subscribe((overlay) => {
            this.EditMode = overlay;
        });
        this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        if (this.Rows) {
            this.initialized = true;
        } else if (this.ExternalDataSource) {
            this.FlatTableSource = this.ExternalDataSource;
            this.InitializeDataSource();
        } else {
            MetaHelper.FindDataBindingProperties(this.Layout, this.LayoutElementValue).then(result => {
                if (result) {
                    if (result.IsDataBinding) {
                        if (result.IsShared) {
                            this.DataSourceID = result.Table.SID;
                            this.AdditionalFilter = new RequestFilter();
                            if (result.KeyColumns && result.KeyColumns.length === 1) {
                                this.AdditionalFilter.Name = result.KeyColumns[0];
                                if (this.DataSourceValue != null) {
                                    this.AdditionalFilter.Operator = Comparer.Equal;
                                    this.AdditionalFilter.Value = this.DataSourceValue;
                                } else {
                                    this.AdditionalFilter.Operator = Comparer.In;
                                    this.AdditionalFilter.Value = [];
                                }
                                this.Subscriptions['DTDataSource'] = this.DataSourceChange.subscribe(x => {
                                    if (this.DataSourceValue != null) {
                                        this.AdditionalFilter.Operator = Comparer.Equal;
                                        this.AdditionalFilter.Value = this.DataSourceValue;
                                    } else {
                                        this.AdditionalFilter.Operator = Comparer.In;
                                        this.AdditionalFilter.Value = [];
                                    }
                                    this.ExecuteRefresh();
                                });
                            } else {
                                this.AdditionalFilter.Name = '_Id';
                                this.AdditionalFilter.Operator = Comparer.In;
                                this.AdditionalFilter.Value = [];
                                if (Array.isArray(this.DataSourceValue)) {
                                    this.AdditionalFilter.Value.push(...this.DataSourceValue);
                                }
                                this.Subscriptions['DTDataSource'] = this.DataSourceChange.subscribe(x => {
                                    this.AdditionalFilter.Value = [];
                                    if (Array.isArray(x)) {
                                        this.AdditionalFilter.Value.push(...x);
                                    }
                                    this.ExecuteRefresh();
                                });
                            }
                            this.createColumns();
                            this.InitializeDataSource();
                        } else {
                            this.DataSourceElement = this.LayoutElementValue;
                            this.onRefresh();
                            this.Rows = this.DataSourceValue;
                            this.Subscriptions['DTDataSource'] = this.DataSourceChange.subscribe(x => {
                                this.Rows = x;
                            });
                            this.createColumns();
                            this.initialized = true;
                        }
                    } else {
                        this.DataSourceID = result.Table.SID;
                        this.DataSourceElement = MetaHelper.FindValidParent(this.Layout, this.LayoutElementValue);
                        this.createColumns();
                        this.InitializeDataSource();
                    }
                }
            });
        }
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            if (this.LayoutElement.Selected) {
                this.dragItem = item.item;
                this.Dragging = true;
            }
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
    }
    FillElements(obj) {
        if (obj) {
            let keys = Object.keys(obj);
            keys.forEach((key) => {
                let found = this.LayoutElementValue.Elements.find((value) => value.ID == obj[key].ID);
                if (found) {
                    obj[key] = found;
                }
            });
        }
    }

    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        super.ngAfterViewInit();
    }
    //#endregion
    //#region Table&Data
    createColumns() {
        if (this.LayoutElementValue && this.LayoutElementValue.Columns && this.LayoutElementValue.Columns.length > 0) {
            this.Columns = this.LayoutElementValue.Columns;
        }
    }
    FilteredCount = 0;
    Data = [];
    RowHeight = 33;
    ViewRowHeight;
    EditRowHeight;
    InitializeDataSource() {
        //if (!this.ExternalDataSource) {
        //    this.FlatTableSource = new DataTableDataSource(this.DataSourceID, this.service, this.TableStyle);
        //}
        //this.FlatTableSource.UseCaching = this.LayoutElementValue.UseCaching;
        //this.FlatTableSource.PagingType = this.LayoutElementValue.PagingType;
        //this.FlatTableSource.pageSize = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 300;
        //if (this.LayoutElementValue.PagingType === PagingType.Pager) {
        //    this.FlatTableSource.pageSize = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 10;
        //}
        ////this.onRefresh();
        //this.Subscriptions['Initialized'] = this.FlatTableSource.Initialized.subscribe((res) => {
        //    this.initialized = res;
            
        //});
        ////this.Subscriptions['Loading'] = this.FlatTableSource.Loading.subscribe((res) => {
        ////    this.cdRef.detectChanges();
        ////});
        //if (!this.DisableAutoLoad) {
        //    this.FlatTableSource.Initialize();
        //}


        if (this.LayoutElementValue.UseCaching) {
            if (this.queryId) {
                this.DataService.CloseQuery(this.queryId).subscribe((r) => {
                    this.DataService.OpenQuery(this.DataSourceID, this.Filter).subscribe((result) => {
                        if (result) {
                            this.queryId = result;
                            if (this.PagingType === PagingType.Endless) {
                                this.initialized = true;
                            } else {
                                this.DataService.ExecuteCountQuery(this.queryId).subscribe(res => {
                                    if (res) {
                                        this.FilteredCount = res;
                                    } else {
                                        this.FilteredCount = 0;
                                    }
                                    this.initialized = true;
                                });
                            }
                        }
                    });
                });
            } else {
                this.DataService.OpenQuery(this.DataSourceID, this.Filter).subscribe((result) => {
                    if (result) {
                        this.queryId = result;
                        if (this.PagingType === PagingType.Endless) {
                            this.initialized = true;
                        } else {
                            this.DataService.ExecuteCountQuery(this.queryId).subscribe((data) => {
                                if (data) {
                                    this.FilteredCount = data;
                                } else {
                                    this.FilteredCount = 0;
                                }
                                this.initialized = true;
                            });
                        }
                    }
                });
            }
        } else {
            if (this.PagingType === PagingType.Endless) {
                this.initialized = true;
            } else {
                this.DataService.ExecuteCount(this.DataSourceID, this.Filter).subscribe((data) => {
                    if (data) {
                        this.FilteredCount = data;
                    } else {
                        this.FilteredCount = 0;
                    }
                    this.initialized = true;
                });
            }
        }
    }
    //public fetchPage(page: number) {
    //    //this.ActivePage = page;
    //    //if (this.PageCache[page] != null) {
    //    //    if (this.PagingType === PagingType.Pager) {
    //    //        this.PagedData.next(this.cachedData.slice(page * this.pageSize, (page * this.pageSize) + this.pageSize));
    //    //    }
    //    //    return;
    //    //}
    //    //this.PageCache[page] = [];
    //    //this.Loading.next(true);
    //    //this.Filter.StartRow = page * this.pageSize;
    //    //this.Filter.EndRow = this.Filter.StartRow + this.pageSize;
    //    //if (this.UseCaching) {
    //    //    this.DataService.ExecuteQuery(this.queryId, this.Filter.StartRow, this.Filter.EndRow).subscribe((result) => {
    //    //        this.FillResult(result, page);
    //    //    });
    //    //} else {
    //    //    this.DataService.ExecuteQueryWithFilter(this.DataSourceID, this.Filter).subscribe((result) => {
    //    //        this.FillResult(result, page);
    //    //    });
    //    //}
    //}
    lazyLoad(event) {
        this.loading = true;
        if (this.LayoutElementValue.UseCaching) {
            this.DataService.ExecuteQuery(this.queryId, event.first, event.first+event.rows).subscribe((result) => {
                if (result && Array.isArray(result) && result.length > 0) {
                    this.FillResult(result);
                }
            });
        } else {
            this.DataService.ExecuteQueryWithFilter(this.DataSourceID, this.Filter).subscribe((result) => {
                if (result && Array.isArray(result) && result.length > 0) {
                    this.FillResult(result);
                }
            });
        }
        this.loading = false;
    }
    FillResult(result) {
        const rows = [];
        if (result) {
            if (this.TableStyle == TableStyle.DataTable) {
                result.forEach((item) => {
                    const row = new Row();
                    row.data = item;
                    //row.styles = this._RowStyle;
                    rows.push(row);
                });
            } else {
                result.forEach((item) => {
                    const cols = {};
                    this.Columns.forEach((column) => {
                        cols[column.Name] = {
                            selected: false,
                            edit: false,
                            //tabindex: this.TabIndex
                        };
                        //this.TabIndex += 1;
                    });
                    const row = new Row();
                    row.data = item;
                    row.cols = cols;
                    //row.styles = this._RowStyle;
                    rows.push(row);
                });
            }
            this.Data = [...this.Data, ...rows];
        }
    }
    loading = false;
    queryId;
    //#endregion
    //#region Actions
    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
    }
    RowDoubleClicked(event) {
        this.triggerEvent('DoubleClick', event);
    }
    SelectionChanged(item) {
        const selection = [];
        if (item && item.length > 0) {
            item.forEach(x => {
                selection.push(x.RowData);
            });
        }
        this.triggerEvent('SelectionChanged', selection);
    }
    ValueChanged(item) {
        this.triggerEvent('ValueChanged', item);
    }
    SortChanged(sort) {
        if (this.FlatTableSource) {
            this.FlatTableSource.SetOrder(sort);
            this.FlatTableSource.Refresh();
            //this.cdRef.detectChanges();
        }
    }
    FilterChanged(filter) {
        const clone = {
            Filters: []
        };
        if (this.Filter && this.Filter.Filters) {
            clone.Filters.push(...JSON.parse(JSON.stringify(this.Filter.Filters)));
        }
        filter.forEach(x => {
            FilterHelper.AddSearchFilter(clone, x);
        });
        if (this.FlatTableSource) {
            this.FlatTableSource.SetFilter(clone.Filters);
            this.FlatTableSource.Refresh();
        }
        //this.cdRef.detectChanges();
    }
    ExecuteRefresh() {
        if (this.FlatTableSource) {
            this.onRefresh();
            if (this.Columns) {
                this.Columns.forEach(col => {
                    col.OrderState = 'NONE';
                });
            }
            this.FlatTableSource.Refresh();
        } else if (this.Rows) {
            this.onRefresh();
            const rows = this.Rows;
            this.Rows = [];
            window.setTimeout(() => {
                this.Rows = rows;
            }, 50);           
        }
        //this.cdRef.detectChanges();
    }

    onRefresh() {
        if (!this.DisableAutoLoad) {
            this.Filter = FilterHelper.PrepareFilter(this.DataSourceElement);
            if (this.FlatTableSource) {
                this.FlatTableSource.Columns = this.Columns;
                if (this.AdditionalFilter) {
                    FilterHelper.AddSearchFilter(this.Filter, this.AdditionalFilter);
                }
                this.FlatTableSource.SetFilter(this.Filter.Filters);
                this.FlatTableSource.SetRequestColumns(this.RequestColumns);
                this.FlatTableSource.SetOrder(this.DefaultSort);
            }
        }
        this.DisableAutoLoad = false;
        //this.cdRef.detectChanges();
    }
    ColumnResized(event) {
        VariableHelper.ValueChanged(this.Layout, {
            ID: this.LayoutElement.ID,
            Value: this.Columns
        });
        //this.cdRef.detectChanges();
    }
    //#endregion

    LoadUserSettings() {
        this.UserSpecificColumns = null;
        if (VariableHelper.ActiveVariablesMap[this.Layout._Id + '||' + this.LayoutElement.ID]) {
            let retVal = [];
            const cols = VariableHelper.ActiveVariablesMap[this.Layout._Id + '||' + this.LayoutElement.ID].Value;
            if (cols && this.ColumnsValue) {
                this.ColumnsValue.forEach((item) => {
                    const col = cols.find((value) => value.Name == item.Name);
                    if (col && col.CalculatedWidth) {
                        col.Width.Value = col.CalculatedWidth;
                        col.Width.Type = 0;
                        //const old = this.ColumnsValue.find((value) => value.Name == col.Name);
                        if (item && item.Position != col.Position) {
                            col.Position = item.Position;
                        }
                        retVal.push(col);
                    } else {
                        retVal.push(item);
                    }
                })
                //cols.forEach((col) => {
                //    if (col.CalculatedWidth) {
                //        col.Width.Value = col.CalculatedWidth;
                //        col.Width.Type = 0;
                //        const old = this.ColumnsValue.find((value) => value.Name == col.Name);
                //        if (old && old.Position != col.Position) {
                //            col.Position = old.Position;
                //        }
                //    }
                //})
                this.UserSpecificColumns = retVal;
                this.UserSpecificColumns = this.UserSpecificColumns.sort((a, b) => {
                    if (a.Position > b.Position) {
                        return 1;
                    }
                    if (a.Position < b.Position) {
                        return -1;
                    }
                    return 0;
                });
            }
        }
        //this.cdRef.detectChanges();
    }

    GetUserSpecificValue() {
        return this.Columns;
    }
    SetUserSpecificValue(val) {
        this.LoadUserSettings();
    }
    OnRowsChange(ev) {
        if (ev) {
            const cols = this.Columns;
            if (cols) {
                const rowStyle = {};
                cols.forEach(col => {
                    rowStyle[col.Name] = '0';
                });
                ev.forEach(row => {
                    row.styles = rowStyle;
                });
            }
        }
    }




    GetSourceValue() {

    }

    createInjector(dataToPass): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(LayoutService.CONTAINER_DATA, dataToPass);
        return new PortalInjector(this._injector, injectorTokens);
    }

    headerDrop(event, item) {
        if (this.dragItem) {
            let control: any = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
            this.AddToElements(control, item);
            if (!this.LayoutElementValue.HeaderViewDefinition) {
                this.LayoutElementValue.HeaderViewDefinition = {};
            }
            this.LayoutElementValue.HeaderViewDefinition[item.Name] = control;
        }
    }
    contentViewDrop(event, item) {
        if (this.dragItem) {
            let control = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
            this.AddToElements(control, item);
            if (!this.LayoutElementValue.ContentViewDefinition) {
                this.LayoutElementValue.ContentViewDefinition = {};
            }
            this.LayoutElementValue.ContentViewDefinition[item.Name] = control;
        }
    }
    contentEditDrop(event, item) {
        if (this.dragItem) {
            let control = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
            this.AddToElements(control, item);
            if (!this.LayoutElementValue.ContentEditDefinition) {
                this.LayoutElementValue.ContentEditDefinition = {};
            }
            this.LayoutElementValue.ContentEditDefinition[item.Name] = control;
        }
    }

    AddToElements(control, item) {
        if (item) {
            control.DataSourceID = this.DataSourceID;
            control.DataSource = item.ContainerFieldID;
            control.type = item.Type2;
        }
        if (!this.LayoutElementValue.Elements) {
            this.LayoutElementValue.Elements = [];
        }
        this.LayoutElementValue.Elements.push(control);
    }

    onDragEnter(event, item) {
        event.currentTarget.style.border = '2px solid #c6e067';
    }
    onDragLeave(event, item) {
        event.currentTarget.style.border = '1px solid black';
    }

    itemSelected(item) {
        if (!item.Selected) {
            LayoutService.SelectedItem.next(item);
        }
    }
    clear(item, property) {
        this.LayoutElement.Elements.splice(this.LayoutElement.Elements.indexOf(item[property]), 1);
        delete item[property];
    }
    print(item) {
        console.log(item);
    }
   
}
